import { useState, useEffect } from 'react'
import { FormGroup, Input, FormText, Alert, Table, Button } from "reactstrap";
import * as XLSX from 'xlsx';
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { api } from '../../services/api';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";


export const ExcelImport = () => {
  const [typeError, setTypeError] = useState(null);
  const [excelData, setExcelData] = useState(null);
  const param = useParams();
  const history = useHistory();
  const [categorias, setCategorias] = useState();
  const [ubicaciones, setUbicaciones] = useState();
  const [tarifas, setTarifas] = useState();
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const [enviado, setEnviado] = useState(false);
  const [t] = useTranslation("global");
  const isMobile = useMediaQuery({ query: `(max-width: 1000px)` });
  const excelTickets = "/Tickets.xlsx"

  useEffect (() => {
    const fetchCategorias = async () => {
      try {
        let totalCategorias = [];
        let currentPage = 1;
        let hasMoreTickets = true;
        let filtro = '';
      
        while (hasMoreTickets) {
          const response = await api.categorias.getAll(param.id, filtro, 10, currentPage);
          if (response.status === "success") {
            const categorias  = response.data.categorias; 

            if (categorias && categorias.length > 0) {
              totalCategorias = [...totalCategorias, ...categorias];
              currentPage++;
            } else {
              hasMoreTickets = false;
            }
          }
            setCategorias(totalCategorias)
        }    
      } catch (error) {
        setState({
          ...state,
          error: error.error,
        });
      }
    }
    fetchCategorias();
  },[param.id]);
  
  
  useEffect (() => {
    const fetchUbicaciones = async () => {
      try {
        let totalUbicaciones = [];
        let currentPage = 1;
        let hasMoreTickets = true;
        let filtro = '';
      
        while (hasMoreTickets) {
          const response = await api.locations.getAll(param.id, filtro, 10, currentPage);
          if (response.status === "success") {
            const ubicaciones  = response.data.ubicaciones;

            if (ubicaciones && ubicaciones.length > 0) {
              totalUbicaciones = [...totalUbicaciones, ...ubicaciones];
              currentPage++;
            } else {
              hasMoreTickets = false;
            }
          }
          setUbicaciones(totalUbicaciones)
        }    
      } catch (error) {
          setState({
            ...state,
            error: error.error,
          })
        }
    }
    fetchUbicaciones();
  }, [param.id])

  useEffect (() => {
    const fetchTarifa = async () => {
        try {
          let totalTarifas = [];
          let currentPage = 1;
          let hasMoreTickets = true;
          let filtro = '';
      
          while (hasMoreTickets) {
            const response = await api.tarifas.getAll(param.id, filtro, 10, currentPage);
            if (response.status === "success") {
              const tarifas  = response.data.tarifas; 

              if (tarifas && tarifas.length > 0) {
                totalTarifas = [...totalTarifas, ...tarifas];
                currentPage++;
              } else {
                hasMoreTickets = false;
              }
            }
              setTarifas(totalTarifas)
          }    
        } catch (error) {
            setState({
              ...state,
              error: error.error,
            });
        }
    }
    fetchTarifa();
  },[param.id]);
  const entradasTarifas = tarifas?.filter((tarifa) => tarifa.idtipo === 1 );

  const procesarArchivoExcel = (e) => {
    const archivo = e.target.files[0];
    const filtro = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
    if(archivo) {
      if(archivo && filtro.includes(archivo.type)){
        setTypeError(null);
        leerArchivoExcel(archivo);
      }
      else {
        setTypeError('Por favor, asegúrese de que el archivo seleccionado sea de tipo Excel(.xlsx o .xls.)')
      }
    } 
  }

  const errores = [];
  const validaryGuardar = () => {
    if (!excelData) {
        setTypeError('Por favor, cargue un archivo Excel válido antes de enviar.');
        return;
    }
    
    let errores = [];
        const valido = excelData.every((row, index) => {
          console.log(`Validando fila ${index + 1}:`, row); 
         
        const ubicacionValida = !row.nameubicacion || ubicaciones.some(ubicacion => {
          console.log(`Comparando ubicacion: "${ubicacion.ubicacion}" con row.nameubicacion: "${row.nameubicacion}"`);
          return ubicacion.ubicacion && row.nameubicacion && ubicacion.ubicacion.trim() === row.nameubicacion.trim();
      });

      const categoriaValida = !row.namecategoria || categorias.some(categoria => {
          console.log(`Comparando categoria: "${categoria.categoria}" con row.namecategoria: "${row.namecategoria}"`);
          return categoria.categoria && row.namecategoria && categoria.categoria.trim() === row.namecategoria.trim();
      });

      const tarifaValida = entradasTarifas.some(tarifa => {
        console.log(`Comparando tarifa: "${tarifa.concepto}" con row.nametarifa: "${row.nametarifa}"`);
        return tarifa.concepto && row.nametarifa && tarifa.concepto.trim() === row.nametarifa.trim();
    });

      if (!ubicacionValida) {
          errores.push(`Ubicación inválida en fila ${index + 1}: ${row.nameubicacion}`);
      }
      if (!categoriaValida) {
          errores.push(`Categoría inválida en fila ${index + 1}: ${row.namecategoria}`);
      }
      if (!tarifaValida) {
        errores.push(`Tarifa inválida en fila ${index + 1}: ${row.nametarifa}`);
    }

      return ubicacionValida && categoriaValida && tarifaValida /* && idEventoValido */;
  });

  if (!valido) {
      console.log('Errores de validación:', errores);
      setTypeError(`Los datos del archivo Excel contienen errores:\n${errores.join('\n')}`);
      return;
  }
  guardarTickets();
  };

  const guardarTickets = async () => {
    try {
      const response = await api.eventos.createTickets(param.id, excelData);

      if (response.status === 'success') { 
        console.log('Ticket creado exitosamente:');
        setEnviado(true);
        setTimeout(() => {
        }, 4000); 
      }
      else { console.error('Error al crear el ticket:', response.error); 
      }
    } catch (error) {
        console.error('Error al intentar guardar los tickets:', error.message);
    }
  };


  const leerArchivoExcel = (archivo) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]);

    
      const cleanedJson = jsonData.map(row => {
        const cleanedRow = {};
        for (const key in row) {
          let value = String(row[key]).replace(/\t/g, '').trim();
          if (key === 'idevento') {
            value = parseInt(value, 10);
          }
          if (key === 'cantidadtarifa') {
            value = Number(value);
          }
          if (key === 'importe') {
            value = parseFloat(value);
          }
          cleanedRow[key.trim()] = value;
        }
        return cleanedRow;
      });
      setExcelData(cleanedJson);
    };
    reader.readAsBinaryString(archivo);
  }

  return (
    <>
      <div className='container-sm'>
      <Alert color="info">
        <h4 className='text-center'>{t("excelImport.attention")}</h4>
        <p>{t("excelImport.explain")}</p>
      </Alert>

      <h5 className='mt-4'>{t("excelImport.format")}</h5>
      {!isMobile && (
          <Table responsive bordered borderless>
            <thead className='text-center'>
              <tr className="table-secondary">
                <th>nombre</th>
                <th>apellido</th>
                <th>nameubicacion</th>
                <th>nametarifa</th>
              </tr>
            </thead>
            <tbody className="text-center">
              <tr>
                <td>({t("excelImport.example")} Maria)</td>
                <td>({t("excelImport.example")} Fabbro)</td>
                <td>({t("excelImport.example")} Mesa 01)</td>
                <td>({t("excelImport.example")} General)</td>
              </tr> 
            </tbody>
          </Table>
      )}
      <a href={excelTickets} download="excelTickets.xlsx" className='text-decoration-none'>
          <Button color="success" className='d-flex justify-content-center align-items-center'>
            <i className="material-symbols-outlined">arrow_downward</i>
            Descargar plantilla
          </Button>
      </a>
      <div className='especificaciones mt-4'>
        <h5>{t("excelImport.specifications")}</h5>
        <li>{t("excelImport.sp1")} {param.id}</li>
        <li>
          {t("excelImport.sp2-1")}&nbsp;
          <a className="alert-link" href={`/eventos/${param.id}/ConfigurarCategorias`}>
            {t("excelImport.sp2-2")}
          </a>
          &nbsp;{t("excelImport.sp2-3")}&nbsp;
          <a className="alert-link" href={`/eventos/${param.id}/ubicaciones`}>
            {t("excelImport.sp2-4")}
          </a>
        </li>
        <li>{t("excelImport.sp3")}</li>
        <li>{t("excelImport.sp4")}</li>
        <li>{t("excelImport.sp5")}</li>
      </div>
      <div className='formulario-ingreso mt-4'>
        <h5>{t("excelImport.uploadFile")}</h5>
        <FormGroup>
          <Input id="exampleFile" name="file" type="file" accept=".xlsx, .xls" onChange={procesarArchivoExcel}/>
            <FormText>{t("excelImport.extension")}</FormText>
            {typeError && <Alert color="danger">{typeError}</Alert>}
        </FormGroup>
        {enviado && ( 
          <div className="mt-3">
            <Alert>{t("excelImport.succesCreated")}</Alert>
          </div>)}
      </div>
      <div className='opciones d-flex justify-content-end'>
        <div>
          <Button color="secondary" className='mr-3' onClick={() => history.push("/eventos/"+ param.id +"/nuevoTicket")}>{t("button.cancel")}</Button>
        </div>
        <div>
          <Button color="primary" onClick={validaryGuardar}>{t("excelImport.create")}</Button>
        </div>
      </div>
      </div>
      
    </>
  )
}
