import React, { useEffect, useState } from "react";
import { api } from "../../services/api";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useAuth0 } from "@auth0/auth0-react";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { IconButton } from "@mui/material";

const LikeButton = ({ publicacionId }) => {
    const [userId, setUserId] = useState(null);
    const { user, isAuthenticated, isLoading } = useAuth0();
    const [hasLiked, setHasLiked] = useState(false);
    const [state, setState] = useState({
        error: null,
    });

    useEffect(() => {
        const fetchUser = async () => {
            if (!isAuthenticated || !user) return;

            try {
                const response = await api.usuarios.getUsuario(); 
                if (response.status === "success") {
                    setUserId(response.data.usuario.id); 
                }
            } catch (error) {
                console.error("Error al obtener el usuario activo:", error);
                setState({ error: "Error al obtener el usuario." });
            }
        };

        fetchUser();
    }, [isAuthenticated]);

    useEffect(() => {
        const checkIfLiked = async () => {
            if (!userId) return;

            try {
                const response = await api.usuarios.getLikesUser(); 
                if (response.status === "success") {
                    const userLikes = response.data; 
                    // verificar si el ususario ya le dio like a una publi
                    const liked = userLikes.some(like => like.idpublicacion === publicacionId && like.like === true);
                    setHasLiked(liked);
                }
            } catch (error) {
                console.error("Error al obtener los likes del usuario:", error);
                setState({ error: "Error al obtener los likes." });
            }
        };

        checkIfLiked();
    }, [userId, publicacionId]);

    const handleLikeToggle = async () => {
        if (!userId) return; 

        const body = {
            idusuario: userId,
            idpublicacion: publicacionId,
        };

        const action = hasLiked ? eliminarLike : darLike;

        try {
            const response = await action(body);
            if (response.status === "success") {
                setHasLiked(!hasLiked);
            } else {
                setState({ error: `Error al ${hasLiked ? 'eliminar' : 'dar'} like.` });
            }
        } catch (error) {
            console.error(`Error en la red al ${hasLiked ? 'eliminar' : 'dar'} like:`, error);
            setState({ error: "Error en la red." });
        }
    };

    const darLike = async (body) => {
        const { idusuario, idpublicacion } = body;
        return await api.usuarios.AddLike(idusuario, idpublicacion); 
    };

    const eliminarLike = async (body) => {
        const { idusuario, idpublicacion } = body;
        return await api.usuarios.RemoveLike(idusuario, idpublicacion); 
    };

    return (
        <>
            {isAuthenticated && ( 
                <IconButton 
                    onClick={handleLikeToggle}
                    color={hasLiked ? "primary" : "default"} 
                >
                    {hasLiked ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                </IconButton>
            )}
            {state.error && <div className="error-message">{state.error}</div>}
        </>
    );
};

export default LikeButton;
