import { Modal, Box, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { red } from '@mui/material/colors';
import { api } from '../../services/api';
import { useState } from 'react';
import { toast } from "react-toastify";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };  

export const MensajeFinalizarCreacionEvento = ({ open, handleClose, eventoId }) => {
    const history = useHistory();

    const handleActivateEvent = (eventId) => {
        history.push('/crearEvento/segundoPaso', { eventId }); 
    };

    // const handleEliminarEvento = async () => {
    //     try {
    //         const response = await api.eventos.deleteEvento(eventoId);
    //         if (response.status === "success") {
    //           console.log("Evento eliminado");
    //           toast.success("Evento eliminado correctamente", {
    //             position: "top-right",
    //             autoClose: 2000,
    //           });
    //           setTimeout(() => {
    //             history.push(`/eventos/`);
    //           }, 3000);
    //         }
    //       } catch (error) {
    //         console.error("Error:", error.message);
    //       }
    //     };


    return (
        <Modal
            open={open} onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box sx={{ ...style, width: 600 }}>
                <h2 id="child-modal-title">Activación de Evento</h2>
                <p id="child-modal-description">
                    Para activar tu evento, asegúrate de completar todos los pasos requeridos en el proceso de creación. Una vez que hayas finalizado, podrás gestionar y administrar tu evento.
                </p>
                <div className="d-flex justify-content-end">
                    {/* <div>
                        <Button size="small"  sx={{ backgroundColor: red[700], color: 'white' }} onClick={handleEliminarEvento}>Eliminar Evento</Button>
                    </div> */}
                    <div>
                        <Button sx={{marginRight: "10px"}} size="small" onClick={handleClose}>Cerrar</Button>
                        <Button variant="contained" onClick={() => handleActivateEvent(eventoId)}>Activar evento</Button>
                    </div>
                </div>
            </Box>
        </Modal>
    )
}
