import { withAuthenticationRequired } from "@auth0/auth0-react";
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import QrReader from 'react-weblineindia-qrcode-scanner';
import { Button, Card, CardBody, CardSubtitle, CardTitle, Container, ListGroup, ListGroupItem } from "reactstrap";
import Loading from "../../components/Loading";
import { api } from "../../services/api";
import "./Styles.css";

export const QrTickets = () => {
  const beepSoundOkRef = useRef(null);
  const beepSoundErrRef = useRef(null);
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null
  });
  const imgRef = useRef(null)
  const [evento, setEvento] = useState();
  const [ticket, setTicket] = useState();
  const [success, setSuccess] = useState();
  const [accesos, setAccesos] = useState();
  const [scan, setScan] = useState(true);
  const [alerta, setAlerta] = useState();
  const [qr, setQr] = useState();
  const param = useParams();

  const callApi = async () => {
    try {
      const response = await api.eventos.get(param.id);

      if (response.status === "success") {
        setEvento(response.data.evento);
      }

    } catch (error) {
      setState({
        ...state,
        error: error.error,
      });
    }
  };

  useEffect(() => {
    beepSoundOkRef.current = new Audio('/ok.mp3');
    beepSoundOkRef.current.preload = 'auto';
    beepSoundErrRef.current = new Audio('/error.wav');
    beepSoundErrRef.current.preload = 'auto';
    callApi();
  }, []);

  const callQr = async (data) => {
    setQr(data)
    const response = await api.eventos.getTicket(param.id, data);
    console.log(response)
    console.log(data)

    if (response.status === "success") {
      await beepSoundOkRef.current.play();
      setSuccess(true)
      if (imgRef.current) {
        imgRef.current.src = "/qrverde.png"
      }
      setTicket(response.data.ticket);
      setAccesos(response.data.accesos)

    } else {
      if (response.data) {
        await beepSoundErrRef.current.play();
        setSuccess(false)
        if (imgRef.current) {
          imgRef.current.src = "/qrrojo.png"
        }
        setTicket(response.data.ticket);
        setAccesos((response.data.accesos ? response.data.accesos : response.data.accesos))
      }
    }
  };

  const previewStyle = {
    width: "100%",
    height: "40vh",
  }
  const handleScan = (data) => {
    if (data) {
      setScan(false)
      callQr(data)
    }
  }
  const handleError = (err) => {
    console.error(err)
  }

  const SalidaTemporal = async (data) => {
    await api.eventos.setTicket(param.id, qr, "Temporal");
    setAlerta("Se registro la Salida Temporal");
    setTimeout(() => {
      setAlerta();
    }, 3000);
    notify("success", "Se registro la Salida Temporal")
  }

  const SalidaDefinitiva = async (data) => {
    await api.eventos.setTicket(param.id, qr, "Definitiva");
    setAlerta("Se registro la Salida Definitiva");
    setTimeout(() => {
      setAlerta();
    }, 3000);
    notify("success", "Se registro la Salida Definitiva")
  }
  const [showScanner, setShowScanner] = useState(true)
  const [showCamera, setShowCamera] = useState(false)
  const [scannerValue, setScannerValue] = useState("")
  const inputRef = useRef(null)
  const handleScannerRead = (e) => {
    callQr(e.target.value)

    inputRef.current.focus()
  }
  const handleSelectOption = (e) => {
    const option = e.currentTarget.id
    if (option === "camera") {
      setShowScanner(false)
      setShowCamera(true)
    }
    if (option === "scanner") {
      setShowScanner(true)
      setShowCamera(false)
      if (inputRef.current){
        inputRef.current.focus()
      }
    }
  }

  const notify = (type, message, position = "bottom-center") => {
    const options = {
      position,
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    }
    switch (type) {
      case 'success':
        toast.success(message, options);
        break;
      case 'error':
        toast.error(message, options);
        break;
      case 'info':
        toast.info(message, options);
        break;
      case 'warning':
        toast.warning(message, options);
        break;
      default:
        toast(message, options);
    }
  }



  return (
    <>
      {evento &&
        <Container fluid="sm" className="d-flex flex-column justify-content-center">
          <div className="d-flex flex-column align-items-center gap-2">
            <div className="w-100">
              <nav
                className="w-100 d-flex justify-content-evenly "
              >
                <div
                  id="scanner"
                  className="btn rounded-0 d-flex aligm-items-center gap-1"
                  style={{
                    borderBottom: showScanner ? '2px solid black' : ''
                  }}
                  onClick={(e) => handleSelectOption(e)}
                >
                  <i className="material-symbols-outlined">qr_code_scanner</i>
                  Escaner
                </div>
                <div
                  id="camera"
                  onClick={(e) => handleSelectOption(e)}
                  className="btn rounded-0 d-flex aligm-items-center gap-1"
                  style={{
                    borderBottom: showCamera ? '2px solid black' : ''
                  }}
                >
                  <i className="material-symbols-outlined">photo_camera</i>
                  Cámara
                </div>
              </nav>
            </div>
            {showScanner &&
              <>
                <div className="w-100 p-2 d-flex justify-content-center align-items-center">
                  <input
                    ref={inputRef}
                    autoFocus
                    className="w-auto"
                    value={scannerValue}
                    placeholder="Escanea algún código"
                    style={{
                      border: "none",
                    }}
                    onChange={(e) => handleScannerRead(e)}
                  />
                </div>
                <div className="w-100 d-flex justify-content-center align-items-center gap-3">
                  <div className="w-50 d-flex flex-column justify-content-center align-items-end gap-2">
                    <img
                      alt="OK"
                      ref={imgRef}
                      src="/qrgris.png"
                      onClick={() => inputRef.current.focus()}
                      style={{ width: "100px", height: "100px" }}
                    />
                  </div>
                  <div className="w-50 ">
                    {ticket &&
                      <CardBody>
                        <CardTitle className="text-left fs-6">
                          {qr}
                        </CardTitle>

                        <CardSubtitle tag="h3" className="text-left">
                          {ticket.apellido} {ticket.nombre}
                        </CardSubtitle>
                      </CardBody>
                    }
                  </div>
                </div>
              </>
            }
            {ticket &&
              <CardBody className="d-flex gap-3 align-items-start pt-2">
                {!success && accesos &&
                  <>
                    <Button
                      color="warning"
                      onClick={() => {
                        SalidaTemporal()
                        setScan(true)
                        setTicket(null)
                        if(inputRef.current)(inputRef.current.focus())
                      }}
                    >
                      Salida Temporal
                    </Button>
                    <Button
                      style={{ float: "right" }}
                      color="danger"
                      onClick={() => {
                        SalidaDefinitiva()
                        setScan(true)
                        setTicket(null)
                        if(inputRef.current)(inputRef.current.focus()) 
                      }}
                    >
                      Salida Definitiva
                    </Button>
                  </>
                }
              </CardBody>
            }
            {showCamera &&
              scan &&
              <div style={{ maxWidth: '400px' }}>
                <QrReader
                  delay={100}
                  legacyMode={false}
                  style={previewStyle}
                  onError={handleError}
                  onScan={handleScan}
                />
              </div>
            }
            {ticket &&
              <Card className="my-2"
                color="light"
                outline
              >
                <ListGroup flush
                  style={{ height: "200px", overflow: "auto" }}
                >
                  {accesos && accesos.map((acceso) => (
                    <ListGroupItem className="accesos">
                      {moment.parseZone(acceso.fecha).format("HH:mm")} {acceso.evento}
                    </ListGroupItem>))
                  }
                </ListGroup>

                <CardBody className="d-flex flex-column gap-3 align-items-start">
                  <Button
                    color="primary"
                    onClick={() => {
                      setScan(true)
                      setTicket(null)
                    }}
                  >
                    Volver <FontAwesomeIcon icon={faQrcode} />
                  </Button>
                </CardBody>
              </Card>
            }
          </div>
        </Container>
      }
    </>
  )

}
export default withAuthenticationRequired(QrTickets, {
  onRedirecting: () => <Loading />,
});
