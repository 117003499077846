import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { api } from "../../services/api";
import AdsMobile from "./AdsMobile";
import CardPublicacion from "./CardPublicacion";
import "./ads.css";
import DatePicker from "react-multi-date-picker";
import Toolbar from "react-multi-date-picker/plugins/toolbar";
import "react-multi-date-picker/styles/layouts/mobile.css";
import { toast } from "react-toastify";
import TextField from '@mui/material/TextField';
import MicExternalOnOutlinedIcon from '@mui/icons-material/MicExternalOnOutlined';
import NightlifeOutlinedIcon from '@mui/icons-material/NightlifeOutlined';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import SportsBasketballOutlinedIcon from '@mui/icons-material/SportsBasketballOutlined';
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { MenuItem } from "@mui/material";

const categorias = ["Música", "Fiestas", "Deportes", "Beneficencia", "Gastronomía", "Exposiciones"];

const categoriaIcons = {
  "Fiestas": <NightlifeOutlinedIcon className="icon-category" />,
  "Música": <MicExternalOnOutlinedIcon className="icon-category" />,
  "Beneficencia": <VolunteerActivismOutlinedIcon className="icon-category" />,
  "Deportes": <SportsBasketballOutlinedIcon className="icon-category" />,
  "Gastronomía": <FastfoodOutlinedIcon className="icon-category" />,
  "Exposiciones": <BusinessCenterOutlinedIcon className="icon-category" />,
};

const translations = {
  "Música": {
    "es": "Música",
    "en": "Music"
  },
  "Fiestas": {
    "es": "Recepciones",
    "en": "Social events"
  },
  "Deportes": {
    "es": "Deportivos",
    "en": "Sports"
  },
  "Beneficencia": {
    "es": "Beneficios",
    "en": "Profits"
  },
  "Gastronomía": {
    "es": "Gastronomía",
    "en": "Food"
  },
  "Exposiciones": {
    "es": "Exposiciones",
    "en": "Exhibitions"
  }
};

const translateCategory = (category, language) => {
  return translations[category] ? translations[category][language] : category;
};

const Ads = () => {
  const [publicaciones, setPublicaciones] = useState([]);
  const [publicacionesConFechaEvento, setPublicacionesConFechaEvento] = useState({});
  const [filteredPublicaciones, setFilteredPublicaciones] = useState([]);
  const [filtrosAplicados, setFiltrosAplicados] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [dateRange, setDateRange] = useState([]);
  const calendarRef = useRef();
  const [loading, setLoading] = useState(true);
  const { i18n, t } = useTranslation("global");
  const currentLanguage = i18n.language;
  const isMobile = useMediaQuery({ query: `(max-width:770px)` });

  const months = [
    ["Enero", "En"],
    ["Febrero", "Feb"],
    ["Marzo", "Mar"],
    ["Abril", "Abr"],
    ["Mayo", "Ma"],
    ["Junio", "Jun"],
    ["Julio", "Jul"],
    ["Agosto", "Ago"],
    ["Septiembre", "Sept"],
    ["Octubre", "Oct"],
    ["Noviembre", "Nov"],
    ["Diciembre", "Dic"],
  ]

  const weekDays = [
    ["Domingo", "Do"],
    ["Lunes", "Lu"],
    ["Martes", "Ma"],
    ["Miercoles", "Mi"],
    ["Jueves", "Ju"],
    ["Viernes", "Vi"],
    ["Sabado", "Sa"],
  ]



  const fetchData = async () => {
    try {
      const response = await api.publicaciones.getAllActive();
      if (response.status === "success") {
        setPublicaciones(response.data.publicaciones || []);
        setFilteredPublicaciones(response.data.publicaciones || []);
      }
    } catch (error) {
      toast.error("Error al cargar publicaciones.", {
        position: "top-center",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleFechaEventoChange = (publicacionId, fechaEvento) => {
    const fecha = new Date(fechaEvento);
    setPublicacionesConFechaEvento((prev) => ({
      ...prev,
      [publicacionId]: fecha,
    }));

    return fecha;
  };
  
    useEffect(() => {
      fetchData();
    }, []);

  const handleCategoryChange = (category) => {
    setSelectedCategory(prev => prev === category ? '' : category);
    applyFiltros(null, category);
  };


  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFecha = (dates) => {
    setDateRange(dates);
    applyFiltros(null, null, dates);
  };

  const handleSearchSubmit = () => {
    applyFiltros(searchTerm);
  }

  const applyFiltros = (searchOverride = null, categoryOverride = null, dateOverride = null) => {
    let filtered = publicaciones;
    let anyFiltroApplied = false;

    const categoryToUse = categoryOverride !== null ? categoryOverride : selectedCategory;
    const searchToUse = searchOverride !== null ? searchOverride : searchTerm;
    const datesToUse = dateOverride !== null ? dateOverride : dateRange;

    if (categoryToUse) {
      filtered = filtered.filter((pub) =>
        pub.evento.evento_tipo.evento_tipo === categoryToUse
      );
      anyFiltroApplied = true;
    }

    const [startDate, endDate] = datesToUse;
    if (startDate && endDate) {
      filtered = filtered.filter((pub) => {
        const fechaEvento = publicacionesConFechaEvento[pub.id] || handleFechaEventoChange(pub.id, pub.evento.fecha);
        if (!fechaEvento) return false;
        return fechaEvento >= startDate.toDate() && fechaEvento <= endDate.toDate();
      });
      anyFiltroApplied = true;
    }

    if (searchToUse) {
      filtered = filtered.filter((pub) =>
        pub.titulo.toLowerCase().includes(searchToUse.toLowerCase())
      );
      anyFiltroApplied = true;
    }

    setFilteredPublicaciones(filtered);
    setFiltrosAplicados(anyFiltroApplied);
  };


  const clearFiltros = () => {
    setSelectedCategory('');
    setSearchTerm('');
    setDateRange([]);
    setFilteredPublicaciones(publicaciones);
    setFiltrosAplicados(false);
  }

  const handleClearFiltros = () => {
      clearFiltros();
  }
  return (
    <>
      {isMobile ? (
        <div className="container-fluid">
            <AdsMobile publicaciones={publicaciones} filteredPublicaciones={filteredPublicaciones}
             setFilteredPublicaciones={setFilteredPublicaciones} handleFechaEventoChange={handleFechaEventoChange}/>
          </div>

      ) : (

        <>
          <div className="d-flex w-100 flex-wrap justify-content-center mt-2">
            <div className="d-flex justify-content-center" style={{ width: "100%" }}>
              <TextField sx={{
                width: "40%",
                borderRight: "none",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px 0 0 10px",
                },
                input: {
                  '::placeholder': {
                    fontFamily: 'Inter',
                  },
                }
              }} placeholder={t("home.filterText")} value={searchTerm}
                onChange={handleSearchChange}
                className="input-search" />

              <TextField sx={{
                width: "15%", borderLeft: "none", borderRight: "none", "& .MuiOutlinedInput-root": { borderRadius: "0", }, input: {
                  '::placeholder': {
                    fontFamily: 'Inter',
                  },
                }
              }} select label="Region" className="input-region">
                <MenuItem>a</MenuItem>
                <MenuItem>b</MenuItem>
              </TextField>

              <div className="container-calendar d-inline-flex" ref={calendarRef}>
                <DatePicker
                  placeholder={t("home.filterDate")}
                  months={months}
                  weekDays={weekDays}
                  range
                  value={dateRange}
                  onChange={handleFecha}
                  numberOfMonths={1}
                  rangeHover
                  rangeColors={"#7D888D"}
                  dateSeparator=" | "
                  className="fs-6 "
                  scrollSensitive={false}
                  plugins={[
                    <Toolbar
                      position="bottom"
                      names={{
                        today: "Hoy",
                        deselect: "Limpiar",
                        close: "Cerrar"
                      }}
                    />
                  ]}
                />
              </div>

              {filtrosAplicados ? (
                <button type="button" className="boton-limpiar-filtros" onClick={handleClearFiltros}>

                  <div className="d-flex flex-row content-boton-filtros">
                    <span className="icon-boton-filtros"><ClearOutlinedIcon className="mr-2" /></span>
                    <p className="txt-boton-filtros mb-0">{t("home.cleanFilters")}</p>
                  </div>
                </button>
              ) : (
                <button type="button" className="boton-buscar-filtros" onClick={handleSearchSubmit}>
                  <SearchOutlinedIcon className="mr-2" />
                  {t("button.search")}
                </button>
              )}


            </div>


          </div>

          <div className="d-flex justify-content-center gap-4 mt-5">
            {categorias.map((category) => (
              <button
                key={category}
                className={`button-ads-desktop ${selectedCategory === category ? 'active' : ''}`}
                onClick={() => handleCategoryChange(category)}
              >
                <div>
                  <span className="">{categoriaIcons[category]}</span>
                  <p className="text-btn-category">{translateCategory(category, currentLanguage)}</p>
                </div>
              </button>
            ))}
          </div>

          <div className="d-flex flex-wrap justify-content-center gap-4 py-4">
            {filteredPublicaciones.length > 0 ? (
              filteredPublicaciones.map((pub) => (

                <CardPublicacion
                  key={pub.id}
                  publicacion={pub}
                  isLoading={loading}
                />


              ))
            ) : (
              <p className="mt-4 w-100 text-center">
                {t("publications.publicationsAlert")}
              </p>
            )}
          </div>
        </>
      )}
    </>

  );
};

export default Ads;
