import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../services/api";
import { FormGroup, Label, Input, Form , InputGroup, InputGroupText, Row, Col, Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { TextField, FormControl, InputLabel, Select, MenuItem, Button, Box, Alert, AlertTitle, FormHelperText } from '@mui/material';


export const NuevoTicket = () => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const [categorias, setCategorias] = useState();
  const [ubicaciones, setUbicaciones] = useState();
  const [tarifas, setTarifas] = useState();
  const [importe, setImporte] = useState()
  const [tarifaSeleccionada, setTarifaSeleccionada] = useState('');
  const param = useParams();
  const history = useHistory();
  const [t] = useTranslation("global");
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const [formData, setFormData] = useState({
    nombre: '',
    apellido: '',
    idubicacion: ' ',
    idcategoria: ' ',
    idtarifa: '',
    cantidadtarifas: '1',
    importe: '',
    cantidadtickets: '1'
  })

  useEffect (() => {
    const fetchCategorias = async () => {
        try {
          let totalCategorias = [];
          let currentPage = 1;
          let hasMoreTickets = true;
          let filtro = '';
      
          while (hasMoreTickets) {
            const response = await api.categorias.getAll(param.id, filtro, 10, currentPage);
            if (response.status === "success") {
              const categorias  = response.data.categorias; 

              if (categorias && categorias.length > 0) {
                totalCategorias = [...totalCategorias, ...categorias];
                currentPage++;
              } else {
                hasMoreTickets = false;
              }
            }
              setCategorias(totalCategorias)
          }    
        } catch (error) {
            setState({
              ...state,
              error: error.error,
            });
        }
    }
    fetchCategorias();
  }, [param.id, state]);

  useEffect (() => {
    const fetchUbicaciones = async () => {
      try {
        let totalUbicaciones = [];
        let currentPage = 1;
        let hasMoreTickets = true;
        let filtro = '';
      
        while (hasMoreTickets) {
          const response = await api.locations.getAll(param.id, filtro, 10, currentPage);
          if (response.status === "success") {
            const ubicaciones  = response.data.ubicaciones;

            if (ubicaciones && ubicaciones.length > 0) {
              totalUbicaciones = [...totalUbicaciones, ...ubicaciones];
              currentPage++;
            } else {
              hasMoreTickets = false;
            }
          }
          setUbicaciones(totalUbicaciones)
        }    
      } catch (error) {
          setState({
            ...state,
            error: error.error,
          })
        }
    }
    fetchUbicaciones();
  }, [param.id, state])

  useEffect (() => {
    const fetchTarifa = async () => {
        try {
          let totalTarifas = [];
          let currentPage = 1;
          let hasMoreTickets = true;
          let filtro = '';
      
          while (hasMoreTickets) {
            const response = await api.tarifas.getAll(param.id, filtro, 10, currentPage);
            if (response.status === "success") {
              const tarifas  = response.data.tarifas; 

              if (tarifas && tarifas.length > 0) {
                totalTarifas = [...totalTarifas, ...tarifas];
                currentPage++;
              } else {
                hasMoreTickets = false;
              }
            }
              setTarifas(totalTarifas)
          }    
        } catch (error) {
            setState({
              ...state,
              error: error.error,
            });
        }
    }
    fetchTarifa();
  }, [param.id, state]);

  const entradasTarifas = tarifas?.filter((tarifa) => tarifa.idtipo === 1 );

  const [unidad, setUnidad] = useState('');
  useEffect(() => {
    const selectedTarifa = tarifas?.find((tarifa) => tarifa.id === parseInt(formData.idtarifa));
    setUnidad(selectedTarifa ? selectedTarifa.unidad : '');
    setTarifaSeleccionada(selectedTarifa ? selectedTarifa.concepto : '');

    if (selectedTarifa && formData.cantidadtarifas) {
      const cantidad = parseInt(formData.cantidadtarifas);
      const precio = selectedTarifa.precio;
      setImporte(cantidad * precio);
    } else {
      setImporte(0);
    }
  }, [formData.idtarifa, formData.cantidadtarifas, tarifas]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "cantidadtickets") {
      if (value == '' || /^[1-9]\d*$/.test(value)) {
          setFormData({
              ...formData,
              [name]: value,
          });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const idCategoriaInt = parseInt(formData.idcategoria);
  const idUbicacionInt = parseInt(formData.idubicacion);
  const idTarifa = parseInt(formData.idtarifa);
  const cantidadTarifas = parseInt(formData.cantidadtarifas);
  const cantidadTickets = parseInt(formData.cantidadtickets);

  const [errors, setErrors] = useState({});
  
  const handleSubmit = async (e) => { 
    e.preventDefault();
    let validationErrors = {};

    if (formData.nombre === '') {
      validationErrors.nombre = t('errors.errorName');
    }
    if (formData.idcategoria === '') {
      validationErrors.idcategoria = t('errors.errorCategory');
    }
    if (formData.idubicacion === '') {
      validationErrors.idubicacion = t('errors.errorLocation');
    }
    if (formData.idtarifa === '') {
      validationErrors.idtarifa = t('errors.errorRate');
    }
    

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    const data = {
      ticket: {
        nombre: formData.nombre,
        apellido: formData.apellido,
        idubicacion: idUbicacionInt,
        idcategoria: idCategoriaInt,
        idtarifa: idTarifa,
        cantidad: cantidadTarifas,
        importe: importe
      },
      cantidad: cantidadTickets,
    };

    try {
      const response = await api.eventos.createTicket(param.id, data);

      if (response.status === 'success') { 
        console.log('Datos enviados a la API:', data);
        
        toast.success(t("tickets.ticketCreated"), {
          position: "top-right",
          autoClose: 2000,
        });

        setTimeout(() => {
          history.push(`/eventos/${param.id}`);
        }, 2000);
      } 
      else {
        console.error('Error al crear el ticket:', response.error);
      } 
    } catch (error) { 
      console.error('Error en la solicitud:', error.message); 
      toast.error(error.message, {
        position: "top-right",
        autoClose: 4000,
      }); 
    } 
  };

  

  return (
    <Container fluid="sm" className="d-flex justify-content-center flex-wrap">
      <div className="d-flex justify-content-center flex-wrap">
        <div className="mt-2" style={{ maxWidth: isMobile ? '800px' : 'none', width: !isMobile ? '800px' : 'auto'}}>
          <Box 
            component="form" 
            onSubmit={handleSubmit}>
            <div className="w-100 d-flex gap-3 mb-3">
              <TextField 
                name="nombre"
                label="Nombre" 
                variant="outlined" 
                value={formData.nombre}
                onChange={handleChange}
                style={{width: "50%"}}
                // error={errors.nombre}
                // helperText={errors.nombre && errors.nombre}
              />
                {/* <FormGroup floating>
                  <Input id="nombre" name="nombre" placeholder={t("ticket.name")} value={formData.nombre} onChange={handleChange}/>
                  <Label for="nombre">{t("tickets.name")}</Label>
                  {errors.nombre && <span className="text-danger">{errors.nombre}</span>}
                </FormGroup> */}
              <TextField 
                name="apellido"
                label="Apellido" 
                variant="outlined" 
                value={formData.apellido}
                onChange={handleChange}
                style={{width: "50%"}}
                // error={errors.nombre}
                // helperText={errors.nombre && errors.nombre}
              />
                {/* <FormGroup floating>
                  <Input id="apellido" name="apellido" placeholder={t("tickets.lastNameOptional")} value={formData.apellido} onChange={handleChange}/>
                  <Label for="apellido">{t("tickets.lastNameOptional")}</Label>
                  {errors.apellido && <span className="text-danger">{errors.apellido}</span>}
                </FormGroup> */}
            </div>
            <div className="w-100 d-flex gap-3 mb-3">
              {/* <Col md={6}>
                <FormGroup floating>
                  <Input disabled id="idcategoria" name="idcategoria" type="select" value={formData.idcategoria} onChange={handleChange}>
                    <option value="" hidden>
                    {t("tickets.selectACategory")}
                    </option>
                    {categorias?.map((categoria) => (
                      <option key={categoria.id} value={categoria.id}>
                        {categoria.categoria}
                      </option>
                    ))}
                  </Input>
                  <Label for="idcategoria">{t("tickets.categories")}</Label>
                  {errors.idcategoria && <span className="text-danger">{errors.idcategoria}</span>}
                </FormGroup>
              </Col> */}
              <FormControl
                //error={errors.idtarifa}
                style={{width: "50%"}}
              >
                <InputLabel id="demo-simple-select-label">{t("tickets.rates")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="idtarifa"
                  label={t("tickets.locations")} 
                  variant="outlined" 
                  value={formData.idtarifa}
                  onChange={handleChange}
                >
                  {entradasTarifas?.map((tarifa) => (
                    <MenuItem key={tarifa.id} value={tarifa.id}>
                      {tarifa.concepto}
                    </MenuItem>
                  ))}
                </Select>
                {/* <FormHelperText>{errors.idtarifa}</FormHelperText> */}
              </FormControl>
              <FormControl
                //error={errors.idtarifa}
                style={{width: "50%"}}
              >
                <InputLabel id="demo-simple-select-label">{t("tickets.locations")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  name="idubicacion"
                  label={t("tickets.locations")} 
                  variant="outlined" 
                  value={formData.idubicacion}
                  onChange={handleChange}
                >
                  {ubicaciones?.map((ubicacion) => (
                    <MenuItem key={ubicacion.id} value={ubicacion.id}>
                      {ubicacion.ubicacion}
                    </MenuItem>
                  ))}
                </Select>
                {/* <FormHelperText>{errors.idtarifa}</FormHelperText> */}
              </FormControl>
                {/* <FormGroup floating>
                  <Input disabled id="idubicacion" name="idubicacion" type="select" value={formData.idubicacion} onChange={handleChange}>
                    <option value="" hidden>
                    {t("tickets.selectALocation")}
                    </option>
                    {ubicaciones?.map((ubicacion) => (
                      <option key={ubicacion.id} value={ubicacion.id}>
                        {ubicacion.ubicacion}
                      </option>
                    ))}
                  </Input>
                  <Label for="idubicacion">{t("tickets.locations")}</Label>
                  {errors.idubicacion && <span className="text-danger">{errors.idubicacion}</span>}
                </FormGroup> */}
            </div>
            <TextField
              name="cantidadtickets"
              label={t("tickets.numberOfTickets")}
              variant="outlined" 
              value={formData.cantidadtickets}
              onChange={handleChange}
              inputProps={{ 
                min: 1,
              }}
              // error={errors.cantidadvouchers}
              // helperText={errors.cantidadvouchers && errors.cantidadvouchers}
            />
              {/* <Col md={6}>
                <FormGroup floating>
                  <Input id="idtarifa" name="idtarifa" type="select" value={formData.idtarifa} onChange={handleChange} >
                    <option value="" hidden>
                      {t("tickets.selectARate")}
                    </option>
                    {entradasTarifas?.map((tarifa) => (
                      <option key={tarifa.id} value={tarifa.id}>
                        {tarifa.concepto}
                      </option>
                    ))}
                  </Input>
                  <Label for="idtarifa">{t("tickets.rates")}</Label>
                  {errors.idtarifa && <span className="text-danger">{errors.idtarifa}</span>}
                </FormGroup> 
              </Col> */}

              {/* <Col md={6}>
                <InputGroup>
                  <Input
                    name="cantidadtarifas" 
                    type="number"
                    min={1}
                    max={1}
                    disabled
                    style={{height: '58px'}}
                    onChange={handleChange}
                    value={formData.cantidadtarifas}
                  />
                  {unidad && (
                    <InputGroupText>
                      {unidad}
                    </InputGroupText>
                  )}
                </InputGroup>
                <small className="text-secondary">
                  Los tickets van individuales
                </small>
              </Col> */}         
            {/* <FormGroup floating >
              <Input
                id="cantidadtickets"
                name="cantidadtickets"
                type="number"
                min={1}
                max={10}
                value={formData.cantidadtickets}
                onChange={handleChange}
              />
              <Label for="cantidadtickets">
                {t("tickets.numberOfTickets")}
              </Label>
            </FormGroup> */}

            <div className="d-flex justify-content-end gap-3 mt-3 mb-5">
              <Button 
                variant="outlined"
                onClick={() => history.push("/eventos/"+ param.id)}
              >
                {t("button.cancel")}
              </Button>
              <Button 
                variant="contained" 
                type="submit"
              >
                Enviar
              </Button>
            </div>
          </Box>
        </div>

        <div style={{width: '300px', height: 'fit-content', borderRadius: '5px'}} className="ml-4 py-4 px-3 border border-dark-subtle mt-2">
          <h5 style={{textAlign: "center"}}>
            {t("tickets.summary")}
          </h5>
          <hr/>
          {tarifaSeleccionada && formData.cantidadtarifas > 0 && (
            <p>
              {tarifaSeleccionada} x {formData.cantidadtarifas} {unidad}
            </p>
          )}
          <div className="d-flex justify-content-between" style={{backgroundColor: '#ededed', padding: '4px 7px'}}>
            <h4 style={{margin: '0px'}}>
              {t("tickets.total")}:
            </h4>
            <h4 style={{margin: '0px'}}>
              {importe === 0 || !tarifaSeleccionada || !formData.cantidadtarifas
                ? '0'
              : 
                `$${importe * cantidadTickets || ""}`
              }  
            </h4>
          </div>
        </div>
      </div>

      <div style={{boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderradius: '8px'}} className=" py-4 px-3 border border-dark-subtle mt-4">
        <p>
          {t("tickets.attention")}
        </p>
        <div className="d-flex flex-column">
          <p>
            {t("tickets.import")}
          </p>
          <Button className="align-self-end" color="success" onClick={() => history.push("/eventos/"+param.id+"/nuevoTicket/importarExcel") }>
            {t("tickets.upload")}
          </Button>
        </div>
      </div>
    </Container>

  )
}


