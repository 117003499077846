import React, { useEffect, useState } from "react";
import { Button, Card, CardImg, CardText, CardTitle, Col, Form, FormGroup, Input, Label, ListGroup, ListGroupItem} from "reactstrap";
import { FaFacebookF, FaInstagram, FaWhatsapp, FaPhone} from "react-icons/fa"
import { IoMailOutline} from "react-icons/io5";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { IoIosArrowBack,IoIosArrowForward } from "react-icons/io";
import testimonio2 from "../assets/testimonio1.jpg"
import testimonio3 from "../assets/testimonio3.jpg"
import testimonio4 from "../assets/testimonio4.png"
import testimonio5 from "../assets/testimonio5.png"
import testimonio6 from "../assets/testimonio6.png"
import "./creaEventos.css"
import { api } from '../services/api';


export const CreaEventos = () =>{
    const [t] = useTranslation("global");
    const [nombre, setNombre] = useState();
    const [email, setEmail] = useState();
    const [telefono, setTelefono] = useState();
    const [consulta, setConsulta] = useState();
    const [nombreError, setNombreError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [telefonoError, setTelefonoError] = useState("");
    const [consultaError, setConsultaError] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const words = [
        "se crean.",
         "se gestionan.", 
         "se publicitan.", 
         "crecen."
    ];
    const [index, setIndex] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % words.length);
      }, 1500);
  
      return () => clearInterval(interval); // Limpia el intervalo al desmontar
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: true,
              },
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: true,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
              },
            },
          ],
          autoplay: true,
          autoplaySpeed: 2000,   
          speed: 500,
          nextArrow: <SampleNextArrow />,
          prevArrow: <SamplePrevArrow />,
      };

  const items = [
    {
    key: 1,
      src: testimonio4,
      name: 'Gino',
      text: '"La posibilidad de diseñar nuestras propias entradas y vouchers fue un gran plus. Nuestros asistentes quedaron impresionados con la calidad, y la gestión del acceso mediante códigos QR hizo que todo fluyera sin contratiempos."',
    },
    {
      key: 2,
      src: testimonio2,
      name: 'Candela',
      text: '"Gracias a esta plataforma, organizar nuestro festival anual fue más fácil que nunca. Desde la venta de entradas hasta la promoción en redes sociales, todo fue gestionado de manera impecable. ¡Definitivamente la usaremos para futuros eventos!"',
    },
    {
      key: 3,
      src: testimonio3,
      name: 'Pablo',
      text: '"Poder publicitar nuestro evento y vender entradas desde un solo lugar fue un cambio total. La plataforma nos permitió enfocarnos en la experiencia de los asistentes, y los resultados fueron increíbles. ¡Un éxito total!"	',
    },
    {
      key: 4,
      src: testimonio6,
      name: 'Benjamín',
      text: '"La plataforma nos ayudó a gestionar nuestro evento benéfico de principio a fin. La opción de personalizar las entradas y seguir las ventas en tiempo real nos dio el control total que necesitábamos. ¡El evento fue un éxito rotundo!"',
    },
    {
        key: 5,
        src: testimonio5,
        name: 'Daniel',
        text: '"La organización de nuestro torneo deportivo fue impecable gracias a esta plataforma. Desde la venta de entradas hasta el seguimiento en tiempo real, todo estuvo bajo control. Los asistentes quedaron impresionados con la facilidad del acceso y la gestión del evento. ¡Sin duda, repetiremos!"	',
      },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (nombre.trim() === '') {
      setNombreError(<>{t("errors.emptyField")}</>);
    }
    if (email.trim() === '') {
        setEmailError(<>{t("errors.emptyField")}</>);
    }
    if (telefono.trim() === '') {
      setTelefonoError(<>{t("errors.emptyField")}</>);
    }
    if (consulta.trim() === '') {
    setConsultaError(<>{t("errors.emptyField")}</>);
    } 
    
    else {

        const newConsulta = {
        nombre: nombre,
        email: email,
        telefono: telefono,
        consulta: consulta
            }
          try {
              const response = await api.consultas.create(newConsulta);
              console.log(newConsulta);
              if (response.status === "success") {
              setNombre('')
              setEmail('')
              setTelefono('')
              setConsulta('')
              setSubmitted(true);
             }
            } catch (error) {
                setNombre('')
                setEmail('')
                setTelefono('')
                setConsulta('')
            }
       }}

    return(
        <div style={{display:"flex", flexDirection:"column", alignItems:"center", width:"100%", position:"relative"}}>
            
      {/*  -------- HERO  ----------*/}
            <div style={{width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", height:"350px"}} className="hero-div-crear" >
                <h1>
                    DONDE TUS EVENTOS 
                    </h1>
                <h3 style={{textAlign:"center", fontSize:"35px", fontStyle:"italic"}} className="hero-h3"><span> {words[index]}</span></h3>
            </div>


         {/*  -------- INFORMACION  ----------*/}
 
            <div className="info-first-div">
                    <div className="info-reviews-div" >
                        <h5 className="info-reviews-h5" >
                        <i className="material-symbols-outlined" style={{marginRight:"10px", fontSize:"25px"}}>
                            edit_calendar
                        </i>     
                            Creá y gestiona tus eventos.
                        </h5>

                        <p style={{paddingLeft: "10px"}}>
                            En Evento podes crear tus eventos y gestionarlos de principio a fin. Descubri todas las funcionalidades que tenemos para vos.
                        </p>
                    </div>
                    <div className="info-reviews-div" >
                        <h5 className="info-reviews-h5" >
                        <i className="material-symbols-outlined" style={{marginRight:"10px", fontSize:"25px"}}>
                            credit_card_heart
                        </i>     
                            Venta de entradas
                        </h5>
                        <p style={{paddingLeft: "10px"}}>
                        Lanza tu evento y comienza a vender entradas directamente desde nuestra plataforma de manera segura.
                        </p>
                    </div>
                    <div className="info-reviews-div" >
                        <h5 className="info-reviews-h5" >
                        <i className="material-symbols-outlined" style={{marginRight:"10px", fontSize:"25px"}}>
                            groups
                        </i>     
                        Publicidad para tus eventos
                        </h5>

                        <p style={{paddingLeft: "10px"}}>
                        Te ofrecemos las herramientas necesarias para publicitar tu evento y llegar a una audiencia más amplia.
                        </p>
                    </div>
              </div>

                <div className="info-second-div" >
                    <h3 className="info-second-div-h3" >
                        Todo en una plataforma intuitiva
                    </h3>
                    <p className="info-second-div-p">
                        Te proporcionamos todas las herramientas necesarias para que tu evento sea un éxito.<br/>
                        Planifica, ejecuta y monitorea cada detalle sin complicaciones, todo desde un solo lugar.
                    </p>
                    <button className="info-first-div-button">
                        <a href= "#contactus" style={{textDecoration:"none", color: "white"}}>Empezar!</a>
                    </button>
                </div>

                <div className="info-first-div">
                    <div div className="info-reviews-div">
                        <h5 className="info-reviews-h5">
                        <i className="material-symbols-outlined" style={{marginRight:"10px", fontSize:"25px"}}>
                            design_services
                        </i>     
                            Diseña tus tickets y vouchers
                        </h5>

                        <p style={{paddingLeft: "10px"}}>
                            Puedes crear y personalizar entradas y vouchers para tu evento con facilidad. Diseña a tu gusto, elige entre múltiples plantillas o crea un diseño desde cero que refleje la identidad de tu evento.                    
                        </p>
                    </div>
                    <div div className="info-reviews-div">
                        <h5 className="info-reviews-h5" >
                        <i className="material-symbols-outlined" style={{marginRight:"10px", fontSize:"25px"}}>
                        qr_code_scanner
                        </i>     
                        Ingreso con código QR
                        </h5>
                        <p style={{paddingLeft: "10px"}}>
                        Con nuestra tecnología de escaneo de códigos QR la gestión de accesos es rápida, segura y eficiente.
                        </p>
                    </div>
                    <div div className="info-reviews-div">
                        <h5 className="info-reviews-h5">
                        <i className="material-symbols-outlined" style={{marginRight:"10px", fontSize:"25px"}}>
                        donut_large
                        </i>     
                        Datos e informes
                        </h5>

                        <p style={{paddingLeft: "10px"}}>
                        Obtén una visión completa de tu evento con nuestros detallados informes y análisis de ventas. Nuestra plataforma te proporciona datos precisos en tiempo real, permitiéndote tomar decisiones informadas y optimizar el éxito de tu evento. 
                        </p>
                    </div>
                </div>

         {/*  -------- TESTIMONIOS  ----------*/}

                <div style={{ width: "90%", margin: "auto", paddingTop: "50px", marginBottom:"50px"}}>
                <h3 style={{textAlign:"center", marginBottom:"20px"}}>Conocé las experiencias de nuestros clientes</h3>
          <Slider {...settings}>
                  {items.map((item, index) => (
                    <div style={{padding:"5px"}} key={index}>
                     <div className="reviews-Size">
                     <ListGroup flush style={{height:"300px"}}>
                     <ListGroupItem style={{display: "flex", flexDirection:"row", height:"80px", margin:"5px", padding:"0"}}>
                        <CardImg top style={{margin:"5px", width:"70px", height:"70px", borderRadius: "50%"}} src={item.src} alt={item.title} />                        
                        <CardTitle style={{height:"70px", fontWeight:"500", display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>{item.name}</CardTitle>
                         </ListGroupItem>
                         <ListGroupItem>
                             <CardText style={{fontSize: "13px", fontStyle:"italic", height:"auto", display:"flex", flexDirection:"row", justifyContent:"center"}}>{item.text}</CardText>
                         </ListGroupItem>
                         </ListGroup>

                     </div>
                     </div>
                 ))            
                 }
          </Slider>
        </div>

            {/* --------- PRECIOS ---------------- */}
            <h2 className="price-h2">Descubrí nuestros planes</h2>
                <div className="price-div">

                <Col sm="5" className="price-col">
                  <Card body className="price-card">
                    <CardTitle tag="h5">
                      Basic
                    </CardTitle>
                    <CardText className="price-card-text">
                      <h5 className="price-card-h5">Free</h5>
                    </CardText>
                    <div>
                      <ul className="list-unstyled">
                        <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#818181', marginRight: '5px' }}> check </i>
                          Lorem ipsum dolor sit amet</li>

                        <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#818181', marginRight: '5px' }}> check </i>
                          Suspendisse quis eros sed 
                        </li>
                        <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#818181', marginRight: '5px' }}> check </i>
                          Phasellus leo augue,
                        </li>
                        <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#818181', marginRight: '5px' }}> check </i>
                          Vestibulum ut sagittis nisi</li>
                      </ul>
                   </div>
                    <Button>
                      Comenzar!
                    </Button>
                  </Card>
                </Col>
                <Col sm="5" className="price-col">
                  <Card body className="price-card">
                    <CardTitle tag="h5">
                      Pro
                    </CardTitle>
                    <CardText className="price-card-text" >
                      <h5 className="price-card-h5"><span className="span-sm">$</span>25300</h5>
                      <span className="span-sm"> por mes </span>
                    </CardText>
                    <div>
                      <ul className="list-unstyled">
                        <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#818181', marginRight: '5px' }}> check </i>
                          Lorem ipsum dolor sit amet</li>

                        <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#818181', marginRight: '5px' }}> check </i>
                          Suspendisse quis eros sed 
                        </li>
                        <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#818181', marginRight: '5px' }}> check </i>
                          Phasellus leo augue,
                        </li>
                        <li className="fs-6 fw-normal"><i className="material-symbols-outlined fs-5" style={{ color: '#818181', marginRight: '5px' }}> check </i>
                          Vestibulum ut sagittis nisi</li>
                      </ul>
                   </div>
                    <Button>
                      Comenzar!
                    </Button>
                  </Card>
                </Col>
                <Col sm="5" className="price-col">
                  <Card body className="price-card" style={{backgroundColor:"#818181"}}>
                    <CardTitle tag="h5" style={{color: "#fff"}}>
                      Premium
                    </CardTitle>
                    <CardText className="price-card-text" style={{color:"white"}} >
                      <h5 className="price-card-h5"><span className="span-sm">$</span>40200</h5>
                      <span className="span-sm"> por mes </span>
                    </CardText>
                    <div>
                      <ul className="list-unstyled">
                        <li className="fs-6 fw-normal" style={{color:"white"}}><i className="material-symbols-outlined fs-5" style={{ color: '#fff', marginRight: '5px' }}> check </i>
                          Lorem ipsum dolor sit amet</li>

                        <li className="fs-6 fw-normal" style={{color:"white"}}><i className="material-symbols-outlined fs-5" style={{ color: '#fff', marginRight: '5px' }}> check </i>
                          Suspendisse quis eros sed 
                        </li>
                        <li className="fs-6 fw-normal" style={{color:"white"}}><i className="material-symbols-outlined fs-5" style={{ color: '#fff', marginRight: '5px' }}> check </i>
                          Phasellus leo augue,
                        </li>
                        <li className="fs-6 fw-normal" style={{color:"white"}}><i className="material-symbols-outlined fs-5" style={{ color: '#fff', marginRight: '5px' }}> check </i>
                          Vestibulum ut sagittis nisi</li>
                      </ul>
                   </div>
                    <Button style={{backgroundColor:"rgb(255, 255, 255, 0.55)", color:"#818181", border: "none"}}>
                      Comenzar!
                    </Button>
                  </Card>
                </Col>
                </div>

         {/*  -------- CONTACT US  ----------*/}
          <div id="contactus" className="contactus-div">
            <h2> Contactanos!</h2>
                <div className="contactus-div-form">
                <Form className="contactus-div-form-form" onSubmit={handleSubmit} >

                <FormGroup floating >
                    <Input
                        id="name"
                        name="name"
                        placeholder="Nombre"
                        type="string"
                        value={nombre}
                        onChange={(e) => setNombre(e.target.value)}
                    />
                    <Label for="name">
                        Nombre y Apellido
                    </Label>
                    </FormGroup>
                    <FormGroup floating>
                    <Input
                        id="email"
                        name="email"
                        placeholder="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Label for="email">
                        Email
                    </Label>
                    </FormGroup>
                    {' '}
                    <FormGroup floating>
                    <Input
                        id="phone"
                        name="phone"
                        placeholder="Teléfono"
                        type="string"
                        value={telefono}
                        onChange={(e) => setTelefono(e.target.value)}
                    />
                    <Label for="phone">
                        Teléfono
                    </Label>
                    </FormGroup>
                    {' '}
                    <FormGroup floating>
                    <Input
                        id="text"
                        name="text"
                        placeholder="Mensaje"
                        type="textarea"
                        value={consulta}
                        onChange={(e) => setConsulta(e.target.value)}
                    />
                    <Label for="text">
                        Dejanos tu consulta
                    </Label>
                    </FormGroup>
                    {' '}

                    <Button type="submit">
                        Enviar
                    </Button>
                    {!submitted ? (
                    <div></div>
                ): (
                  <div><p style={{fontSize:"18px", fontWeight:"700", color:"green", padding:"10px"}}>Gracias por comunicarte con nosotros. Responderemos tu consulta a la brevedad.</p></div>
                ) }
                </Form>

                </div>

                <div className="social-div">
                  <div className="social-div-redes" >
                      <h5 className="redes-h5">
                      <FaFacebookF style={{fontSize:"25px", paddingRight:"10px"}}/> /evento
                      </h5>
                      <h5 className="redes-h5">
                      <FaInstagram style={{fontSize:"30px", paddingRight:"10px"}}/> /evento
                      </h5>
                      <h5 className="redes-h5">
                      <FaPhone style={{fontSize:"30px", paddingRight:"10px"}}/> 3482-446801
                      </h5>
                      <h5 className="redes-h5">
                      <FaWhatsapp style={{fontSize:"30px", paddingRight:"10px"}}/> 3482-446801
                      </h5>
                      <h5 className="redes-h5">
                      <IoMailOutline style={{fontSize:"30px", paddingRight:"10px"}}/>pruebadeenviodecorreocaar@gmail.com

                      </h5>
                      
                  </div>
                </div>

          </div>


         {/*  -------- FOOTER  ----------*/}
            <footer style={{width:"100%", height:"50px", backgroundColor:"#f6f5f5", marginTop:"20px"}} className="bg-dark-subtle">
             <img src="/web-EVENTO.png" width="110px"></img>
             <p>
                 {t("footer.footer")}    
              </p>
            </footer>

        </div>

    )
}

// Custom next arrow component
function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div
        style={{
          display: "block",
          borderRadius: "50%",
          padding: "10px",
          position: "absolute",
          top: "50%",
          right: "-25px",
          transform: "translateY(-50%)",
          cursor: "pointer",
          zIndex: 2
        }}
        onClick={onClick}
      >
        <IoIosArrowForward style={{ color: "grey", fontSize: "20px" }} />
      </div>
    );
  }
  
  // Custom prev arrow component
  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div
        style={{
          display: "block",
          borderRadius: "50%",
          padding: "10px",
          position: "absolute",
          top: "50%",
          left: "-30px",
          transform: "translateY(-50%)",
          cursor: "pointer",
          zIndex: 2
        }}
        onClick={onClick}
      >
        <IoIosArrowBack style={{ color: "grey", fontSize: "20px" }} />
      </div>
    );
}

export default CreaEventos;