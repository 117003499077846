import React, { useRef, useState } from "react";
import CardPublicacion from "./CardPublicacion";
import { useTranslation } from "react-i18next";
import "./adsmobile.css"
import { TextField } from '@mui/material';

import MicExternalOnOutlinedIcon from '@mui/icons-material/MicExternalOnOutlined';
import NightlifeOutlinedIcon from '@mui/icons-material/NightlifeOutlined';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import SportsBasketballOutlinedIcon from '@mui/icons-material/SportsBasketballOutlined';
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DatePicker from "react-multi-date-picker";
import "react-multi-date-picker/styles/layouts/mobile.css";

import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';

const categorias = ["Música", "Fiestas", "Deportes", "Beneficencia", "Gastronomía", "Exposiciones"];

const categoriaIconsMobile = {
    "Fiestas": <NightlifeOutlinedIcon className="icon-category-mobile" />,
    "Música": <MicExternalOnOutlinedIcon className="icon-category-mobile" />,
    "Beneficencia": <VolunteerActivismOutlinedIcon className="icon-category-mobile" />,
    "Deportes": <SportsBasketballOutlinedIcon className="icon-category-mobile" />,
    "Gastronomía": <FastfoodOutlinedIcon className="icon-category-mobile" />,
    "Exposiciones": <BusinessCenterOutlinedIcon className="icon-category-mobile" />,
};

const translations = {
    "Música": {
        "es": "Música",
        "en": "Music"
    },
    "Fiestas": {
        "es": "Fiestas",
        "en": "Parties"
    },
    "Deportes": {
        "es": "Deportivos",
        "en": "Sports"
    },
    "Beneficencia": {
        "es": "Beneficios",
        "en": "Profits"
    },
    "Gastronomía": {
        "es": "Gastronomía",
        "en": "Food"
    },
    "Exposiciones": {
        "es": "Exposiciones",
        "en": "Exhibitions"
    }
};

const translateCategory = (category, language) => {
    return translations[category] ? translations[category][language] : category;
};

const AdsMobile = ({ publicaciones, filteredPublicaciones, setFilteredPublicaciones, handleFechaEventoChange }) => {
    const [publicacionesConFechaEvento, setPublicacionesConFechaEvento] = useState({});
    const [filtrosAplicados, setFiltrosAplicados] = useState(false);
    const { i18n, t } = useTranslation("global");
    const currentLanguage = i18n.language;
    const [showFiltrosMobile, setShowFiltrosMobile] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [dateRange, setDateRange] = useState([]);
    const calendarRef = useRef();

    const months = [
        ["Enero", "En"],
        ["Febrero", "Feb"],
        ["Marzo", "Mar"],
        ["Abril", "Abr"],
        ["Mayo", "Ma"],
        ["Junio", "Jun"],
        ["Julio", "Jul"],
        ["Agosto", "Ago"],
        ["Septiembre", "Sept"],
        ["Octubre", "Oct"],
        ["Noviembre", "Nov"],
        ["Diciembre", "Dic"],
    ]

    const weekDays = [
        ["Domingo", "Do"],
        ["Lunes", "Lu"],
        ["Martes", "Ma"],
        ["Miercoles", "Mi"],
        ["Jueves", "Ju"],
        ["Viernes", "Vi"],
        ["Sabado", "Sa"],
    ];

    const handleCategoryChange = (category) => {
        setSelectedCategory(prev => prev === category ? '' : category);
        applyFiltros(null, category);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleFecha = (dates) => {
        setDateRange(dates);
        applyFiltros(null, null, dates);
    };

    const handleSearchSubmit = () => {
        applyFiltros(searchTerm);
    }

    const applyFiltros = (searchOverride = null, categoryOverride = null, dateOverride = null) => {
        let filtered = publicaciones;
        let anyFiltroApplied = false;

        const categoryToUse = categoryOverride !== null ? categoryOverride : selectedCategory;
        const searchToUse = searchOverride !== null ? searchOverride : searchTerm;
        const datesToUse = dateOverride !== null ? dateOverride : dateRange;

        if (categoryToUse) {
            filtered = filtered.filter((pub) =>
                pub.evento.evento_tipo.evento_tipo === categoryToUse
            );
            anyFiltroApplied = true;
        }

        const [startDate, endDate] = datesToUse;
        if (startDate && endDate) {
            filtered = filtered.filter((pub) => {
                const fechaEvento = publicacionesConFechaEvento[pub.id] || handleFechaEventoChange(pub.id, pub.evento.fecha);
                if (!fechaEvento) return false;
                return fechaEvento >= startDate.toDate() && fechaEvento <= endDate.toDate();
            });
            anyFiltroApplied = true;
        }

        if (searchToUse) {
            filtered = filtered.filter((pub) =>
                pub.titulo.toLowerCase().includes(searchToUse.toLowerCase())
            );
            anyFiltroApplied = true;
        }

        setFilteredPublicaciones(filtered);
        setFiltrosAplicados(anyFiltroApplied);
    };

    const clearFiltros = () => {
        setSelectedCategory('');
        setSearchTerm('');
        setDateRange([]);
        setFilteredPublicaciones(publicaciones);
        setFiltrosAplicados(false);
    }

    const handleClearFiltros = () => {
        clearFiltros();
    }


    const toggleFiltros = () => {
        setShowFiltrosMobile(!showFiltrosMobile);
    };

    return (
        <>
            <div className="boton-filtros-mobile">
                <div className="d-flex flex-row">
                    <TextField sx={{
                        width: "100%",
                        borderRight: "none",
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "10px 0 0 10px",
                        },
                        input: {
                            '::placeholder': {
                                fontFamily: 'Inter',
                            },
                        }
                    }} placeholder={t("home.filterText")} value={searchTerm}
                        onChange={handleSearchChange}
                        className="input-search" />
                    <button type="button" className="boton-buscar-filtros" onClick={toggleFiltros} style={{ borderRadius: "0 10px 10px 0", fontSize: "18px" }}>
                        {showFiltrosMobile ? <ClearOutlinedIcon/> : <TuneOutlinedIcon/>}
                    </button>
                </div>
                <div className="contenedor-filtros-mobile">
                    <div className="filtro-categorias-mobile">
                        <div className="botones-categorias-mobile">
                            {categorias.map((category) => (
                                <label key={category} className="category-item">
                                    <input
                                        type="radio"
                                        name="category"
                                        value={category}
                                        checked={selectedCategory === category}
                                        onChange={() => handleCategoryChange(category)}
                                        className="category-radio"
                                    />
                                    <span className="category-content">
                                        <span className="category-icon">{categoriaIconsMobile[category]}</span>
                                        <span className="category-text">{translateCategory(category, currentLanguage)}</span>
                                    </span>
                                </label>
                            ))}
                        </div>
                    </div>
                    {showFiltrosMobile && (
                        <div className="contenedor-filtros-mobile">
                            <div className="my-4">
                                <div className="d-flex justify-content-between mb-3">
                                    <p className="mb-0 fw-bold">Fechas</p>
                                    <button type="button">x</button>
                                </div>
                                <div className="calendar-mobile">
                                    <div className="container-calendar container-calendar-mobile" ref={calendarRef}>
                                        <DatePicker
                                            placeholder={t("home.filterDate")}
                                            months={months}
                                            weekDays={weekDays}
                                            range
                                            value={dateRange}
                                            onChange={handleFecha}
                                            format={`DD MMMM YYYY`}
                                            numberOfMonths={1}
                                            rangeColors={['#0A58C2']}
                                            dateSeparator=" al "
                                            className="fs-6 rmdp-mobile"
                                            mobileButtons={[
                                                {
                                                    label: "Limpiar",
                                                    type: "button",
                                                    className: "rmdp-button rmdp-action-button",
                                                    onClick: () => setDateRange([]),
                                                }
                                            ]}
                                            mobileLabels={{
                                                OK: "Aceptar",
                                                CANCEL: "Cerrar",
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {filtrosAplicados ? (
                                <button type="button" className="boton-limpiar-filtros mt-2" onClick={handleClearFiltros}>
                                    <div className="d-flex flex-row content-boton-filtros">
                                        <span className="icon-boton-filtros"><ClearOutlinedIcon className="mr-2" /></span>
                                        <p className="txt-boton-filtros mb-0">{t("home.cleanFilters")}</p>
                                    </div>
                                </button>
                            ) : (
                                <button type="button" className="boton-buscar-filtros boton-buscar-filtros-mobile" onClick={handleSearchSubmit}>
                                    <SearchOutlinedIcon className="mr-2" />
                                    Aplicar filtros
                                </button>
                            )}
                        </div>

                    )}
                </div>
            </div>
            <div className="d-flex flex-wrap justify-content-evenly gap-3 py-4">
                {filteredPublicaciones.length > 0 ? (
                    filteredPublicaciones.map((pub) => (
                        <CardPublicacion
                            key={pub.id}
                            className="ads-detail-mobile"
                            publicacion={pub}
                        />
                    ))
                ) : (
                    <p>
                        {t("publications.publicationsAlert")}
                    </p>
                )}
            </div>
        </>
    )
}

export default AdsMobile;