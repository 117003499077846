import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Button,
  Input,
  InputGroup,
  InputGroupText
} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Loading from '../components/Loading'
import './ayuda.css'


export const Ayuda = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // simulacion para el loadingg
    const tiempo = setTimeout(() => {
      setIsLoading(false);
    }, 1000); 

    return () => clearTimeout(tiempo);
  }, []);

const faqData = [
  {
    category: "Creación de Eventos",
    icon: "bi-calendar-event",
    questions: [
      {
        question: "¿Cómo puedo crear un nuevo evento?",
        answer: "Para crear un nuevo evento, tienes que ir a <span class='destacado'>'Mis eventos'</span> luego haz clic en el botón <span class='destacado'>'Agregar Evento'</span>. Para crear un evento deberas completar un formulario con los detalles del evento como título, fecha, hora y ubicación."
      },
      {
        question: "¿Puedo programar eventos recurrentes?",
        answer: "No, al crear un evento, deberas especificar la fecha y la hora, si quieres puedes modificar la fecha en la seccion <span class='destacado'>'Gestión de eventos'</span>."
      }
    ]
  },
  {
    category: "Gestión de Eventos",
    icon: "bi-gear",
    questions: [
      {
        question: "¿Cómo puedo editar un evento existente?",
        answer: "Para editar un evento, ve a la seccion <span class='destacado'>'Mis eventos'</span>, encuentra el evento que deseas modificar el cual debes ser <span class='destacado'>Owner</span> del evento y haz clic en el icono de <span class='destacado'>'Configuracion del evento'</span>. Realiza los cambios necesarios y guarda las modificaciones."
      },
      {
        question: "¿Puedo eliminar un evento?",
        answer: "Sí, puedes elimiar un evento yendo a la seccion <span class='destacado'>'Mis eventos'</span> luego seleccionar el evento donde eres Owner, y haciendo clic en el botón <span class='destacado'>'Configuracion del evento'</span>. En la parte inferior aparecera un botón <span class='destacado'>'Eliminar Evento'</span>, se le pedira una confirmación, luego de la confirmacion su evento sera eliminado."
      }
    ]
  },
  {
    category: "Gestión de Invitados",
    icon: "bi-people",
    questions: [
      {
        question: "¿Como invito Colaboradores a mi evento?",
        answer: "En la página de detalles del evento, hay una sección <span class='destacado'>'Staff'</span>. Haz clic en <span class='destacado'>'Colaborador'</span> e ingresa sus correos electrónicos y Subroles si es necesario, recuerda que en Colaboradores existen los Colaboradores de <span class='destacado'>Control de Acceso</span> y <span class='destacado'>Control de Vouchers</span>."
      },
      {
        question: "¿Como invito Clientes a mi evento?",
        answer: "En la página de detalles del evento, hay una sección <span class='destacado'>'Staff'</span>. Haz clic en <span class='destacado'>'Cliente'</span> e ingresa sus correos electrónicos y luego <span class='destacado'>'Registrar'</span>."
      },
      {
        question: "¿Puedo ver quién ha confirmado su asistencia?",
        answer: "Sí, en la misma página donde invitas a un Cliente o Colaborador, encontrarás una lista de invitados con su estado de confirmación <span class='destacado'>(Pendiente, Aceptado, Rechazado)</span>."
      }
    ]
  },
  {
    category: "Cuenta",
    icon: "bi bi-person-gear",
    questions: [
      {
        question: "¿Puedo vincular mi cuenta con alguna otra red social?",
        answer: "Sí, en tu perfil aparecera unas opciones de vinculacion, lo cual puedes vincular tu cuenta con Facebook y Google."
      },
      {
        question: "¿Puedo dar de baja mi cuenta?",
        answer: "Sí, en el perfil aparecera una opcion de <span class='destacado'>'Desactivar cuenta'</span> donde se pedira una validacion, una vez completado la validacion se te avisara sobre el estado de la cuenta y luego de desactivara, pasado los 30 dias, se eliminara tu cuenta permanentemente."
      }
    ]
  }
];

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Card className="mb-2">
      <CardHeader>
        <Button 
          color="link" 
          onClick={() => setIsOpen(!isOpen)} 
          className="text-left w-100" 
          style={{
            textDecoration: "none",
            position: "relative",
            paddingRight: "40px",
            paddingLeft: "1.25rem",
            paddingTop: "0.75rem",
            paddingBottom: "0.75rem",
            fontWeight:"bolder"
          }}
        >
          {question}
          <i 
            className={`bi bi-chevron-${isOpen ? 'up' : 'down'}`} 
            style={{
              position: "absolute",
              right: "15px",
              top: "50%",
              transform: isOpen ? 'translateY(-50%) rotate(360deg)' : 'translateY(-50%) rotate(0deg)',
              transition: 'transform 1s ease'
            }}
          ></i>
        </Button>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody dangerouslySetInnerHTML={{ __html:answer}}></CardBody>
      </Collapse>
    </Card>
  );
};


  const filteredFAQ = faqData.map(category => ({
    ...category,
    questions: category.questions.filter(q => 
      q.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      q.answer.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })).filter(category => category.questions.length > 0);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container className="help-container">
      <Row className="justify-content-center">
        <Col md={8}>
          <h1 className="text-center mb-5" style={{fontWeight:"bold"}}>¿Con qué podemos ayudarte?</h1>
          <InputGroup className="mb-4">
            <Input 
              placeholder="Busca en Ayuda"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <InputGroupText>
              <i className="bi bi-search" style={{cursor:"pointer"}}></i>
            </InputGroupText>
          </InputGroup>
          {filteredFAQ.map((category, index) => (
            <div key={index} className="mb-4">
              <h2 className="top-help" style={{marginTop:"50px"}}>
                <i className={`bi ${category.icon} me-2`}></i>
                {category.category}
              </h2>
              {category.questions.map((faq, faqIndex) => (
                <FAQItem key={faqIndex} question={faq.question} answer={faq.answer} />
              ))}
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
};
export default Ayuda;