import React, { useEffect, useState } from "react";
import { api } from "../../services/api";
import { CircularProgress, IconButton, Box } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import fondoHeader from "../../assets/background-header.jpg";
import "./homeHeader.css";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import moment from 'moment-timezone';

export const HomeHeader = ({ searchPub, onSearchChange }) => {
  const { i18n, t } = useTranslation("global");
  const [eventos, setEventos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const intervalTime = 5000;

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, intervalTime);

    return () => clearInterval(interval);
  }, [activeIndex, eventos.length]);

  useEffect(() => {
    const callApi = async () => {
      try {
        setLoading(true);
        const response = await api.eventos.getAllPublic();
        if (response.status === "success") {
          setEventos(response.data.Eventos || []);
        }
      } catch (error) {
        console.error("Error al cargar los eventos", error);
      } finally {
        setLoading(false);
      }
    };

    callApi();
  }, []);

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % eventos.length);
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + eventos.length) % eventos.length);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!eventos || eventos.length === 0) {
    return <div>No hay eventos disponibles en este momento.</div>;
  }

  moment.locale(i18n.language === "es" ? "es" : "en-gb");



  return (
    <>
      {isMobile ? (

        <div className="header-container-mobile">
          <Box display="flex" alignItems="center" justifyContent="space-between" position="relative" sx={{ width: "100%" }}>
            <div
              className="mobile-background"
              style={{
                backgroundImage: `url(${eventos.length > 0 && eventos[activeIndex] && eventos[activeIndex].attachs.length > 0 ? eventos[activeIndex].attachs[0].url : fondoHeader})`,
              }}
            >
              <div className="text-header-mobile" style={{width:"100%"}}>
                <p className="mobile-title">{eventos.length > 0 && eventos[activeIndex]?.evento}</p>
                <p className="mb-0 fw-bold font-italic fecha-header"style={{color:"white"}} >
                  {i18n.language === "es"
                    ? moment(eventos[activeIndex]?.fecha).clone().local().format("D [de] MMMM YYYY")
                    : moment(eventos[activeIndex]?.fecha).clone().local().format("MMMM D[,] YYYY")}
                </p>
              </div>
            </div>
          </Box>
        </div>

      ) : (
        <div className="header-container" style={{ margin: "0", width: "100%" }}>
          <div className="contenido-header">
            <div className="carousel-container">
              <Box display="flex" alignItems="center" justifyContent="space-between" position="relative" sx={{ width: "100%" }}>
                <div className="boton-carousel" style={{ cursor: "pointer", border: "1px solid #F5F5F5", borderRadius: "0 15px 15px 0", height: "400px", backgroundColor: "#F5F5F5", paddingLeft: "5px" }} onClick={handlePrev}>
                  <IconButton onClick={handlePrev} className="carousel-control left">
                    <ArrowBackIosIcon />
                  </IconButton>
                </div>


                <div className="carousel-slide" style={{ textAlign: "center" }}>
                  {eventos.length > 0 && eventos[activeIndex] && (
                    <>
                      <div className="img-header">
                        {eventos[activeIndex].attachs && eventos[activeIndex].attachs.length > 0 ? (
                          <img
                            src={eventos[activeIndex].attachs[0].url}
                            alt={eventos[activeIndex].evento}
                            style={{ width: "100%", height: "400px", objectFit: "cover" }}
                          />
                        ) : (
                          <img
                            src={fondoHeader}
                            alt="Evento sin imagen"
                            style={{ width: "100%", height: "400px", objectFit: "cover" }}
                          />
                        )}
                      </div>
                      <div className="text-header">
                        <p className="title-header">{eventos[activeIndex].evento}</p>
                        <p className="mb-0 text-secondary fw-bold font-italic fecha-header">
                          {i18n.language === 'es' ? (
                            moment(eventos[activeIndex].fecha).clone().local().format("D [de] MMMM YYYY")
                          ) : (
                            moment(eventos[activeIndex].fecha).clone().local().format("MMMM D[,] YYYY")
                          )}
                        </p>
                        <p>

                        </p>
                      </div>
                    </>
                  )}
                </div>

                <div className="boton-carousel" style={{ cursor: "pointer", border: "1px solid #F5F5F5", borderRadius: "15px 0 0 15px", height: "400px", backgroundColor: "#F5F5F5", paddingLeft: "2px", paddingRight: "5px" }} onClick={handleNext}>
                  <IconButton onClick={handleNext} className="carousel-control right">
                    <ArrowForwardIosIcon />
                  </IconButton>
                </div>
              </Box>
            </div>
          </div>
        </div>
      )}

    </>
  );
}