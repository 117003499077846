import React, { useContext, useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { api } from "../../services/api";
import { useParams } from "react-router-dom";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import Loading from "../../components/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faXmark,
  faAdd
} from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  Input,
  Table,
  Button,
  Alert,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";
import VolverButton from "../../components/VolverButton";
import { PaginatorContext } from "../../providers/Paginator";
import DeleteTarifaModal from "../../components/modales/DeleteTarifaModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
<link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />

export const Tarifas = () => {
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const [tarifas, setTarifas] = useState([]);
  const [showSearch, setShowSearch] = useState();
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const param = useParams();
  const [t] = useTranslation("global");
  const history = useHistory();

  //PAGINACION
  const {
    query,
    setQuery,
  } = useContext(PaginatorContext);


  useEffect(()=>{
    callApi();
  },[]);
 
  const callApi = async () =>{
    try {
      const response = await api.tarifas.getAll(param.id);

      if(response.status === "success"){
        setTarifas(response.data.tarifas);
      }
    } catch (error) {
      setState({
        ...state,
        error: error.message,
      });
    }
  }
  const tarifasTicket = tarifas?.filter((tarifa) => tarifa.idtipo === 1);
  const tarifasConsumicion = tarifas?.filter((tarifa) => tarifa.idtipo === 2);

  //BUSQUEDA
  const handleToggleSearch = () => {
    setShowSearch(!showSearch);
    setQuery('');
  };

  const [open, setOpen] = useState(''); 
  const toggle = (id) => {
    if (open === id) {
      setOpen('');
    } else {
      setOpen(id); 
    }
  };

  return (
    <>
      {isMobile ? (
        <Container fluid="sm">
          <div className="d-flex justify-content-between">
            <Button color="success"
              className="d-flex align-items-center gap-2"
              onClick={() => history.push(`/eventos/${param.id}/creartarifa`)}
            >
              <FontAwesomeIcon icon={faAdd} className="fs-6" />
              {t("button.add")}
            </Button>
            <form onSubmit={(e) => e.preventDefault()}>
              {showSearch ? (
                <div className="w-100 d-flex gap-2">
                  <Input
                    id="exampleSearch"
                    name="q"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Buscar.."
                    type="search"
                  />
                  <Button onClick={() => setShowSearch(false)} color="light">
                    <FontAwesomeIcon icon={faXmark} size="lg" style={{ color: "#3a3b3c" }} />
                  </Button>
                </div>
              ) : (
                <Button color="secondary" onClick={handleToggleSearch}>
                  <FontAwesomeIcon icon={faSearch} className="fs-6" />
                </Button>
              )}
            </form>
          </div>

          <h4 className="mt-3">
            Tarifas de tickets
          </h4>
          <Accordion flush open={open} toggle={toggle}>
            {tarifasTicket ? (tarifasTicket.map((tarifa) => (
              <AccordionItem  key={tarifa?.id}>
                <AccordionHeader targetId={tarifa?.id?.toString()}>{tarifa.concepto}</AccordionHeader>
                  <AccordionBody accordionId={tarifa?.id?.toString()}>
                    <p><b>{t("rates.unit")}</b>: {tarifa.unidad}</p>
                    <p><b>{t("rates.price")}</b>: {tarifa.precio}</p>
                    <p><b>Acciones</b>: 
                      <DeleteTarifaModal id={tarifa.id} callApi={callApi} />
                    </p>

                  </AccordionBody>
              </AccordionItem>
            ))
            ) : (
              <AccordionItem>No hay datos</AccordionItem>
            )}
          </Accordion>

          <h4 className="mt-3">
            Tarifas de vouchers
          </h4>
          <Accordion flush open={open} toggle={toggle}>
            {tarifasConsumicion ? (tarifasConsumicion.map((tarifa) => (
              <AccordionItem key={tarifa?.id}>
                <AccordionHeader targetId={tarifa?.id?.toString()}>{tarifa.concepto}</AccordionHeader>
                  <AccordionBody accordionId={tarifa?.id?.toString()}>
                    <p><b>{t("rates.unit")}</b>: {tarifa.unidad}</p>
                    <p><b>{t("rates.price")}</b>: {tarifa.precio}</p>
                    <p><b>Acciones</b>: 
                      <DeleteTarifaModal id={tarifa.id} callApi={callApi} />
                    </p>

                  </AccordionBody>
              </AccordionItem>
            ))
            ) : (
              <AccordionItem>No hay datos</AccordionItem>
            )}
          </Accordion>
          <div className="d-flex justify-content-end">
            <VolverButton />
          </div>
        </Container>
        
      ):(

        <Container fluid="sm">
          <div className="d-flex justify-content-between">
            <Button color="success"
              className="d-flex align-items-center gap-2"
              onClick={() => history.push(`/eventos/${param.id}/creartarifa`)}
            >
              <FontAwesomeIcon icon={faAdd} className="fs-6" />
              {t("button.add")}
            </Button>
            <form onSubmit={(e) => e.preventDefault()}>
              {showSearch ? (
                <div className="w-100 d-flex gap-2">
                  <Input
                    id="exampleSearch"
                    name="q"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    placeholder="Buscar.."
                    type="search"
                  />
                  <Button onClick={() => setShowSearch(false)} color="light">
                    <FontAwesomeIcon icon={faXmark} size="lg" style={{ color: "#3a3b3c" }} />
                  </Button>
                </div>
              ) : (
                <Button color="secondary" onClick={handleToggleSearch}>
                  <FontAwesomeIcon icon={faSearch} className="fs-6" />
                </Button>
              )}
            </form>
          </div>

          <h4 className="mt-3">
            Tarifas de tickets
          </h4>
          <Table hover responsive size="sm">
            <thead>
              <tr className="table-light">
                <th>{t("rates.rate")}</th>
                {/* <th>{t("rates.unit")}</th> */}
                <th>{t("rates.price")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tarifasTicket ? (tarifasTicket.map((tarifa) => (
                <tr key={tarifa.id}>
                  <td width={300}>{tarifa.concepto}</td>
                  {/* <td>{tarifa.unidad}</td> */}
                  <td width={200}>${tarifa.precio} / {tarifa.unidad}</td>
                  <td width={100} align="center">
                    <DeleteTarifaModal id={tarifa.id} callApi={callApi} />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <Alert color="secondary" className="text-center m-0">
                    No hay tickets aún.
                  </Alert>
                </td>
              </tr>
            )}
            </tbody>
          </Table>

          <h4 className="mt-3">
            Tarifas de vouchers
          </h4>
          <Table hover responsive size="sm">
            <thead>
              <tr className="table-light">
                <th>{t("rates.rate")}</th>
                {/* <th>{t("rates.unit")}</th> */}
                <th>{t("rates.price")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tarifasConsumicion ? (tarifasConsumicion.map((tarifa) => (
                <tr key={tarifa.id}>
                  <td width={300}>{tarifa.concepto}</td>
                  {/* <td>{tarifa.unidad}</td> */}
                  <td width={200}>${tarifa.precio} / {tarifa.unidad}</td>
                  <td width={100} align="center">
                    <DeleteTarifaModal id={tarifa.id} callApi={callApi} />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <Alert color="secondary" className="text-center m-0">
                    No hay vouchers aún.
                  </Alert>
                </td>
              </tr>
            )}
            </tbody>
          </Table>


          <div className="d-flex justify-content-end">
            <VolverButton />
          </div>
        </Container>
      )}
    </>
  );
};
export default withAuthenticationRequired(Tarifas, {
  onRedirecting: () => <Loading />,
});