import React, { useState, useEffect, useRef } from "react";
import Loading from "../../components/Loading";
import { useHistory } from "react-router-dom";
import { api } from "../../services/api";
import { useParams } from "react-router-dom";
import {
  Container,
  Table,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Alert,
  Spinner,
} from "reactstrap";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import DeleteCollaboratorModal from "../../components/modales/DeleteCollabModal";
import VolverButton from "../../components/VolverButton";
import { useTranslation } from "react-i18next";

export const Colaboradores = () => {
  const [state, setState] = useState({
    showResult: false,
    apiMessage: "",
    error: null,
  });
  const [evento, setEvento] = useState();
  const [eventocolaboradores, setEventoColaboradores] = useState();
  const history = useHistory();
  const param = useParams();
  const form = useRef();
  const [t] = useTranslation("global");
  const [userRole, setUserRole] = useState(null);
  const [subrol, setSubrol] = useState()

  useEffect(() => {
    const callApi = async () => {
      try {
        const response = await api.eventos.get(param.id);
        if (response.status === "success") {
          setEvento(response.data.evento);
          setEventoColaboradores(response.data.colaboradores);
        }
      } catch (error) {
        setState({
          ...state,
          error: "Error en la Red.",
        });
      }
    };
    callApi();
  }, []);

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const response = await api.participaciones.getParticipaciones();

        if (response.status === "success") {
          const rol = response.data.participaciones.find(
            (p) => p.idevento == param.id
          )?.idrol;
          setUserRole(rol);
        } else {
          setState({ ...state, error: "Error al obtener el rol." });
        }
      } catch (error) {
        setState({ ...state, error: "Error en la red." });
      }
    };
    fetchRole([]);
  }, [param.id]);

  const [email, setEmail] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);

    if (email === "") {
      setError("El correo electrónico es obligatorio");
      return;
    }

    if (!/\S+@\S+\.\S+/.test(email)) {
      setError("Introduce un correo electrónico válido");
      return;
    }

    const data = { email, subrol };
    const response = api.colaboradores.createColaborador(param.id, data);
    return response.then((response) => {
      if (response.status === "error") {
        setError("Error: " + response.message);
        setSubmitting(false);
      }
      if (response.status === "success") {
        setSubmitting(false);
        setSubmitted(true);
        setError("");
        const data = {
          recipient: email,
          evento: evento.evento,
        };
        const response = api.email.send(data);
        setEmail("");
        setTimeout(function () {
          history.go(0);
        }, 1500);
        if (response.status === "error") {
          setError("Error: " + response.message);
        }
      }
    });
  };

  return (
    <>
      {evento && (
        <Container fluid="sm">
          {userRole === 1 && (
            <>
              <Form onSubmit={handleSubmit} ref={form}>
                <FormGroup>
                  <Label for="email">{t("collaborator.email")}</Label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    placeholder={t("collaborator.enterEmail")}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="subrol">{t("Rol del Colaborador")} </Label>
                  <Input
                  type="select"
                  name="subrol"
                  id="subrol"
                  value={subrol}
                  onChange={(e) => setSubrol(Number(e.target.value))}
                  > 
                    <option value={0}>{t("Colaborador General")} </option>
                    <option value={2}>{t("Control de Acceso")} </option>
                    <option value={3}>{t("Control de Voucher")} </option>
                  </Input>
                </FormGroup>

                {submitting && (
                  <Alert color="warning">
                    {" "}
                    <Spinner color="primary" className="mr-3"></Spinner>{" "}
                    Cargando.. por favor espere
                  </Alert>
                )}
                {error && <Alert color="danger">{error}</Alert>}
                {submitted && (
                  <Alert color="success">
                    {t("collaborator.successEmailAlert")}
                  </Alert>
                )}
                <Button type="submit" color="primary">
                  {t("button.register")}
                </Button>
              </Form>
            </>
          )}
          <br />
          <Table responsive size="sm">
          <thead key="Colaboradores" >
              <tr  className="table-light">
                <th > 
                  Nombre
                </th>
                <th > 
                  Estado
                </th>
                <th >
                  Subrol
                </th>
                <th > 
                 {''}
                </th>
                </tr>
                </thead>
            <tbody>
              {eventocolaboradores?.length > 0 ? (
                eventocolaboradores.map((colaborador) => (
                  <tr key={colaborador.id}>
                    <th scope="row">{colaborador.colaborador}</th>
                    <td>{colaborador.estado.estado}</td>
                      <td>
                        { colaborador.subrol === 0 ? "General" 
                        : colaborador.subrol === 2 ? "Control de acceso"
                        : colaborador.subrol === 3 ? "Voucher" 
                        : "General"
                        }
                    </td>
                    <td style={{ textAlign: "right" }}>
                      {userRole === 1 &&
                        colaborador.idestado !== 3 &&
                        colaborador.idestado !== 4 && (
                          <DeleteCollaboratorModal
                            id={colaborador.id}
                          ></DeleteCollaboratorModal>
                        )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">
                    No hay colaboradores registrados
                  </td>
                </tr>
              )}
            </tbody>
          </Table>

          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <br />
            <VolverButton></VolverButton>
          </div>
        </Container>
      )}
    </>
  );
};

export default withAuthenticationRequired(Colaboradores, {
  onRedirecting: () => <Loading />,
});
