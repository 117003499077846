import React, { useEffect, useState } from 'react';
import { Button, Label, Form, FormGroup, Input, Container, FormFeedback } from 'reactstrap';
import { api } from '../../services/api';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import VolverButton from '../../components/VolverButton';
import { useHistory } from "react-router-dom";
import { TarifaDetalles } from "../../components/modales/TarifaDetalles"
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';


const CrearTarifa = () => {
  const [tarifasTipo, setTarifasTipo] = useState([]);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation("global");
  const param = useParams();
  const [selectedOption, setSelectedOption] = useState('de pago');
  const [asumirCostos, setAsumirCostos] = useState(false)

  const initForm = {
    idevento: param.id,
    idmoneda: 1,
    concepto: '',
    precio: 0,
    unidad: '',
    idtipo: '',
    limite_persona: 10,
    limite_online: 50,
    limite_maximo: 100,
  }
  const [formData, setFormData] = useState(initForm);

  const [formErrors, setFormErrors] = useState({
    concepto: false,
    precio: false,
    unidad: false,
    idtipo: false,
    limite_persona: false,
    limite_online: false,
    limite_maximo: false,
  });

  const callTipoTarifas = async () =>{
    try {
      const response = await api.tarifas.GetAllTarifasTipos();
      if(response.status === "success"){
        setTarifasTipo(response.data.tarifasTipo);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    callTipoTarifas();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    if (value.trim() !== "") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,  
      }));
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {
      concepto: formData.concepto.trim() === '',
      precio: selectedOption === 'de pago' ? !formData.precio : false,
      unidad: formData.unidad.trim() === '',
      idtipo: formData.idtipo == '',
      limite_persona: formData.limite_persona == '',
      limite_online: formData.limite_online == '',
      limite_maximo: formData.limite_maximo == '',
    };
    setFormErrors(errors);
    const isValid = Object.values(errors).every(error => !error);

    const newTarifa = {
      ...formData,
      idevento: parseInt(formData.idevento),
      precio: parseFloat(formData.precio),
      idtipo: parseInt(formData.idtipo),
      limite_persona: parseInt(formData.limite_persona),
      limite_online: parseInt(formData.limite_online),
      limite_maximo: parseInt(formData.limite_maximo),
    }
    
    if (isValid) {
      try {
        setLoading(true);
        
        const response = await api.tarifas.createTarifa(newTarifa);

        if (response.status === "success") {
          setTimeout(() => {
            history.goBack();

            toast.success("Tarifa creada correctamente.", {
              position: "top-right",
              autoClose: 2000,
            });
          }, 500);
        }
        else {
          throw new Error(response.message);
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 4000,
        });
      } finally {
        setLoading(false);
      }
    }
    else {
      toast.error("Asegurate de que todos los campos sean correctos", {
        position: "top-right",
        autoClose: 4000,
      });
    }
  }

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setAsumirCostos(true)
    } else {
      setAsumirCostos(false)
    }
  }

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  }

  if (loading) {
    return <Loading />
  }


  return (
    <Container fluid="sm" >
      <Form className="mx-auto col-md-8 col-lg-6 col-12"  onSubmit={handleSubmit} >
        <div className="d-flex justify-content-evenly">
          <p className={`py-2 px-4 border rounded-0 ${selectedOption === 'de pago' ? 'border-primary text-primary' : 'border-black'}`}
            style={{cursor: "pointer"}}
            onClick={() => handleOptionChange('de pago')}
          >
            De pago
          </p>
          <p className={`py-2 px-4 border rounded-0 ${selectedOption === 'gratis' ? 'border-primary text-primary' : 'border-black'}`}
            style={{cursor: "pointer"}}
            onClick={() => handleOptionChange('gratis')}
          >
            Gratis
          </p>
        </div>
        <FormGroup floating>
          <Input
            id="concepto"
            name="concepto"
            type="string"
            onChange={handleChange}
            placeholder={t("rates.concept")}
            invalid={formErrors.concepto}
          />
          <Label for="concepto">
            {t("rates.concept")}
          </Label>
          <FormFeedback tooltip>Este campo es obligatorio</FormFeedback>
        </FormGroup>
        
        <FormGroup floating>
          <Input
            id="precio"
            name="precio"
            type="number"
            min={0}
            onChange={handleChange}
            placeholder={t("rates.price")}
            disabled={selectedOption === 'gratis'}
            invalid= {selectedOption === 'de pago' && formErrors.precio} 
          />
          <Label for="precio">
            {t("rates.price")}
          </Label>
          <FormFeedback tooltip>Este campo es obligatorio</FormFeedback>
          {formData.precio > 0 && (
            <div className='d-flex justify-content-between'>
              <small style={{ color: 'gray' }}>
                {t("tickets.finalPrice")}: {asumirCostos ? parseFloat(formData.precio).toFixed(2) : parseFloat(formData.precio * 1.05).toFixed(2)}
              </small>
              <TarifaDetalles precio={Number(formData.precio)} asumirCostos={asumirCostos} />
            </div>
          )}

          {formData.precio > 0 && (
            <FormGroup check>
              <Input
                id="asumirCostos"
                name="asumirCostos"
                type="checkbox"
                onChange={handleCheckboxChange}
              />
              <Label check for="asumirCostos">
                {t("tickets.assumeTheCosts")}
              </Label>
            </FormGroup>
          )}
        </FormGroup>
        
        <FormGroup floating>
          <Input
            id="unidad"
            name="unidad"
            type="string"
            onChange={handleChange}
            placeholder={t("rates.unit")}
            invalid={formErrors.unidad} 
          />
          <Label for="unidad">
            {t("rates.unit")}
          </Label>
          <FormFeedback tooltip>Este campo es obligatorio</FormFeedback>
        </FormGroup>

        <FormGroup floating>
          <Input 
            id="idtipo" 
            name="idtipo" 
            type="select"
            onChange={handleChange}
            invalid={formErrors.idtipo} 
          >
            <option value="" defaultChecked>
              Seleccionar
            </option>
            {tarifasTipo?.map((tarifa) => (
              <option key={tarifa.id} value={tarifa.id}>
                {tarifa.tipo}
              </option>
            ))}
          </Input>
          <Label for="idtipo">
            {t("rates.type")}
          </Label>
          <FormFeedback tooltip>Este campo es obligatorio</FormFeedback>
        </FormGroup>

        <FormGroup floating>
          <Input
            id="limite_persona"
            name="limite_persona"
            type="number"
            min={1}
            defaultValue={formData.limite_persona}
            onChange={handleChange}
            placeholder={t("rates.limit")}
            invalid={formErrors.limite_persona} 
          />
          <Label for="limite_persona">
            {t("rates.limit")} de compra p/persona
          </Label>
          <FormFeedback tooltip>Este campo es obligatorio</FormFeedback>
        </FormGroup>

        <FormGroup floating>
          <Input
            id="limite_online"
            name="limite_online"
            type="number"
            min={1}
            defaultValue={formData.limite_online}
            onChange={handleChange}
            placeholder={t("rates.limit")}
            invalid={formErrors.limite_online} 
          />
          <Label for="limite_online">
            {t("rates.limit")} de ventas online
          </Label>
          <FormFeedback tooltip>Este campo es obligatorio</FormFeedback>
        </FormGroup>

        <FormGroup floating>
          <Input
            id="limite_maximo"
            name="limite_maximo"
            type="number"
            min={1}
            defaultValue={formData.limite_maximo}
            onChange={handleChange}
            placeholder={t("rates.limit")}
            invalid={formErrors.limite_maximo} 
          />
          <Label for="limite_online">
            {t("rates.limit")} máximo de tarifa
          </Label>
          <FormFeedback tooltip>Este campo es obligatorio</FormFeedback>
        </FormGroup>

        <Button type="submit" style={{margin: "5px"}} color="primary">
          {t("button.create")}
        </Button>
      </Form>
      <div className="d-flex justify-content-end">
        <VolverButton/>
      </div>
    </Container>
  );
};

export default CrearTarifa;