import React, { useState, useEffect, useRef } from "react";
import { Alert, Button, Col, Form, FormGroup, Input, Label } from "reactstrap";
import { api } from "../../../services/api";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import "./event.css";

const EditEvent = () => {
  const { id } = useParams();
  const history = useHistory();
  const [ownerid, setOwnerId] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [eventos_tipos_id, setEventotipo] = useState("");
  const [tipos, setTipos] = useState("");
  const [fecha, setFecha] = useState("");
  const [hora, setHora] = useState("");
  const [publicoprivado, setPublicoPrivado] = useState(false);
  const [direccion, setDireccion] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState("");
  const [expandedSection, setExpandedSection] = useState(null);
  const refSeccion = useRef({});
  const [evento, setEvento] = useState("");
  const [t] = useTranslation("global");
  const localTime = moment.tz.guess();
  const [files, setFiles] = useState([]);
  const [mediaUrls, setMediaUrls] = useState([]);
  const [mediaTypes, setMediaTypes] = useState([]);
  const [mediaFormats, setMediaFormats] = useState([]);
  const [mediaId, setMediaId] = useState([]);
  const fileInputRef = useRef(null);
  const [mapUrl, setMapUrl] = useState('');
  const [lengthDescrip, setLengthDescrip] = useState("");
  const [showEliminar, setShowEliminar] = useState(false);

  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await api.eventos.get(id);
        if (response.status === "success") {
          const event = response.data.evento;
          setOwnerId(event.ownerid)
          setEvento(event.evento);
          setDescripcion(event.descripcion);
          setEventotipo(event.eventos_tipos_id);
          setFecha(moment(event.fecha).format("YYYY-MM-DD"));
          setHora(moment(event.hora).format("HH:mm"));
          setDireccion(event.direccion);
          setCiudad(event.ciudad);
          setPublicoPrivado(event.privado);
          setMediaUrls(event.attachs.map(attach => attach.url));
          setMediaTypes(event.attachs.map(attach => attach.type));
          setMediaFormats(event.attachs.map(attach => attach.format));
          setMediaId(event.attachs.map(attach => attach.public_id));

        }
      } catch (error) {
        console.error("Error fetching event data: " + error);
      }
    };
    fetchEventData();
  }, [id]);

  useEffect(() => {
    const getEventosTipos = async () => {
      try {
        const response = await api.eventos.getAllEventosTipos();
        if (response.status === "success") {
          setTipos(response.data.eventoTipos);
        }
      } catch (error) {
        console.error("Error: " + error);
      }
    };
    getEventosTipos();
  }, []);

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await api.media.upload(formData);
      if (response.status === "success") {
        const medias = response.data.media;
        setMediaUrls(medias.map((media) => media.url));
        setMediaTypes(medias.map((media) => media.type));
        setMediaFormats(medias.map((media) => media.format));
        setMediaId(medias.map((media) => media.public_id));
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const deleteMedia = async (index) => {
    const publicId = mediaId[index];

    try {
      const response = await api.media.delete({ public_id: publicId });
      if (response.status === "success") {
        setMediaUrls(mediaUrls.filter((_, i) => i !== index));
        setMediaTypes(mediaTypes.filter((_, i) => i !== index));
        setMediaFormats(mediaFormats.filter((_, i) => i !== index));
        setMediaId(mediaId.filter((_, i) => i !== index));

        setFiles(files.filter((_, i) => i !== index));

      }
    } catch (error) {
      console.error("Error deleting media:", error);
    }
  };

  const handleSectionClick = (section) => {
    setExpandedSection(expandedSection === section ? null : section);
  };

  const handleInputClick = (e) => {
    e.stopPropagation();
  };

  const handleDescripcionChange = (e) => {
    const value = e.target.value;
    setDescripcion(value);
    setLengthDescrip(value.length);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const regexE = /^(?!([a-zA-Z])\1+$)[a-zA-Z\sñÑ\u00C0-\u017F]*$/;
    const hoy = new Date();
    const fechasel = new Date(fecha);
    const fechamax = new Date().setFullYear(hoy.getFullYear() + 1);

    if (evento.trim() === "") {
      setError(<>{t("errors.emptyField")}</>);
      return;
    }

    if (!regexE.test(evento)) {
      setError(<>{t("errors.charactersEvent")}</>);
      return;
    }

    if (evento.length > 50) {
      setError(<>{t("errors.eventName")}</>);
      return;
    }

    if (evento.length < 5) {
      setError(<>{t("errors.eventName2")}</>);
      return;
    }

    if (descripcion.trim() === "") {
      setError(<>{t("errors.emptyField")}</>);
      return;
    }

    if (descripcion.length > 250) {
      setError(<>{t("errors.charactersDescription")}</>);
      return;
    }

    if (descripcion.length < 5) {
      setError(<>{t("errors.characterDescription2")}</>);
      return;
    }

    if (eventos_tipos_id === "") {
      setError(<>{t("errors.selectEvent")}</>);
      return;
    }

    if (fecha === "") {
      setError(<>{t("errors.date")}</>);
      return;
    }

    if (fechasel > fechamax) {
      setError(<>{t("errors.errorDate")}</>);
      return;
    }

    if (hora === "") {
      setError(<>{t("errors.time")}</>);
      return;
    }
    if (direccion === "") {
      setError(<>{t("errors.errorAddress")}</>);
    }
    if (ciudad === "") {
      setError(<>{t("errors.errorCity")}</>);
    }

    /*  const isPrivado = publicoprivado === "true"; */
    const fechaEnHorarioLocal = moment
      .tz(`${fecha} ${hora}`, localTime)
      .format("YYYY-MM-DDTHH:mm:ss");
    const horaEnHorarioLocal = moment
      .tz(`${fecha} ${hora}`, localTime)
      .format("YYYY-MM-DDTHH:mm:ss");


    const data = {
      evento: {
        ownerid: parseInt(ownerid),
        id: parseInt(id),
        evento: evento,
        descripcion: descripcion,
        fecha: moment(fechaEnHorarioLocal).clone().tz("America/Buenos_Aires").format("YYYY-MM-DDTHH:mm:ss-03:00"),
        hora: moment(horaEnHorarioLocal).clone().tz("America/Buenos_Aires").format("YYYY-MM-DDTHH:mm:ss-03:00"),
        eventos_tipos_id: eventos_tipos_id,
        privado: publicoprivado,
        direccion: direccion,
        ciudad: ciudad,
      },
      attachs: mediaUrls.map((url, index) => ({
        url: url,
        type: mediaTypes[index],
        format: mediaFormats[index],
        public_id: mediaId[index],
      })),
    };

    try {
      const response = await api.eventos.setEvento(id, data);
      if (response.status === "error") {
        setError("Error: " + response.message);
      }
      if (response.status === "success") {
        setEvento("");
        setDescripcion("");
        setEventotipo("");
        setFecha("");
        setHora("");
        setPublicoPrivado("");
        setDireccion("");
        setCiudad("");
        setMediaUrls("");
        setMediaTypes("");
        setMediaFormats("");
        setMediaId("");
        setError("");
        setSubmitted(true);
        setTimeout(() => {
          history.push("/eventos");
        }, 1500);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const [enviado, setEnviado] = useState(false);
  const [errorEliminar, setErrorEliminar] = useState(false)
  const [inputNombreEvento, setInputNombreEvento] = useState("");
  const [errorValidacion, setErrorValidacion] = useState(false)
  const handleEliminarEvento = async () => {
    if (inputNombreEvento === evento) {
      try {
        const response = await api.eventos.deleteEvento(id);
        if (response.status === "success") {
          console.log("Evento eliminado");
          setEnviado(true);
          setTimeout(() => {
            history.push(`/eventos/`);
          }, 3000);
        }
        else {
          setErrorValidacion(true)
          setErrorEliminar(false)
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    } else {
      setErrorEliminar(true)
      setErrorValidacion(false)
    }
  };

  const mapdireccion = direccion + " " + ciudad;
  const apiKey = "AIzaSyCzPvPffhA4gns9-sfxzSlXXC4ytQtbKxc";

  useEffect(() => {
    if (mapdireccion) {
      const direccionEncoded = encodeURIComponent(mapdireccion);
      const newMapUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${direccionEncoded}`;

      setMapUrl(newMapUrl);
    }
  }, [mapdireccion, apiKey]);

  return (
    <div className="container-evento">
      <Form id="edit-evento" onSubmit={handleSubmit} style={{ padding: "20px" }}>
        <div ref={(el) => (refSeccion.current["img"] = el)} className={`card-form-evento ${expandedSection === "img" ? "expanded" : ""}`} onClick={() => handleSectionClick("img")}>
          <div className="d-flex justify-content-between align-items-center">
            <p style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "4px" }} className={`title-card-evento ${expandedSection === "img" ? "hidden" : ""}`}>
              {t("event.image")}
            </p>
            <button type="button" onClick={() => handleSectionClick("img")} className={`d-flex justify-content-end ${expandedSection === "img" ? "hidden" : ""}`} >
              <i style={{ color: "green", border: "1px solid green", borderRadius: "100%" }} className={`fs-5 material-symbols-outlined ${expandedSection === "img" ? "hidden" : ""}`}>add</i>
            </button>
          </div>
          <p style={{ fontSize: "14px", marginBottom: "4px" }} className={`txt-card-evento ${expandedSection === "img" ? "hidden" : ""}`}>
            {t("event.imageDescrip")}
          </p>


          {expandedSection === "img" && (
            <FormGroup onClick={handleInputClick}>
              <div className="d-flex justify-content-between align-items-center">
                <p className="title-open-card">{t("event.addImage")}</p>
                <button type="button" onClick={() => handleSectionClick("img")} className="d-flex justify-content-end">
                  <i style={{ color: "#3659E3", border: "1px solid #3659E3", borderRadius: "100%" }} className="fs-5 material-symbols-outlined">remove</i>
                </button>
              </div>
              <p className="txt-open-card">
                {t("event.imageDescrip2")}
              </p>
              <p style={{ marginBottom: "10px" }} className={`txt-open-card`}>Puedes subir hasta 5 imagenes.</p>
              <div className="inputFile" >
                <Input
                  id="files"
                  name="files"
                  type="file"
                  ref={fileInputRef}
                  accept="image/*,video/*"
                  multiple
                  onChange={handleFileChange}
                  onClick={handleInputClick}
                  style={{ display: "none" }}
                />

                <label className="" style={{ fontSize: "14px", cursor: "pointer", textAlign: "center", border: "2px dashed #C1C0C3", borderRadius: "5px", padding: "20px" }} htmlFor="files"><i className="material-symbols-outlined" style={{ fontSize: "35px", color: "#C1C0C3" }}>
                  image
                </i><br></br>{t("event.inputImage")}</label>
                <div className="ul-input-img">
                  <ul className="ul-img">
                    <li style={{ fontSize: "10px", fontWeight: "normal" }}>Tamaño recomendado de imagen: 19200x1280</li>
                    <li style={{ fontSize: "10px", fontWeight: "normal" }}>Tamaño máximo de archivo: 15MB</li>
                  </ul>
                </div>

                {mediaUrls.length > 0 && (
                  <div className="uploaded-media d-flex flex-wrap">
                    {mediaUrls.map((url, index) => (
                      <div className="renglon-media">
                        <div key={index} className="media-preview d-flex my-3">
                          <img src={url} alt={`Media ${index}`} className="object-fit-cover"
                            style={{
                              width: "50px", height: "50px",
                              objectPosition: "center",
                            }} />
                          <div className="content-preview">
                            <div className="text-preview">
                              <p className="title-preview">{`Archivo ${index + 1}`}</p>
                              <p className="title-preview">png</p>
                            </div>
                            <button className="btn-preview" type="button" onClick={() => deleteMedia(index)}>
                              <i className="material-symbols-outlined" style={{ color: "red", fontSize: "22px" }}>
                                delete
                              </i>
                            </button>

                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>

            </FormGroup>
          )}
        </div>

        <div ref={(el) => (refSeccion.current["general"] = el)} className={`card-form-evento ${expandedSection === "general" ? "expanded" : ""}`} onClick={() => handleSectionClick("general")}>
          <div className="d-flex justify-content-between align-items-center">
            <p style={{ fontSize: "38px", fontWeight: "bolder", marginBottom: "3px" }} className={`title-card-evento ${expandedSection === "general" ? "hidden" : ""}`}>
              {t("event.eventName")}
            </p>
            <button type="button" onClick={() => handleSectionClick("general")} className={`d-flex justify-content-end ${expandedSection === "general" ? "hidden" : ""}`} >
              <i style={{ color: "green", border: "1px solid green", borderRadius: "100%" }} className={`fs-5 material-symbols-outlined ${expandedSection === "general" ? "hidden" : ""}`}>add</i>
            </button>
          </div>
          <p style={{ fontSize: "14px", marginBottom: "4px" }} className={`txt-card-evento ${expandedSection === "general" ? "hidden" : ""}`}>
            {t("event.eventNameDescrip")}
          </p>

          {expandedSection === "general" && (
            <div className="contenido-evento">
              <FormGroup onClick={handleInputClick}>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="title-open-card">
                    {t("event.eventDescrip")}
                  </p>
                  <button type="button" onClick={() => handleSectionClick("general")} className="d-flex justify-content-end">
                    <i style={{ color: "#3659E3", border: "1px solid #3659E3", borderRadius: "100%" }} className={`fs-5 material-symbols-outlined`}>remove</i>
                  </button>
                </div>

                <p className="label-open-card">{t("event.eventName")}</p>
                <p className="txt-open-card">
                  {t("event.eventDescrip2")}
                </p>

                <Input
                  type="text"
                  name="evento"
                  id="evento"
                  value={evento}
                  onChange={(e) => setEvento(e.target.value)}
                  onClick={handleInputClick}
                  placeholder={t("event.eventName")}
                />
              </FormGroup>

              <FormGroup onClick={handleInputClick}>
                <p className="label-open-card">{t("event.description")}</p>
                <p className="txt-open-card">
                  {t("event.descriptionXl")}
                </p>
                <Input
                  type="textarea"
                  name="descripcion"
                  id="descripcion"
                  minLength={5}
                  maxLength={250}
                  value={descripcion}
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                    handleDescripcionChange(e);
                  }}
                  onClick={handleInputClick}
                  placeholder={t("event.description")}
                  style={{ resize: "none", fontSize: "14px", height: "80px" }}
                />
                {lengthDescrip === 250 ? (
                  <p className="text-right mt-1" style={{ color: "#c44536", fontSize: "12px" }}>
                    {lengthDescrip}/250
                  </p>
                ) : (
                  <p className="text-right mt-1" style={{ fontSize: "12px", color: "#666" }}>
                    {lengthDescrip}/250
                  </p>
                )}
              </FormGroup>
            </div>
          )}
        </div>
        <div ref={(el) => (refSeccion.current["dateLocation"] = el)} className={`card-form-evento ${expandedSection === "dateLocation" ? "expanded" : ""}`} onClick={() => handleSectionClick("dateLocation")}>
          <div className="d-flex justify-content-between align-items-center">
            <p style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "4px" }} className={`title-card-evento ${expandedSection === "dateLocation" ? "hidden" : ""}`}>
              {t("event.dateandplace")}
            </p>
            <button type="button" onClick={() => handleSectionClick("dateLocation")} className={`d-flex justify-content-end ${expandedSection === "dateLocation" ? "hidden" : ""}`} >
              <i style={{ color: "green", border: "1px solid green", borderRadius: "100%" }} className={`fs-5 material-symbols-outlined ${expandedSection === "dateLocation" ? "hidden" : ""}`}>add</i>
            </button>
          </div>
          <p style={{ fontSize: "14px", marginBottom: "4px" }} className={`txt-card-evento ${expandedSection === "dateLocation" ? "hidden" : ""}`}>
            {t("event.dateDescrip")}
          </p>
          {expandedSection === "dateLocation" && (
            <div className="contenido-evento">
              <div className="d-flex justify-content-between align-items-center">
                <p className="title-open-card">
                  {t("event.dateandtime")}
                </p>
                <button type="button" onClick={() => handleSectionClick("dateLocation")} className="d-flex justify-content-end">
                  <i style={{ color: "#3659E3", border: "1px solid #3659E3", borderRadius: "100%" }} className={`fs-5 material-symbols-outlined`}>remove</i>
                </button>
              </div>
              <div className="d-flex flex-wrap gap-3 align-items-center">
                <FormGroup floating onClick={handleInputClick}>
                  <Input
                    type="date"
                    name="fecha"
                    id="fecha"
                    value={fecha}
                    onClick={handleInputClick}
                    onChange={(e) => setFecha(e.target.value)}
                    required
                  />
                  <Label>{t("event.date")}</Label>
                </FormGroup>

                <FormGroup floating onClick={handleInputClick}>
                  <Input
                    type="time"
                    name="hora"
                    id="hora"
                    value={hora}
                    onClick={handleInputClick}
                    onChange={(e) => setHora(e.target.value)}
                    required
                  />
                  <Label>{t("event.time")}</Label>
                </FormGroup>
              </div>

              <p className="title-open-card mt-2">{t("event.location")}</p>
              <div className="d-flex flex-wrap gap-3 align-items-center">
                <FormGroup onClick={handleInputClick} className="" style={{ width: "55%" }}>
                  <Input
                    type="text"
                    name="direccion"
                    placeholder={t("event.address")}
                    onClick={handleInputClick}
                    value={direccion}
                    onChange={(e) => setDireccion(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup onClick={handleInputClick} style={{ width: "38%" }}>
                  <Input
                    type="text"
                    name="ciudad"
                    placeholder={t("event.city")}
                    onClick={handleInputClick}
                    value={ciudad}
                    onChange={(e) => setCiudad(e.target.value)}
                    required
                  />
                </FormGroup>
              </div>

              <div className="d-flex justify-content-center" >
                <iframe
                  className="rounded shadow"
                  width="100%"
                  height="230"
                  id="map"
                  loading="lazy"
                  allowFullScreen
                  referrerPolicy="no-referrer-when-downgrade"
                  src={mapUrl}>
                </iframe>
              </div>

            </div>
          )}
        </div>
        <div ref={(el) => (refSeccion.current["details"] = el)} className={`card-form-evento ${expandedSection === "details" ? "expanded" : ""}`} onClick={() => handleSectionClick("details")}>
          <div className="d-flex justify-content-between align-items-center">
            <p style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "4px" }} className={`title-card-evento ${expandedSection === "details" ? "hidden" : ""}`}>
              {t("event.details")}
            </p>
            <button type="button" onClick={() => handleSectionClick("details")} className={`d-flex justify-content-end ${expandedSection === "details" ? "hidden" : ""}`} >
              <i style={{ color: "green", border: "1px solid green", borderRadius: "100%" }} className={`fs-5 material-symbols-outlined ${expandedSection === "details" ? "hidden" : ""}`}>add</i>
            </button>
          </div>
          <p style={{ fontSize: "14px", marginBottom: "4px" }} className={`txt-card-evento ${expandedSection === "details" ? "hidden" : ""}`}>
            {t("event.detailsDescrip")}
          </p>

          {expandedSection === "details" && (
            <div className="contenido-evento">
              <div className="d-flex justify-content-between align-items-center">
                <p className="title-open-card">
                  {t("event.details")}
                </p>
                <button type="button" onClick={() => handleSectionClick("details")} className="d-flex justify-content-end">
                  <i style={{ color: "#3659E3", border: "1px solid #3659E3", borderRadius: "100%" }} className={`fs-5 material-symbols-outlined`}>remove</i>
                </button>
              </div>
              <FormGroup onClick={handleInputClick}>
                <p className="label-open-card">{t("event.eventType")}</p>
                <p className="txt-open-card">Selecciona qué tipo de evento es</p>
                <Col style={{ padding: "0px" }}>
                  {tipos?.length > 0 ? (
                    <Input
                      id="eventos_tipos_id"
                      name="eventos_tipos_id"
                      type="select"
                      value={eventos_tipos_id}
                      onClick={handleInputClick}
                      onChange={(e) => setEventotipo(parseInt(e.target.value))}
                      required
                    >
                      <option value="" disabled>
                        {" "}
                        {t("event.eventType")}{" "}
                      </option>
                      {tipos.map((tipo) => (
                        <option key={tipo.id} value={tipo.id}>
                          {" "}
                          {tipo.evento_tipo}{" "}
                        </option>
                      ))}
                    </Input>
                  ) : (
                    <p>{t("event.loadingError")}..</p>
                  )}
                </Col>
              </FormGroup>
            </div>
          )}
        </div>
        {error && <Alert color="danger">{error}</Alert>}
        {submitted && <Alert color="success">{t("event.editAlert")}</Alert>}
        
        <div className="btn-form-edit">

          <Button type="button" color="danger" className="delete-form-edit"onClick={() => setShowEliminar(true)}>{t("event.deleteEvent")}</Button>

          <Button type="submit" form="edit-evento" color="primary">{t("button.edit")}</Button>{" "}


        </div>
      </Form>


      {showEliminar && (
        <Form className="d-flex justify-content-center">
          <div className='card-form-evento'>
            <p className='label-open-card'>
              {t("event.deleteEvent")}
            </p>
            <div className="contenido-evento">
              <FormGroup>
                <p className="txt-open-card" style={{ userSelect: "none", msUserSelect: "none", WebkitUserSelect: "none" }}>
                  {t("event.deleteEvent-p1")} "<b>{evento}</b>" {t("event.deleteEvent-p2")}
                </p>

                <Input
                  type="text"
                  name="inputNombreEvento"
                  onChange={(e) => setInputNombreEvento(e.target.value)}
                  value={inputNombreEvento}
                />
              </FormGroup>
              {errorEliminar && (
                <div className="mt-3">
                  <Alert color="danger">{t("event.deleteError")}</Alert>
                </div>
              )}
              {enviado && (
                <div className="mt-3">
                  <Alert>{t("event.successAlert")}</Alert>
                </div>
              )}
              {errorValidacion && (
                <div className="mt-3">
                  <Alert color="danger">
                    <h5 className="alert-heading">
                      Atencion
                    </h5>
                    <p>
                      {t("event.deleteValidationError")}
                    </p>
                  </Alert>
                </div>
              )}
              <Button color="danger" className="txt-open-card" onClick={handleEliminarEvento}>{t("button.delete")}</Button>
            </div>
          </div>

        </Form>
      )}

    </div>
  );
};

export default EditEvent;
