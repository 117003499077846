import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import moment from 'moment-timezone';
import { useMediaQuery } from "@mui/material";
import {
  Container,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Pagination,
  IconButton,
  Alert,
  CircularProgress 
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Loading from "../../../components/Loading";
import VolverButton from "../../../components/VolverButton";
import { api } from "../../../services/api";

moment.locale('es');
moment.locale('en-GB');

const Publicaciones = () => {
  const [state, setState] = useState({ showResult: false, apiMessage: "", error: null });
  const history = useHistory();
  const param = useParams();
  const { id: eventoId } = useParams();
  const { i18n, t } = useTranslation("global");

  const [publicaciones, setPublicaciones] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalLength, setTotalLength] = useState(0);
  const [loading, setLoading] = useState(true);
  const [evento, setEvento] = useState();
  const [showSearch, setShowSearch] = useState(false);
  const [query, setQuery] = useState("");
  const isMobile = useMediaQuery('(max-width:390px)');

  moment.locale(i18n.language === "es" ? "es" : "en-gb");

  const formatFecha = (fecha) => {
    if (i18n.language === 'es') {
      return isMobile
        ? moment(fecha).clone().local().format("DD/MM/YY")
        : moment(fecha).clone().local().format("D [de] MMMM YYYY");
    } else {
      return isMobile
        ? moment(fecha).clone().local().format("MM/DD/YY")
        : moment(fecha).clone().local().format("MMMM D, YYYY");
    }
  };

  useEffect(() => {
    const callApi = async () => {
      try {
        const response = await api.publicaciones.getAll(eventoId, query, pageSize, currentPage);
        if (response.status === "success") {
          setPublicaciones(response.data.publicaciones || []);
          setTotalLength(response.data.totalDataSize);
        } else {
          setPublicaciones([]);
          setTotalLength(0);
        }
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          error: "Error en la Red.",
        }));
      } finally {
        setLoading(false);
      }
    };

    callApi();
  }, [eventoId, query, pageSize, currentPage]);

  useEffect(() => {
    const callEventoNombre = async () => {
      try {
        const response = await api.eventos.get(eventoId);
        if (response.status === "success") {
          setEvento(response.data.evento);
        }
      } catch (error) {
        setState(prevState => ({
          ...prevState,
          error: "Error en la Red.",
        }));
      }
    };
    callEventoNombre();
  }, [eventoId]);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "¿Estás seguro de que deseas eliminar esta publicación?"
    );
    if (!confirmDelete) return;

    try {
      const response = await api.publicaciones.deletePublicacion(id);
      if (response.status === "success") {
        setPublicaciones(publicaciones.filter((pub) => pub.id !== id));
      }
    } catch (error) {
      setState(prevState => ({
        ...prevState,
        error: "Error al eliminar la publicación.",
      }));
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleToggleSearch = () => {
    setShowSearch(!showSearch);
    if (showSearch) setQuery('');
  };

  return (
    <Container maxWidth={isMobile ? "xs" : "lg"}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }}>

        {showSearch ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Buscar.."
              variant="outlined"
              size="small"
            />
            <IconButton onClick={handleToggleSearch}>
              <CloseOutlinedIcon />
            </IconButton>
          </div>
        ) : (
          <IconButton onClick={handleToggleSearch}>
            <SearchOutlinedIcon />
          </IconButton>
        )}
        <Button
          sx={{
            backgroundColor: "#FFC300",
            color: "black",
            outline: "none",
            borderRadius: "5px",
            textTransform: "none",
            fontFamily: 'Inter, sans-serif',
            '&:hover': {
              backgroundColor: "#FFB000",
            }
          }}
          startIcon={<AddOutlinedIcon sx={{ marginLeft: "5px" }} />}
          onClick={() => history.push(`/eventos/${param.id}/publicaciones/nueva`)}
        >
          Crear publicacion
        </Button>
      </div>

      {publicaciones.length > 0 || loading ? (
        <>
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead sx={{ backgroundColor: "#EDEDED" }}>
                <TableRow>
                  <TableCell sx={{ fontFamily: 'Inter, sans-serif', padding: "12px" }}>
                    {t("publications.title")}
                  </TableCell>
                  <TableCell sx={{ fontFamily: 'Inter, sans-serif' }}>
                    {t("publications.subtitle")}
                  </TableCell>
                  <TableCell sx={{ fontFamily: 'Inter, sans-serif' }}>Inicia</TableCell>
                  <TableCell sx={{ fontFamily: 'Inter, sans-serif' }}>Finaliza</TableCell>
                  <TableCell sx={{ fontFamily: 'Inter, sans-serif' }} align="right">
                    Acciones
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <CircularProgress color="primary" />
                    </TableCell>
                  </TableRow>
                ) : (
                  publicaciones.map((pub) => (
                    <TableRow key={pub.id}>
                      <TableCell sx={{ fontFamily: 'Inter, sans-serif' }}>{pub.titulo}</TableCell>
                      <TableCell sx={{ fontFamily: 'Inter, sans-serif' }}>{pub.subtitulo}</TableCell>
                      <TableCell sx={{ fontFamily: 'Inter, sans-serif' }}>{formatFecha(pub.desde)}</TableCell>
                      <TableCell sx={{ fontFamily: 'Inter, sans-serif' }}>{formatFecha(pub.hasta)}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          sx={{ color: "red" }}
                          onClick={() => handleDelete(pub.id)}
                        >
                          <DeleteForeverIcon sx={{ fontSize: "27px" }} />
                        </IconButton>
                        <IconButton
                          color="primary"
                          onClick={() => history.push(`/publicaciones/${pub.id}/editar`)}
                        >
                          <EditOutlinedIcon sx={{ fontSize: "27px" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {!loading && (
            <div style={{ display: "flex", alignItems: "center", marginTop: "1rem", position: "relative" }}>
              <small>{t("publications.totalEntries")} {totalLength}</small>
              <div style={{ margin: "0 auto" }}>
                <Pagination
                  count={Math.ceil(totalLength / pageSize)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <Alert severity="info">{t("publications.publicationsAlert")}</Alert>
      )}

      <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "1rem" }}>
        <VolverButton />
      </div>
    </Container>
  );
};

export default withAuthenticationRequired(Publicaciones, {
  onRedirecting: () => <Loading />,
});