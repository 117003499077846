import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../components/Loading";
import { api } from "../../services/api";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import "moment/locale/es";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { useMediaQuery } from "react-responsive";
import { MensajeFinalizarCreacionEvento } from "../../components/modales/MensajeFinalizarCreacionEvento";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
  Container,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Alert,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import { Input, Spinner } from "reactstrap";
import { toast } from "react-toastify";
moment.locale('es');
moment.locale('en-GB');


export const Evento = () => {
  const [data, setData] = useState([]);
  const history = useHistory();
  const { i18n, t } = useTranslation("global");
  const isMobile = useMediaQuery({ query: `(max-width: 770px)` });
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");
  const [filtroEventos, setFiltroEventos] = useState("Todo");
  const [eventosFiltrados, setEventosFiltrados] = useState([]);
  const [open, setOpen] = useState(false);
  const [eventoId, setEventoId] = useState(null);

  const handleOpen = (id) => {
    setEventoId(id);  
    setOpen(true);
  };

  const handleClose = () => {
    setEventoId(null); 
    setOpen(false);
  };
  
  useEffect(() => {
    const callApi = async () => {
      setLoading(true);

      try {
        const response = await api.eventos.getAll(query);

        if (response.status === "success") {
          setData(response.data.eventos);
        }
      } catch (error) {
        toast.error("Error en la red.", {
          position: "top-center",
          autoClose: 4000,
        });
      } finally {
        setLoading(false);
      }
    };

    callApi();
  }, [query]);

  useEffect(() => {
    let filtro = data;
    
    if (filtroEventos !== "Todo") {
      filtro = filtro?.filter((evento) => 
        evento?.estado?.estado === filtroEventos
      )
    }
    
    setEventosFiltrados(filtro);
  }, [filtroEventos, data]);

  const handleRowClick = (evento) => {
    history.push("/eventos/" + evento.id);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setQuery(e.target.q.value); // Asigna el valor del input a query
  };

  const colorCirculo = (estado) => {
    switch (estado) {
      case "Borrador":
        return "#00f";
      case "Activo":
        return "#0f0";
      case "Finalizado":
        return "grey";
      case "Eliminado":
        return "#f00";
      default:
        return "transparent";
    }
  }
  
  return (
    <>
      {isMobile ? (
        <div className="py-4 w-100 h-100 d-flex flex-column justify-content-between">
          <div>
          {data?.map((evento) => (
            <>
              <div
                className="container d-flex align-items-center py-3 px-4"
                key={evento.id}
              >
                <div
                  className="avatar-evento"
                  style={{ width: "60px", height: "60px" }}
                >
                  {evento.attachs && evento.attachs.length > 0 ? (
                    <img
                      src={evento.attachs[0].url}
                      key={evento.attachs[0].id}
                      alt="Fiesta"
                      className="object-fit-cover rounded-circle"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectPosition: "center",
                      }}
                    />
                  ) : (
                    <img
                      src={process.env.PUBLIC_URL + "/img_fiesta.jpg"}
                      alt="Fiesta"
                      className="object-fit-cover rounded-circle"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectPosition: "center",
                      }}
                    ></img>
                  )}
                </div>
                <div
                  className="info-evento d-flex justify-content-between "
                  style={{ flex: 1 }}
                >
                  <div
                    className="ml-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleRowClick(evento)}
                  >
                    <p className="mb-0 fw-semibold text-black fs-5">
                      {evento.evento}
                    </p>
                    <div
                      className="align-items-center text-body-secondary"
                      style={{ fontSize: "14px" }}
                    >
                      <p className="m-0 fw-medium">
                      {i18n.language === 'es' ? (
                        moment(evento.fecha).clone().local().format("D [de] MMMM YYYY")
                      ):(
                        moment(evento.fecha).clone().local().format("MMMM D[,] YYYY")
                      )}{" "}
                        | {moment(evento.hora).clone().local().format("HH:mm")}
                        <span
                          style={{ fontSize: "15px" }}
                          className="text-body-secondary fw-medium ml-1"
                        >
                          {evento.rol}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="solicutudes-evento d-flex align-items-center justify-content-center">
                    {evento.solicitudes ? (
                      <a
                        className="text-primary d-flex flex-column"
                        style={{ cursor: "pointer" }}
                        href={history.push(`/eventos/${evento.id}/ventas`)}
                      >
                        <i
                          className="material-symbols-outlined text-primary"
                          style={{ width: "20px", height: "20px" }}
                        >
                          notifications
                        </i>
                        <p className="fw-bold fs-6 m-0 text-center">
                          {evento.solicitudes}
                        </p>
                      </a>
                    ) : (
                      <i
                        className="material-symbols-outlined ext-secondary"
                        style={{ width: "20px", height: "20px" }}
                      >
                        notifications
                      </i>
                    )}
                  </div>
                </div>
              </div>
              <div className="px-4 w-100">
                <hr className="m-0"></hr>
              </div>
            </>
          ))}
        </div>
        <div
            className="mr-4"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              className="d-flex justify-content-end align-items-center btn btn-dark"
              onClick={() => history.push("/crearEvento/creacion")}
            >
              <i className="material-symbols-outlined">add</i>
              {t("event.addEvent")}
            </Button>
          </div>
        </div>
      ) : (
        <Container className="my-3" maxWidth="lg">
          <div className="py-2">
            <h1 style={{color: '#0B0062'}}>
              {t("navbar.myEvents")}
            </h1>
            <p>{t("event.controlEvent")}</p>
          </div>

          <div className="d-flex justify-content-between gap-3 mb-4">
            <div className="d-flex gap-3">
              <form onSubmit={handleSearch}>
                <Input
                  id="exampleSearch"
                  name="q"
                  style={{height: '2.5rem'}}
                  placeholder="Buscar evento"
                  type="search"
                />
              </form>

              <Select
                id="filtro-eventos"
                label="filtro-eventos"
                value={filtroEventos}
                onChange={(e) => setFiltroEventos(e.target.value)}
                sx={{height: '2.5rem', color: '#fff', backgroundColor: '#5656FF'}}
              >
                <MenuItem value="Todo">Todos</MenuItem>
                <MenuItem value="Borrador">Borrador</MenuItem>
                <MenuItem value="Activo">Activos</MenuItem>
                <MenuItem value="Eliminado">Eliminados</MenuItem>
                <MenuItem value="Finalizado">Finalizados</MenuItem>
              </Select>
            </div>

            <Button
              variant="contained"
              sx={{backgroundColor: '#FFC300', color: '#000'}}
              onClick={() => history.push("/crearEvento/creacion")}
            >
              <i className="material-symbols-outlined">add</i>
              {t("event.addEvent")}
            </Button>
          </div>

          <TableContainer>
            <Table aria-label="simple table">
              <TableHead sx={{ backgroundColor: "#EDEDED" }}>
                <TableRow>
                  <TableCell>Evento</TableCell>
                  <TableCell align="center">Rol</TableCell>
                  <TableCell align="center">Ventas</TableCell>
                  <TableCell align="center">Estado</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Spinner color="primary" />
                    </TableCell>
                  </TableRow>
                ) : (
                  eventosFiltrados?.length > 0 ? (
                    eventosFiltrados.map((evento) => (
                      <TableRow
                        hover
                        key={evento.id}
                        style={{
                          cursor: (evento.estado.id === 1 || evento.estado.id === 2)
                          ? 'pointer'
                          : 'inherit'
                        }}
                        onClick={evento.estado.id === 1 
                          ? () => handleOpen(evento.id) 
                          : (evento.estado.id === 3 || evento.estado.id === 4) 
                            ? null
                            : () => handleRowClick(evento)
                        }
                      >
                        <TableCell scope="row" className="ps-0 pb-0">
                          <div className="d-flex gap-3">
                            <div
                              className="d-flex flex-column align-items-center justify-content-center lh-1"
                              style={{width: '70px', height: '70px', backgroundColor: '#EDEDED'}}
                            >
                              <span className="fs-2 fw-medium m-0 p-0">{moment(evento.fecha).clone().local().format("D")}</span>
                              <span className=" text-uppercase m-0 p-0">{moment(evento.fecha).clone().local().format("MMM")}</span>
                            </div>

                            <div className="align-self-center lh-2">
                              <p className="fw-semibold m-0">
                                {evento.evento}
                              </p>
                              <small className="text-secondary d-block">
                                {evento.direccion} - {moment(evento.hora).clone().local().format("HH:mm [hs.]")}
                              </small>
                              {evento.privado
                                ?
                                <span style={{color: '#aeaeae'}}>
                                  <LockIcon style={{fontSize: '.8rem'}} />
                                  <small>Privado</small>
                                </span>
                                :
                                <span style={{color: '#aeaeae'}}>
                                  <LockOpenIcon style={{fontSize: '.8rem'}} />
                                  <small>Público</small>
                                </span>
                              }
                            </div>
                          </div>
                        </TableCell>

                        <TableCell align="center" width={100}  className="pb-0">
                          <span
                            className="badge rounded-pill text-dark align-content-center"
                            style={{
                              backgroundColor: "#EDEDED",
                              width: "100px",
                              height: "30px"
                            }}
                          >
                            {evento.rol.replace('_', ' ')}
                          </span>
                        </TableCell>

                        <TableCell align="center" width={60} className="pb-0">
                          {evento.ventas}
                        </TableCell>

                        <TableCell align="center" minWidth={60} className="px-0 pb-0">
                          <span
                            className="circulo-estado"
                            style={{
                              backgroundColor: colorCirculo(evento.estado.estado),
                              boxShadow: `0 0 5px ${colorCirculo(evento.estado.estado)}`
                            }}
                          />
                          {evento.estado?.estado}
                        </TableCell>

                        <TableCell align="center" width={30} className="pb-0" onClick={(e) => e.stopPropagation()}>
                          <IconButton aria-label="Example">
                            <MoreVertIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5}>
                        <Alert severity="info" color="secondary">
                          {filtroEventos !== "Todo"
                            ? `Aún no tienes eventos ${filtroEventos.toLowerCase()}s.`
                            : "Aún no tienes eventos."
                          }
                        </Alert>
                      </TableCell>
                    </TableRow>
                  )
                )}
                <MensajeFinalizarCreacionEvento open={open} handleClose={handleClose} eventoId={eventoId}/>
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      )}
    </>
  );
};
export default withAuthenticationRequired(Evento, {
  onRedirecting: () => <Loading />,
});