import React, { useState, useEffect } from "react";
import { Alert } from "reactstrap";
import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { api } from "../services/api";
import "./Profile.css";
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from "react-responsive";
import Compras from "../components/perfil/perfil_compras";
import Eventos from "../components/perfil/perfil_eventos";
import PerfilFacturacion from "../components/perfil/perfil_facturacion";
import Perfil from "../components/perfil/perfil_informacion";
import Invitaciones from "../components/perfil/perfil_invitaciones";
import { useLocation } from 'react-router-dom';


const ProfileComponent = () => {
  const { user } = useAuth0();
  const [participaciones, setParticipaciones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [invitaciones, setInvitaciones] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");
  const [usuario, setUsuario] = useState();
  const [botonPerfil, setBotonPerfil] = useState(1);
  const { i18n } = useTranslation();
  const [t] = useTranslation("global");
  const [formData, setFormData] = useState({
    nombre: "",
    apellido: "",
    telefono: "",
    direccion: "",
    ciudad: "",
    provincia: "",
    cp: "",
    pais: "",
  });
  const [paises, setPaises] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [paisSeleccionado, setPaisSeleccionado] = useState("");
  const [provSeleccionada, setProvSeleccionada] = useState("");
  const isMobile = useMediaQuery({ query: '(max-width: 770px)' });
  const location = useLocation();
  const { view } = location.state || {};

  useEffect(() => {
    if (view) {
      //CODIGO
      if (isMobile) {
        switch (view) {
          case 'facturacion':
            setBotonPerfil(5);
            break;
          case 'invitaciones': // Eventos e invitaciones
            setBotonPerfil(3);
            break;
          case 'compras':
            setBotonPerfil(4);
            break;
          default:
            setBotonPerfil(1);
        }
      } else {
        switch (view) {
          case 'facturacion':
            setBotonPerfil(5);
            break;
          case 'eventos':
            setBotonPerfil(3);
            break;
          case 'invitaciones':
            setBotonPerfil(2);
            break;
          case 'compras':
            setBotonPerfil(4);
            break;
          default:
            setBotonPerfil(1);
        }
      }
    }
  }, [view]);

  const callApi = async () => {
    try {
      const response = await api.usuarios.getUsuario();
      if (response.status === "success") {
        setUsuario(response.data.usuario);
        const invitacionesCliente = response.data.invitaciones.invitacionesCliente?.map(invitacion => ({
          ...invitacion,
          tipo: 'Cliente'
        })) ?? [];
        const invitacionesColaborador = response.data.invitaciones.invitacionesColaborador?.map(invitacion => ({
          ...invitacion,
          tipo: 'Colaborador'
        })) ?? [];

        const todasInvitaciones = [...invitacionesCliente, ...invitacionesColaborador];

        setParticipaciones(response.data.usuario.participaciones);
        setInvitaciones(todasInvitaciones);
      }
    } catch (error) {
      setError(error.message || "Error al cargar el usuario");
    }
    setLoading(false);
  };

  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    if (usuario) {
      setFormData({
        nombre: usuario.nombre,
        apellido: usuario.apellido,
        telefono: usuario.telefono,
        direccion: usuario.direccion,
        ciudad: usuario.ciudad,
        provincia: usuario.provincia,
        cp: usuario.cp,
        pais: usuario.pais,
      });
    }
  }, [usuario]);

  /* ---------- PAIS, PROV, CIUDAD  ----------*/

  useEffect(() => {
    const fetchPaises = async () => {
      const API_KEY = "YWtuaGxqZURDcUtNWW9NN3hPNEhJUXl4c0tDbUNubDB5NVZNVkdqdg==";
      const url = "https://api.countrystatecity.in/v1/countries";

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'X-CSCAPI-KEY': API_KEY
          }
        });

        if (response.ok) {
          const data = await response.json();
          setPaises(data);
        }

      } catch (error) {
        console.error('Error al obtener los países:', error);
      }
    };
    fetchPaises();
  }, []);

  useEffect(() => {
    if (paisSeleccionado) {
      const fetchProvincias = async () => {
        const API_KEY = "YWtuaGxqZURDcUtNWW9NN3hPNEhJUXl4c0tDbUNubDB5NVZNVkdqdg==";
        const url = `https://api.countrystatecity.in/v1/countries/${paisSeleccionado}/states`;
        try {
          const response = await fetch(url, { headers: { 'X-CSCAPI-KEY': API_KEY } });
          const result = await response.json();
          setProvincias(result);
        } catch (error) {
          console.error('Error al obtener provincias:', error);
        }
      };
      fetchProvincias();
    }
  }, [paisSeleccionado]);

  useEffect(() => {
    if (provSeleccionada && paisSeleccionado) {
      const fetchCiudades = async () => {
        const API_KEY = "YWtuaGxqZURDcUtNWW9NN3hPNEhJUXl4c0tDbUNubDB5NVZNVkdqdg==";
        const url = `https://api.countrystatecity.in/v1/countries/${paisSeleccionado}/states/${provSeleccionada}/cities`;
        try {
          const response = await fetch(url, { headers: { 'X-CSCAPI-KEY': API_KEY } });
          const result = await response.json();
          setCiudades(result);
        } catch (error) {
          console.error('Error al obtener ciudades:', error);
        }
      };
      fetchCiudades();
    }
  }, [provSeleccionada, paisSeleccionado]);

  const updateMyEvents = async () => {
    try {
      await callApi();
    } catch (error) {
      setError(error.message || "Error al actualizar los eventos");
    }
  };

  const handleAccept = async (id, tipo) => {
    const data = {
      id: id,
      idestado: 2
    };

    try {
      let response;
      if (tipo === 'Cliente') {
        response = await api.invitaciones.setInvitacionCliente(id, data);
      } else if (tipo === 'Colaborador') {
        response = await api.invitaciones.setInvitacionColaborador(id, data);
      }

      if (response.status === "success") {
        setResponseMessage(t("invitations.invitation_accepted"));
        await updateMyEvents();
      } else {
        setResponseMessage(t("invitations.error_request"));
      }
    } catch (err) {
      console.log(`Error en la respuesta a la solicitud: ${err}`);
      setResponseMessage(t("invitations.error_request"));
    }
  };

  const handleReject = async (id, tipo) => {
    const confirmReject = window.confirm(t("invitations.alert_reject"));

    if (confirmReject) {
      const data = {
        id: id,
        idestado: 3
      };

      try {
        let response;
        if (tipo === 'Cliente') {
          response = await api.invitaciones.setInvitacionCliente(id, data);
        } else if (tipo === 'Colaborador') {
          response = await api.invitaciones.setInvitacionColaborador(id, data);
        }

        if (response.status === "success") {
          setResponseMessage(t("invitations.invitation_declined"));
          await updateMyEvents();
        } else {
          setResponseMessage(t("invitations.error_request"));
        }
      } catch (err) {
        console.log(`${t("invitations.error_request")} ${err}`);
        setResponseMessage(t("invitations.error_request"));
      }
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Alert color="danger">
        {error}
      </Alert>
    );
  }

  return (
    <div className="container-fluid">
      <div className="row align-items-start">
        {isMobile ? (
          <>
            {/* <div className="row align-items-center justify-content-center mb-3 mt-1"> */}
            <div className="d-flex flex-column align-items-center w-100 max-w-md mx-auto bg-white">
              {/* <div className="col-4 align-items-center justify-content-center"> */}
              <div className="pt-4 pb-2 ">
                <img
                  /* className="rounded-circle img-fluid profile-picture mb-3 mt-3" */
                  className="rounded-circle object-cover"
                  style={{ width: "120px", height: "120px" }}
                  alt={user.name + " avatar"}
                  src={user.picture}
                />
              </div>
              <div className="col-8 align-items-center justify-content-center">
                <h2 className="text-center  text-capitalize mt-3 mb-4 fs-2 font-bold">
                  {usuario && (usuario.nombre && usuario.apellido)
                    ? `${usuario.nombre} ${usuario.apellido}`
                    : `${user?.email}`}
                </h2>
              </div>
            </div>
            <div className="d-flex w-100 justify-content-between overflow-x-auto scroll-contenedor-menu mb-2">
              <button
                type="button"
                className={`flex-1 py-2 px-4 text-center ${botonPerfil === 1 ? 'btn-activo-mobile' : ''}`}
                style={{ borderBottom: botonPerfil === 1 ? '2px solid black' : 'none' }}
                onClick={() => setBotonPerfil(1)}
              >
                {t("profile.profile")}
              </button>
              <button
                type="button"
                className={`position-relative flex-1 py-2 px-4 text-center ${botonPerfil === 3 ? 'btn-activo-mobile' : ''}`}
                style={{ borderBottom: botonPerfil === 3 ? '2px solid black' : 'none' }}
                onClick={() => setBotonPerfil(3)}
              >
                {t("profile.events")}
                {invitaciones?.length > 0 && (
                  <span className="position-absolute p-1 bg-danger border border-light rounded-circle"
                    style={{ fontSize: '0.5rem' }} />
                )}

              </button>
              <button
                type="button"
                className={`flex-1 py-2 px-4 text-center ${botonPerfil === 4 ? 'btn-activo-mobile' : ''}`}
                style={{ borderBottom: botonPerfil === 4 ? '2px solid black' : 'none' }}
                onClick={() => setBotonPerfil(4)}
              >
                {t("profile.shopping")}
              </button>
              <button
                type="button"
                className={`flex-1 py-2 px-4 text-center ${botonPerfil === 5 ? 'btn-activo-mobile' : ''}`}
                style={{ borderBottom: botonPerfil === 5 ? '2px solid black' : 'none' }}
                onClick={() => setBotonPerfil(5)}
              >
                {t("profile.billing")}
              </button>
            </div>

            <div className="col-12">
              {botonPerfil === 1 && (
                <Perfil
                  usuario={usuario}
                  paises={paises}
                  provincias={provincias}
                  ciudades={ciudades}
                  setPaisSeleccionado={setPaisSeleccionado}
                  setProvSeleccionada={setProvSeleccionada} />
              )}
              {botonPerfil === 2 && (
                <Invitaciones
                  invitaciones={invitaciones}
                  handleAccept={handleAccept}
                  handleReject={handleReject}
                  responseMessage={responseMessage}
                />)}
              {botonPerfil === 3 && (<Eventos participaciones={participaciones} invitaciones={invitaciones}
                handleAccept={handleAccept}
                handleReject={handleReject}
                responseMessage={responseMessage} />)}
              {botonPerfil === 4 && (<Compras />)}
              {botonPerfil === 5 && (<PerfilFacturacion usuario={usuario} />)}
            </div>
          </>
        ) : (
          <>
            <div className="col-3">
              <div className="container">
                <div className="row">
                  <div className="col-3">
                    <img
                      className="rounded-circle img-fluid profile-picture mt-3 mb-3"
                      alt={user.name + " avatar"}
                      src={user.picture}
                    />
                  </div>
                  <div className="col-9">
                    <h2 className="text-start fs-5 text-capitalize mt-3 mb-3 romper-texto nombre-usuario">
                      {usuario && (usuario.nombre && usuario.apellido)
                        ? `${usuario.nombre} ${usuario.apellido}`
                        : `${user?.email}`}
                    </h2>
                  </div>

                  <button
                    type="button"
                    className={` mt-2 mb-2 btn d-flex align-items-center text-start ${botonPerfil === 1 ? "btn-activo-profile" : "btn-transparente"}`}
                    onClick={() => setBotonPerfil(1)}
                  >
                    <i className="material-symbols-outlined mr-2">person</i>
                    {t("profile.profile")}
                  </button>

                  <button
                    type="button"
                    className={` mt-2 mb-2 btn d-flex align-items-center text-start ${botonPerfil === 2 ? "btn-activo-profile" : "btn-transparente"}`}
                    onClick={() => setBotonPerfil(2)}
                  >
                    <i className="material-symbols-outlined mr-2">mail</i>
                    {t("profile.invitations")}
                  </button>

                  <button
                    type="button"
                    className={` mt-2 mb-2 btn d-flex align-items-center text-start ${botonPerfil === 3 ? "btn-activo-profile" : "btn-transparente"}`}
                    onClick={() => setBotonPerfil(3)}
                  >
                    <i className="material-symbols-outlined mr-2">event</i>
                    {t("profile.events")}
                  </button>

                  <button
                    type="button"
                    className={` mt-2 mb-2 btn d-flex align-items-center text-start ${botonPerfil === 4 ? "btn-activo-profile" : "btn-transparente"}`}
                    onClick={() => setBotonPerfil(4)}
                  >
                    <i className="material-symbols-outlined mr-2">shopping_cart</i>
                    {t("profile.shopping")}
                  </button>

                  <button
                    type="button"
                    className={` mt-2 mb-2 btn d-flex align-items-center text-start ${botonPerfil === 5 ? "btn-activo-profile" : "btn-transparente"}`}
                    onClick={() => setBotonPerfil(5)}
                  >
                    <i className="material-symbols-outlined mr-2">receipt_long</i>
                    {t("profile.billing")}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-9">
              {botonPerfil === 1 && (<Perfil
                usuario={usuario}
                paises={paises}
                provincias={provincias}
                ciudades={ciudades}
                setPaisSeleccionado={setPaisSeleccionado}
                setProvSeleccionada={setProvSeleccionada} />)}
              {botonPerfil === 2 && (<Invitaciones
                invitaciones={invitaciones}
                handleAccept={handleAccept}
                handleReject={handleReject}
                responseMessage={responseMessage} />)}
              {botonPerfil === 3 && (<Eventos participaciones={participaciones}
                invitaciones={invitaciones}
                handleAccept={handleAccept}
                handleReject={handleReject}
                responseMessage={responseMessage} />)}
              {botonPerfil === 4 && (<Compras />)}
              {botonPerfil === 5 && (<PerfilFacturacion usuario={usuario} />)}
            </div>
          </>
        )}
      </div>
    </div>

  );
};


export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});