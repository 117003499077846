import { useState, useEffect } from 'react';
import { api } from '../services/api';
import { useAuth0 } from "@auth0/auth0-react";
import {socket} from '../components/Socket/cliente'
import {toast} from 'react-toastify'
import '../components/Socket/toast.css'

const useNotificaciones = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [notificaciones, setNotificaciones] = useState([]);
  const [pendingNotificaciones, setPendingNotificaciones] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotificaciones = async () => {
      try {
        if (isAuthenticated) {
          const response = await api.notificaciones.getNotificaciones();

          if (response.status === 'success') {
            const notificacionesArray = response.data.notificaciones || []; // Aseguramos que sea un array
            setNotificaciones(notificacionesArray);

            const tienePendientes = notificacionesArray.some(notificacion => !notificacion.atendido);
            setPendingNotificaciones(tienePendientes);
          } else {
            setError('Error al obtener las notificaciones');
          }
        }
      } catch (error) {
        setError('Error en la red.');
      }
    };

    if (isAuthenticated) {
      fetchNotificaciones();

        if (socket.connected) {
        socket.on('nueva-notificacion', (notification) => {
          console.log('Notificación recibida:', notification);
          setNotificaciones(prev => [...prev, notification]);
          setPendingNotificaciones(prev => prev || !notification.atendido);

          toast.success(`${notification.titulo} ${notification.descripcion}`, {
          position: 'top-right',
          autoClose: 4000, 
      });
        });
      }

      return () => {
        socket.off('nueva-notificacion');
      };
    }
  }, [isAuthenticated]);

  return { notificaciones, pendingNotificaciones, error };
};

export default useNotificaciones;
