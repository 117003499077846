import { useState, useEffect, useContext } from "react";
import { api } from "../../services/api";
import Loading from "../../components/Loading";
import { Button, Input } from "reactstrap";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint, faSearch, faXmark, faAdd } from "@fortawesome/free-solid-svg-icons";
import { PaginatorContext } from "../../providers/Paginator";
import VolverButton from "../../components/VolverButton";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Table, TablePagination, TableBody, Paper, TableContainer, TableHead, TableRow, TableCell, CircularProgress, Alert } from "@mui/material";

const Tickets = () => {
  const history = useHistory();
  const param = useParams()
  const [t] = useTranslation("global");
  const [loading, setLoading] = useState();

  const {
    query,
    setQuery,
    totalLength,
    setTotalLength,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = useContext(PaginatorContext);

  const [data, setData] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const [showSearch, setShowSearch] = useState();

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const response = await api.participaciones.getParticipaciones();

        if (response.status === "success") {
          const rol = response.data.participaciones.find((p) => p.idevento === param.id)?.idrol;
          setUserRole(rol);
        }
        else {
          throw new Error("Error al obtener el rol.");
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-center",
          autoClose: 4000,
        });
      }
    };

    fetchRole();
  }, [param.id])

  useEffect(() => {
    const callApi = async (id) => {
      setLoading(true);

      try {
        const response = await api.eventos.getTickets(
          id,
          query,
          pageSize,
          currentPage
        );

        if (response.status === "success") {
          const res = response.data.tikets;
          setData(res);
          setTotalLength(response.data.totalDataSize);
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-center",
          autoClose: 4000,
        });
      } finally {
        setLoading(false);
      }
    };

    callApi(param.id);
  }, [param.id, query, pageSize, currentPage, setTotalLength]);

  // Manejar el cambio de página
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Manejar el cambio de tamaño de página
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reiniciar la página al cambiar el tamaño
  };

  const handleToggleSearch = () => {
    setShowSearch(!showSearch); // Cambia el estado de visibilidad del campo de búsqueda
    setQuery(""); // Limpia el campo de búsqueda al mostrarlo
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between">
        <Button color="success"
          className="d-flex align-items-center gap-2"
          onClick={() => history.push("/eventos/" + param.id + "/nuevoTicket")}
        >
          <FontAwesomeIcon icon={faAdd} className="fs-6" />
          {t("tickets.newTicket")}
        </Button>
        
        <form onSubmit={(e) => e.preventDefault()} className="d-flex justify-content-end">
          {showSearch ? (
            <div className="w-50 d-flex gap-2">
              <Input
                id="exampleSearch"
                name="q"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Buscar.."
                type="search"
              />
              <Button onClick={() => setShowSearch(false)} color="light">
                <FontAwesomeIcon icon={faXmark} size="lg" style={{ color: "#3a3b3c" }} />
              </Button>
            </div>
          ) : (
            <Button color="secondary" onClick={handleToggleSearch}>
              <FontAwesomeIcon icon={faSearch} className="fs-6" />
            </Button>
          )}
        </form>
      </div>
      
      <TableContainer component={Paper} className="mt-3 table-responsive">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>{t("tickets.code")}</TableCell>
              <TableCell>Ticket</TableCell>
              <TableCell>{t("tickets.name")}</TableCell>
              <TableCell>{t("tickets.lastName")}</TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              data && data.length > 0 ? (
                data.map((ticket) => (
                  <TableRow key={ticket.id}>
                    <TableCell component="th" scope="row">
                      <div className="d-flex align-items-center gap-1">
                        <Button color="primary" size="sm" onClick={() => history.push(`/eventos/${param.id}/tickets/${ticket.id}`)}>
                          <FontAwesomeIcon icon={faPrint} className="fs-6" />
                        </Button>
                        {ticket.id}
                      </div>
                    </TableCell>
                    
                    <TableCell>{ticket.codigo}</TableCell>
    
                    <TableCell>{ticket.tarifa.concepto}</TableCell>

                    <TableCell>{ticket.nombre}</TableCell>

                    <TableCell>{ticket.apellido}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Alert severity="info" >
                      {t("tickets.ticketsAlert")}
                    </Alert>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          className="d-flex justify-content-center"
          rowsPerPageOptions={[10, 25, 50]} // Opciones de tamaño de página
          count={totalLength} // Total de registros
          rowsPerPage={pageSize} // Tamaño de la página actual
          page={currentPage} // Página actual
          onPageChange={handleChangePage} // Evento de cambio de página
          onRowsPerPageChange={handleChangeRowsPerPage} // Evento de cambio de tamaño de página
          labelRowsPerPage="Registros p/página"
          showFirstButton
          showLastButton
        />
      </TableContainer>


      <div className="d-flex justify-content-end mt-3">
        <VolverButton />
      </div>
    </div>
  )
};

export default withAuthenticationRequired(Tickets, {
  onRedirecting: () => <Loading />,
});