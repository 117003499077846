import { Table, Alert } from 'reactstrap';
import "../../views/Profile.css";
import { useMediaQuery } from "react-responsive";
import Invitaciones from './perfil_invitaciones';
import React, { useState } from "react";
import { useTranslation } from 'react-i18next';

const Eventos = ({ participaciones, invitaciones, handleAccept, handleReject, responseMessage }) => {
  const isMobile = useMediaQuery({ query: '(max-width: 770px)' });
  const { i18n } = useTranslation();
  const [t] = useTranslation("global");

  return (
    <div className="container">
      {isMobile ? (
        <>
          <Invitaciones
            invitaciones={invitaciones}
            handleAccept={handleAccept}
            handleReject={handleReject}
            responseMessage={responseMessage}
          />

          <div className="mt-5">
            <h3 className="text-lg font-semibold ml-2 mb-4">{t("event.tittle")}</h3>
            {participaciones?.length > 0 ? (
              <div className="pb-4 w-100 h-100 d-flex flex-column justify-content-between">
                {participaciones.map(participacion => {
                  let statusEvento = participacion.evento.estado.estado;
                  let tablaEvento = null;

                  switch (statusEvento) {
                    case "Inactivo":
                      tablaEvento = "table-secondary";
                      statusEvento = t("event.inactive");
                      break;
                    case "Activo":
                      tablaEvento = "table-success";
                      statusEvento = t("event.active");
                      break;
                    case "Eliminado":
                      tablaEvento = "table-danger";
                      statusEvento = t("event.deleted");
                      break;
                    default:
                      tablaEvento = "table-warning";
                      statusEvento = t("event.finalized")
                      break;
                  }

                  let rolEvent = participacion.rol.rol;

                  switch (rolEvent) {
                    case "Owner":
                      rolEvent = t("event.owner");
                      break;
                    case "Colaborador":
                      rolEvent = t("event.collaborator");
                      break;
                    case "Cliente":
                      rolEvent = t("event.client");
                      break;
                    case "Colaborador_acceso":
                      rolEvent = t("event.access_collaborator");
                      break;
                    case "Colaborador_voucher":
                        rolEvent = t("event.voucher_collaborator");
                        break;
                    default: // Guest
                      rolEvent = t("event.guest")
                      break;
                  }

                  return (
                    <div key={participacion.id} className="container d-flex align-items-center justify-content-between pb-3 px-3 border-bottom border-top">
                      <div className="flex-grow">
                        <h4 className="font-semibold mt-1">{participacion.evento.evento}</h4>
                        <p className='m-0 fst-italic'>{rolEvent}</p>
                        <p className="text-sm text-gray-600 m-0">
                          {new Date(participacion.evento.fecha).toLocaleDateString()}
                        </p>
                      </div>
                      <div className="ml-4">
                        <span className={`px-2 py-1 rounded-full text-xs font-medium ${tablaEvento}`}>
                          {statusEvento}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <Alert color="secondary">{t("event.no_events_logged")}.</Alert>
            )}
          </div>
        </>
      ) : (
        <>
          <h3 className="mt-2 ml-3"><strong>{t("event.tittle")}</strong></h3>
          {participaciones?.length > 0 ? (
            <Table className="mb-0 table">
              <thead>
                <tr>
                  <th className="align-middle">{t("event.event")}</th>
                  <th className="align-middle">{t("event.date")}</th>
                  <th className="align-middle">{t("event.role")}</th>
                  <th className="align-middle">{t("event.status")}</th>
                </tr>
              </thead>
              <tbody>
                {participaciones.map(participacion => {
                  let statusEvento = participacion.evento.estado.estado;
                  let tablaEvento = null;

                  switch (statusEvento) {
                    case "Inactivo":
                      tablaEvento = "table-secondary";
                      statusEvento = t("event.inactive");
                      break;
                    case "Activo":
                      tablaEvento = "table-success";
                      statusEvento = t("event.active");
                      break;
                    case "Eliminado":
                      tablaEvento = "table-danger";
                      statusEvento = t("event.deleted");
                      break;
                    default:
                      tablaEvento = "table-warning";
                      statusEvento = t("event.finalized")
                      break;
                  }

                  let rolEvent = participacion.rol.rol;

                  switch (rolEvent) {
                    case "Owner":
                      rolEvent = t("event.owner");
                      break;
                    case "Colaborador":
                      rolEvent = t("event.collaborator");
                      break;
                    case "Cliente":
                      rolEvent = t("event.client");
                      break;
                    case "Colaborador_acceso":
                      rolEvent = t("event.access_collaborator");
                      break;
                    case "Colaborador_voucher":
                        rolEvent = t("event.voucher_collaborator");
                        break;
                    default: // Guest
                      rolEvent = t("event.guest")
                      break;
                  }


                  return (
                    <tr key={participacion.id}>
                      <td className="p-2 align-middle">{participacion.evento.evento}</td>
                      <td className="p-2 align-middle">{new Date(participacion.evento.fecha).toLocaleDateString()}</td>
                      <td className="p-2 align-middle">{rolEvent}</td>
                      <td className="p-2 align-middle">
                        <span className={tablaEvento}>{statusEvento}</span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <Alert color="secondary">{t("event.no_events_logged")}.</Alert>
          )}
        </>
      )}
    </div>
  );
};

export default Eventos;
