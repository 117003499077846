import React, { useContext, useEffect, useState } from 'react';
import { Table, Button, Alert } from 'reactstrap';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { api } from '../../services/api';
import { toast } from 'react-toastify';
import { PaginatorContext } from '../../providers/Paginator';
import Loading from '../Loading';
import { CircularProgress, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Compras = (/* { compras } */) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {
    query,
    setQuery,
    totalLength,
    setTotalLength,
    currentPage,
    setCurrentPage,
    pageSize,
    setPageSize,
  } = useContext(PaginatorContext);
  const [compras, setCompras] = useState([]);
  const { i18n } = useTranslation();
  const [t] = useTranslation("global");

  //Dirigir al usuario a la vista de detalles sobre su compra.
  const masDetalles = (compra) => {
    history.push(`/detalles/${compra.id}`);
  }

  useEffect(() => {
    const callApi = async () => {
      setLoading(true);
      try {
        const response = await api.compras.getAllCompras(
          query,
          pageSize,
          currentPage,
        );
  
        if (response.status === 'success') {
          setCompras(response.data.compras);
          setTotalLength(response.data.totalDataSize);
        }
        else if (response.status === 'in progress') {
          throw new Error(response.message || "La compra sigue pendiente de pago.");
        }
        else {
          throw new Error("Error al cargar las compras.");
        }
      } catch (error) {
        toast.error(error.message, {
          position: "top-center",
          autoClose: 4000,
        });
      } finally {
        setLoading(false);
      }
    };

    callApi();
  }, [query, pageSize, currentPage, setTotalLength]);

  // Manejar el cambio de página
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Manejar el cambio de tamaño de página
  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0); // Reiniciar la página al cambiar el tamaño
  };

  if (loading) return (
    <div className="d-flex justify-content-center align-items-center" style={{height: "80vh"}}>
      <CircularProgress />
    </div>
  );

  return (
    <div className="container">
      <h3 className="mt-2 ml-3"><strong>{t("purchasehistory.purchasehistory")}</strong></h3>
      {compras?.length > 0 ? (
        <Table className="mb-0 table">
          <thead>
            <tr>
              <th className="align-middle">{t("purchasehistory.event")}</th>
              <th className="align-middle">{t("purchasehistory.datepurchase")}</th>
              <th className="align-middle">{t("purchasehistory.status")}</th>
              <th className="align-middle">{t("purchasehistory.details")}</th>
            </tr>
          </thead>
          <tbody>
            {compras.map(compra => {
              if (compra.idcarrito !== 0) {
                const fechaCompra = new Date(compra.fecha);
                const estadoCompra = compra.atendido === null ? "Pendiente" : "Completado";
                let traduccionCompra = estadoCompra === "Pendiente" ? t("purchasehistory.pending") : t("purchasehistory.completed");

                return (
                  <tr key={compra.id}>
                    <td className="p-2 align-middle">{compra.carrito.evento?.evento}</td>
                    <td className="p-2 align-middle">{fechaCompra.toLocaleDateString()}</td>
                    <td className="p-2 align-middle">{traduccionCompra}</td>
                    <td className="p-2 align-middle">
                      <Button onClick={() => masDetalles(compra)} color="primary">{t("purchasehistory.seedetails")}</Button>
                    </td>
                  </tr>
                );
              }
              return null;
            })}
            <tr>
              <td colSpan={4}>
                <TablePagination
                  component="div"
                  className="d-flex justify-content-center"
                  rowsPerPageOptions={[10, 25, 50]} // Opciones de tamaño de página
                  count={totalLength} // Total de registros
                  rowsPerPage={pageSize} // Tamaño de la página actual
                  page={currentPage} // Página actual
                  onPageChange={handleChangePage} // Evento de cambio de página
                  onRowsPerPageChange={handleChangeRowsPerPage} // Evento de cambio de tamaño de página
                  labelRowsPerPage="Registros p/página"
                  showFirstButton
                  showLastButton
                />
              </td>
            </tr>
          </tbody>
        </Table>
      ) : (
        <Alert color="secondary">{t("purchasehistory.nopurchases")}</Alert>
      )}
    </div>
  );
};

export default Compras;