import React, { useState, useEffect } from 'react';
import { api } from '../../services/api';
import { useHistory } from 'react-router-dom';
import Loading from '../Loading';
import './eliminarcuenta.css';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';


const EliminarCuenta = () => {
    const [email, setEmail] = useState('');
    const [usuarioEmail, setUsuarioEmail] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(true);
    const [isProcessing, setIsProcessing] = useState(false);
    const [eventosActivos, setEventosActivos] = useState(false);
    const history = useHistory();
    const { logout } = useAuth0();
    const { i18n } = useTranslation();
    const [t] = useTranslation("global");

    useEffect(() => {
        const verificarEventosActivos = async () => {
            try {
                const response = await api.usuarios.getUsuario();
                const { usuario } = response.data;
                setUsuarioEmail(usuario.usuario);

                const eventosOwnerActivos = usuario.participaciones.filter(evento => {
                    const isOwner = evento.rol?.rol === 'Owner';
                    const eventoFecha = new Date(evento.evento?.fecha);
                    const ahora = new Date();
                    return isOwner && eventoFecha > ahora;
                });

                setEventosActivos(eventosOwnerActivos.length > 0);
            } catch (error) {
                console.error('Error al verificar eventos activos:', error);
            }
            setLoading(false);
        };

        verificarEventosActivos();
    }, []);

    const validarEmail = (email) => {
        return email.includes('@');
    };

    const handleEliminarCuenta = async () => {
        setError('');
        if (!email) {
            setError('Por favor, ingrese su correo electrónico.');
            return;
        }

        if (!validarEmail(email)) {
            setError('El correo electrónico debe contener un "@" válido.');
            return;
        }

        if (email !== usuarioEmail) {
            setError('El correo ingresado no corresponde al usuario activo.');
            return;
        }

        if (eventosActivos) {
            setError('No se puede eliminar su cuenta porque tiene eventos activos.');
            return;
        }

        setIsProcessing(true);
        try {
            const response = await api.usuarios.BajaUsuario(email);
            setSuccess('Su cuenta ha sido eliminada.');

            // Cerrar sesión
            sessionStorage.removeItem('token');
            localStorage.removeItem('token');
            console.log('Token eliminado, redirigiendo al home...');

            logout({ returnTo: window.location.origin });

            // Redirigir después de 1 segundo
            setTimeout(() => {
                setIsProcessing(false);
                history.push('/');
            }, 1000);
        } catch (err) {
            setIsProcessing(false);
            setError('Error al eliminar la cuenta: ' + (err.response?.data?.message || err.message));
        }
    };

    if (loading) {
        return <Loading />;
    }

    if (isProcessing) {
        return <Loading />;
    }

    return (
        <div className="container-baja-cuenta">
            <h2 className="titulo-baja-cuenta">{t("deleteaccount.accountdeactivationtitle")}</h2>
            {eventosActivos ? (
                <p className="mensaje-baja-cuenta">
                    {t("deleteaccount.deleteaccoutmessage1")} <span className="texto-baja">{t("deleteaccount.deleteaccoutmessage3")}</span> {t("deleteaccount.deleteaccoutmessage4")}.
                </p>
            ) : (
                <div>
                    <p className="instrucciones-baja-cuenta">{t("deleteaccount.accountdeletioninstructions")}</p>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder={t("deleteaccount.email")}
                        required
                        className="input-email-baja-cuenta"
                    />
                    <button type="button" className="dar-baja" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    {t("deleteaccount.deleteaccountbutton")}
                    </button>
                </div>
            )}
            <div className='error-baja'>
                {error && <p className="error">{error}</p>}
                {success && <p className="success">{success}</p>}
            </div>

            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">{t("deleteaccount.warning")}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                        {t("deleteaccount.titlewarning")}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-warning" data-bs-dismiss="modal">{t("deleteaccount.cancelbutton")}</button>
                            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={handleEliminarCuenta}>{t("deleteaccount.confirmbutton")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EliminarCuenta;
