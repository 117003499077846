import React, { useState, useEffect } from "react";
import { Table, Button, Alert, Input, Form, Tooltip } from 'reactstrap';
import { api } from "../../services/api";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from 'react-i18next';

const PerfilFacturacion = ({ usuario }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTool = () => setTooltipOpen(!tooltipOpen);
  const [t] = useTranslation("global");
  const [formData, setFormData] = useState({
    nombre: "",
    direccion: "",
    ciudad: "",
    provincia: "",
    cp: "",
    pais: "",
  });
  const [editProfile, setEditProfile] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const isMobile = useMediaQuery({ query: '(max-width: 770px)' });

  const [paises, setPaises] = useState([]);
  const [provincias, setProvincias] = useState([]);
  const [ciudades, setCiudades] = useState([]);
  const [paisSeleccionado, setPaisSeleccionado] = useState("");
  const [provSeleccionada, setProvSeleccionada] = useState("");
  const [ciudadSeleccionada, setCiudadSeleccionada] = useState("");


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  /* ---------- PAIS, PROV, CIUDAD  ----------*/
  const [loadingProvincias, setLoadingProvincias] = useState(false);
  const [loadingCiudades, setLoadingCiudades] = useState(false);

  useEffect(() => {
    const fetchPaises = async () => {
      const API_KEY = "YWtuaGxqZURDcUtNWW9NN3hPNEhJUXl4c0tDbUNubDB5NVZNVkdqdg==";
      const url = "https://api.countrystatecity.in/v1/countries";

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'X-CSCAPI-KEY': API_KEY
          }
        });

        if (!response.ok) {
          throw new Error('Error al obtener los países');
        }

        const data = await response.json();
        setPaises(data);
      } catch (error) {
        console.error('Error al obtener los países:', error);
      }
    };
    fetchPaises();
  }, []);

  const buscarNombrePais = (iso2) => {
    const paisEncontrado = paises.find(pais => pais.iso2 === iso2);
    return paisEncontrado ? paisEncontrado.name : iso2;
  };

  const buscarNombreProvincia = (iso2Provincia) => {
    const provinciaEncontrada = provincias.find(provincia => provincia.iso2 === iso2Provincia);
    return provinciaEncontrada ? provinciaEncontrada.name : iso2Provincia;
  };

  const buscarNombreCiudad = (ciudadId) => {
    const ciudadEncontrada = ciudades.find(ciudad => ciudad.id === ciudadId);
    return ciudadEncontrada ? ciudadEncontrada.name : ciudadId;
  };

  useEffect(() => {
    const fetchProvincias = async (paisIso2) => {
      setLoadingProvincias(true);
      const API_KEY = "YWtuaGxqZURDcUtNWW9NN3hPNEhJUXl4c0tDbUNubDB5NVZNVkdqdg==";
      const url = `https://api.countrystatecity.in/v1/countries/${paisIso2}/states`;

      try {
        const response = await fetch(url, { headers: { 'X-CSCAPI-KEY': API_KEY } });
        if (!response.ok) {
          throw new Error('No se pudieron obtener las provincias');
        }
        const result = await response.json();
        setProvincias(result);
      } catch (error) {
        console.error('Error al obtener provincias:', error);
      } finally {
        setLoadingProvincias(false);
      }
    };

    if (paisSeleccionado) {
      fetchProvincias(paisSeleccionado);
    } else {
      setProvincias([]);
    }
  }, [paisSeleccionado]);

  useEffect(() => {
    const fetchCiudades = async (paisIso2, provinciaIso2) => {
      setLoadingCiudades(true);
      const API_KEY = "YWtuaGxqZURDcUtNWW9NN3hPNEhJUXl4c0tDbUNubDB5NVZNVkdqdg==";
      const url = `https://api.countrystatecity.in/v1/countries/${paisIso2}/states/${provinciaIso2}/cities`;

      try {
        const response = await fetch(url, { headers: { 'X-CSCAPI-KEY': API_KEY } });
        if (!response.ok) {
          throw new Error('No se pudieron obtener las ciudades');
        }
        const result = await response.json();
        setCiudades(result);
      } catch (error) {
        console.error('Error al obtener ciudades:', error);
      } finally {
        setLoadingCiudades(false);
      }
    };

    if (provSeleccionada && paisSeleccionado) {
      fetchCiudades(paisSeleccionado, provSeleccionada);
    } else {
      setCiudades([]);
    }
  }, [provSeleccionada, paisSeleccionado]);

  const handleCountryChange = (event) => {
    const selectedPais = event.target.value;
    const nombrePais = buscarNombrePais(selectedPais);
    setPaisSeleccionado(selectedPais);
    setFormData(prevState => ({
      ...prevState,
      pais: nombrePais,
      provincia: "",
      ciudad: ""
    }));
    setProvSeleccionada("");
    setCiudadSeleccionada("");
  };

  const handleProvChange = (event) => {
    const selectedProv = event.target.value;
    const nombreProvincia = buscarNombreProvincia(selectedProv);
    setProvSeleccionada(selectedProv);
    setFormData(prevState => ({
      ...prevState,
      provincia: nombreProvincia,
      ciudad: ""
    }));
    setCiudadSeleccionada("");
  };

  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    const nombreCiudad = buscarNombreCiudad(selectedCityId);
    setCiudadSeleccionada(selectedCityId);
    setFormData(prevState => ({
      ...prevState,
      ciudad: nombreCiudad
    }));
  };
  /* ---------- FIN PAIS, PROV, CIUDAD  ----------*/

  const callApi = async () => {
    try {
      const response = await api.facturacion.getFacturacion();
      if (response.status === "success") {
        const data = response.data.facturacion;
        setFormData({
          nombre: data.nombre,
          direccion: data.direccion,
          ciudad: data.ciudad,
          provincia: data.provincia,
          cp: data.cp,
          pais: data.pais,
        });
      }
    } catch (error) {
      setError("Error al cargar los datos de la publicación.");
    }
  }

  useEffect(() => {
    callApi();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const regexE = /^(?!([a-zA-Z])\1+$)[a-zA-Z\sñÑ\u00C0-\u017F]*$/;

    if (formData.nombre?.trim() === "") {
      setError(<>{t("errors.emptyField")}</>);
      return;
    }

    if (!regexE.test(formData.nombre)) {
      setError(<>{t("errors.charactersEvent")}</>);
      return;
    }

    if (formData.nombre?.length > 50) {
      setError(<>{t("errors.eventName")}</>);
      return;
    }

    if (formData.apellido?.trim() === "") {
      setError(<>{t("errors.emptyField")}</>);
      return;
    }

    if (!regexE.test(formData.apellido)) {
      setError(<>{t("errors.charactersEvent")}</>);
      return;
    }

    if (formData.apellido?.length > 50) {
      setError(<>{t("errors.charactersDescription")}</>);
      return;
    }

    const data = {
      nombre: formData.nombre,
      direccion: formData.direccion,
      ciudad: formData.ciudad,
      provincia: formData.provincia,
      cp: formData.cp,
      pais: formData.pais,
    };

    try {
      const response = await api.facturacion.updateFacturacion(data);
      if (response.status === "error") {
        setError("Error: " + response.message);
      } else if (response.status === "success") {
        setError("");
        setEditProfile(false);
        setSubmitted(true);
        setTimeout(() => window.location.reload(), 1500);
      }
    } catch (error) {
      console.error("Error al editar usuario:", error);
      setError("Error al editar usuario.");
    }
  };


  return (
    <div className="container">
      {isMobile ? (
        <>
          <h3 className='d-flex align-items-center my-2'>
          {t("billing.tittle")}
            <div>
              {!editProfile && (
                <Button
                  className="py-0"
                  outline
                  color="grey"
                  style={{ border: "none" }}
                  onClick={() => setEditProfile(true)}
                >
                  <i className="material-symbols-outlined">border_color</i>
                </Button>
              )}
            </div>
          </h3>

          <div>
            {editProfile ? (
              <>
                <div className="">
                  <Form id="form-profile" onSubmit={handleSubmit}>
                    <div className='form-seccion my-3'>
                      <p className='mb-0'>{t("billing.fullname")}</p>
                      <Input type="text"
                        id="nombre"
                        name="nombre"
                        value={formData.nombre || ""}
                        onChange={handleInputChange}
                        /* style={{ width: "70%" }} */ />
                    </div>
                    <div className='form-seccion my-3'>
                      <p className='m-0'>{t("billing.country")}</p>
                      <Input id="pais"
                        type="select"
                        name="pais"
                        value={paises.find(p => p.name === formData.pais)?.iso2 || ""}
                        onChange={handleCountryChange}
                        className="profile-input"
                        style={{ width: "70%" }}
                        disabled={!paises.length}
                      >
                        <option value="" disabled>Selecciona un país</option>
                        {paises.map((pais) => (
                          <option key={pais.iso2} value={pais.iso2}>
                            {pais.name}
                          </option>
                        ))}
                      </Input>
                    </div>
                    <div className='form-seccion my-3'>
                      <p className='m-0'>{t("billing.province_state")}</p>
                      <Input
                        id="provincia"
                        name="provincia"
                        type="select"
                        value={provincias.find(p => p.name === formData.provincia)?.iso2 || ""}
                        onChange={handleProvChange}
                        className="profile-input"
                        style={{ width: "70%" }}
                        disabled={!formData.pais || !provincias.length}
                      >
                        <option value="" disabled>Selecciona una provincia</option>
                        {provincias.map((provincia) => (
                          <option key={provincia.iso2} value={provincia.iso2}>
                            {provincia.name}
                          </option>
                        ))}
                      </Input>
                    </div>
                    <div className='form-seccion my-3'>
                      <p className='m-0'>{t("billing.city")}</p>
                      <Input
                        id="ciudad"
                        name="ciudad"
                        type="select"
                        value={formData.ciudad || ""}
                        onChange={handleCityChange}
                        className="profile-input"
                        style={{ width: "70%" }}
                        disabled={!formData.provincia || !ciudades.length}
                      >
                        <option value="" disabled>Selecciona una ciudad</option>
                        {ciudades.map((ciudad) => (
                          <option key={ciudad.id} value={ciudad.name}>
                            {ciudad.name}
                          </option>
                        ))}
                      </Input>
                    </div>
                    <div className='form-seccion my-3'>
                      <p className='m-0'>{t("billing.address")}</p>
                      <Input type="text"
                        id="direccion"
                        name="direccion"
                        value={formData.direccion || ""}
                        onChange={handleInputChange}
                        /* style={{ width: "70%" }}  */ />
                    </div>
                    <div className='form-seccion my-3'>
                      <p className='m-0'>Codigo Postal</p>
                      <Input
                        type="text"
                        id="cp"
                        name="cp"
                        value={formData.cp || ""}
                        onChange={handleInputChange}
                        style={{ width: "30%" }}
                      />
                    </div>

                    {submitted && <Alert color="success">Usuario editado con éxito.</Alert>}

                    {editProfile && (
                      <div className="d-flex flex-row ml-auto justify-content-end mt-2 mb-4">
                        <Button
                          type="button"
                          color="primary"
                          outline
                          className="mr-2 d-inline-flex"
                          onClick={() => setEditProfile(false)}
                        >
                          {t("button.cancel")}
                        </Button>{" "}
                        <Button
                          type="submit"
                          color="primary"
                          form="form-profile"
                          style={{}}
                        >
                          {t("button.save")}
                        </Button>{" "}
                      </div>
                    )}
                  </Form>
                </div>
              </>
            ) : (
              <>
                <div className='d-flex justify-content-between my-3 pb-2 border-bottom '>
                  <p className='m-0' style={{ color: "#797D88" }}>{t("billing.fullname")}</p>
                  <p className='m-0 text-capitalize fw-bold'>{formData.nombre}</p>
                </div>
                <div className='d-flex justify-content-between my-3 pb-2 border-bottom '>
                  <p className='m-0' style={{ color: "#797D88" }}>{t("billing.country")}</p>
                  <p className='m-0 fw-bold'>{formData.pais}</p>
                </div>
                <div className='d-flex justify-content-between my-3 pb-2 border-bottom  '>
                  <p className='m-0' style={{ color: "#797D88" }}>Provincia / Estado</p>
                  <p className='m-0 fw-bold'>{formData.provincia}</p>
                </div>
                <div className='d-flex justify-content-between my-3 pb-2 border-bottom '>
                  <p className='m-0' style={{ color: "#797D88" }}>{t("billing.city")}</p>
                  <p className='m-0 fw-bold'>{formData.ciudad}</p>
                </div>
                <div className='d-flex justify-content-between my-3 pb-2 border-bottom '>
                  <p className='m-0' style={{ color: "#797D88" }}>{t("billing.zip_code")}</p>
                  <p className='m-0 fw-bold'>{formData.cp}</p>
                </div>
                <div className='d-flex justify-content-between my-3 pb-2 border-bottom  '>
                  <p className='m-0' style={{ color: "#797D88" }}>{t("billing.address")}</p>
                  <p className='m-0 fw-bold'>{formData.direccion}</p>
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <>
          <h3 className='d-flex align-items-center ml-4 my-2'>
          {t("billing.tittle")}
            <div>
              {!editProfile && (
                <Button
                  className="py-0"
                  outline
                  color="grey"
                  style={{ border: "none" }}
                  onClick={() => setEditProfile(true)}
                >
                  <i className="material-symbols-outlined">border_color</i>
                </Button>
              )}
            </div>
          </h3>

          {error && <Alert color="danger">{error}</Alert>}

          {editProfile ? (
            <>
              <Form id="form-profile" onSubmit={handleSubmit}>
                <div className="container mt-2 ml-4">
                  <div className="row g-2">
                    <div className="mb-3">
                      <small className="text-muted">{t("billing.fullname")}</small>
                      <Input type="text"
                        id="nombre"
                        name="nombre"
                        value={formData.nombre || ""}
                        onChange={handleInputChange}
                        style={{ width: "85%" }} />
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="mb-3">
                        <small className="text-muted">{t("billing.country")}</small>
                        <Input id="pais"
                          type="select"
                          name="pais"
                          value={paises.find(p => p.name === formData.pais)?.iso2 || ""}
                          onChange={handleCountryChange}
                          className="profile-input"
                          style={{ width: "70%" }}
                          disabled={!paises.length}
                        >
                          <option value="" disabled>Selecciona un país</option>
                          {paises.map((pais) => (
                            <option key={pais.iso2} value={pais.iso2}>
                              {pais.name}
                            </option>
                          ))}
                        </Input>
                      </div>
                      <div className="mb-3">
                        <small className="text-muted">{t("billing.city")}</small>
                        <Input
                          id="ciudad"
                          name="ciudad"
                          type="select"
                          value={formData.ciudad || ""}
                          onChange={handleCityChange}
                          className="profile-input"
                          style={{ width: "70%" }}
                          disabled={!formData.provincia || !ciudades.length}
                        >
                          <option value="" disabled>Selecciona una ciudad</option>
                          {ciudades.map((ciudad) => (
                            <option key={ciudad.id} value={ciudad.name}>
                              {ciudad.name}
                            </option>
                          ))}
                        </Input>
                      </div>
                    </div>
                    <div className="col-md-6 mb-2">
                      <div className="mb-3">
                        <small className="text-muted">{t("billing.province_state")}</small>
                        <Input
                          id="provincia"
                          name="provincia"
                          type="select"
                          value={provincias.find(p => p.name === formData.provincia)?.iso2 || ""}
                          onChange={handleProvChange}
                          className="profile-input"
                          style={{ width: "70%" }}
                          disabled={!formData.pais || !provincias.length}
                        >
                          <option value="" disabled>Selecciona una provincia</option>
                          {provincias.map((provincia) => (
                            <option key={provincia.iso2} value={provincia.iso2}>
                              {provincia.name}
                            </option>
                          ))}
                        </Input>
                      </div>
                      <div className="mb-3">
                        <small className="text-muted">{t("billing.zip_code")}</small>
                        <Input
                          type="text"
                          id="cp"
                          name="cp"
                          value={formData.cp || ""}
                          onChange={handleInputChange}
                          style={{ width: "30%" }}
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <small className="text-muted">{t("billing.address")}</small>
                      <Input type="text"
                        id="direccion"
                        name="direccion"
                        value={formData.direccion || ""}
                        onChange={handleInputChange}
                        style={{ width: "85%" }} />
                    </div>
                  </div>
                </div>
                {submitted && <Alert color="success">Usuario editado con éxito.</Alert>}

                {editProfile && (
                  <div className="d-flex flex-row mt-2 ml-4">
                    <Button
                      className='ml-2 mr-2 d-inline-flex'
                      type="submit"
                      color="success"
                      form="form-profile"
                      style={{}}
                    >
                      {t("button.edit")} perfil
                    </Button>{" "}
                    <Button
                      type="button"
                      color="danger"
                      outline
                      onClick={() => setEditProfile(false)}
                    >
                      {t("button.cancel")}
                    </Button>{" "}
                  </div>
                )}
              </Form>
            </>
          ) : (
            <>
              <div className="container mt-2 ml-4">
                <div className="row g-2">
                  <div className="mb-3">
                    <small className="text-muted">{t("billing.fullname")}</small>
                    <p className="fs-6 mb-0">{formData?.nombre}</p>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="mb-3">
                      <small className="text-muted">{t("billing.country")}</small>
                      <p className="mb-0">{formData?.pais}</p>
                    </div>
                    <div className="mb-3">
                      <small className="text-muted">{t("billing.city")}</small>
                      <p className="mb-0">{formData?.ciudad}</p>
                    </div>
                  </div>
                  <div className="col-md-6 mb-2">
                    <div className="mb-3">
                      <small className="text-muted">{t("billing.province_state")}</small>
                      <p className="mb-0"> {formData?.provincia}</p>
                    </div>
                    <div className="mb-3">
                      <small className="text-muted">{t("billing.zip_code")}</small>
                      <p className="mb-0">{formData?.cp}</p>
                    </div>
                  </div>
                  <div className="mb-3">
                    <small className="text-muted">{t("billing.address")}</small>
                    <p className="mb-0">{formData?.direccion}</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PerfilFacturacion;