import { Badge, Button, ButtonGroup, Carousel, CarouselItem, CarouselControl, Spinner } from "reactstrap";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api } from "../../services/api"
import { format } from "date-fns";
import { es } from 'date-fns/locale';
import moment from 'moment';
import ShareButton from "../../components/shareButton/shareButton";
import Loading from "../../components/Loading";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useCarrito from "../../hooks/hookCarrito";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from 'react-toastify'
import '../../components/Socket/toast.css'

//escapado de caracteres para el wysiwyg
const sanitizeHtml = (html) => {
    // crea un div temporal
    const div = document.createElement('div');
    // guarda el html en el div
    div.innerHTML = html;

    // filtra las etiquetas permitidas
    const allowedTags = ['ol', 'ul', 'li', 'p', 'strong', 'em', 'br']; // etiquetas permitidas
    Array.from(div.querySelectorAll('*')).forEach((node) => {
        if (!allowedTags.includes(node.nodeName.toLowerCase())) {
            node.parentNode.removeChild(node); // elimina los que no permite
        }
    });

    return div.innerHTML; // devuelve el html seguro
};

export const EventoPublic = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [data, setData] = useState();
    const param = useParams();
    const [cantidad, setCantidad] = useState({});
    const [mapUrl, setMapUrl] = useState('');
    const history = useHistory();
    const { crearCarrito, loadingCarrito } = useCarrito();


    useEffect(() => {
        const callApi = async () => {
            try {
                const response = await api.eventos.getPublic(param.id);

                if (response.status === "success") {
                    setData(response.data.evento);
                }

            } catch (error) {
                toast.error("Error en la red.", {
                    position: "top-center",
                    autoClose: 5000,
                });
            }
        };
        
        callApi();
    }, [param.id])

    const direccion = data?.direccion + " " + data?.ciudad;
    const apiKey = "AIzaSyCzPvPffhA4gns9-sfxzSlXXC4ytQtbKxc";

    useEffect(() => {
        if (direccion) {
            const direccionEncoded = encodeURIComponent(direccion);
            const newMapUrl = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${direccionEncoded}`;

            setMapUrl(newMapUrl);
        }
    }, [direccion, apiKey]);

    const incremento = (idtarifa) => {
        setCantidad((prevCantidades) => {
            const nuevaCantidad = (prevCantidades[idtarifa] || 0) + 1;
    
            // Verificar si la cantidad supera el límite permitido
            const limite = data?.tarifas?.find((tarifa) => tarifa.id === idtarifa)?.limite_persona;
    
            if (nuevaCantidad > parseInt(limite)) {
                return prevCantidades; // No actualizamos si se supera el límite
            }
    
            return {
                ...prevCantidades,
                [idtarifa]: nuevaCantidad,
            };
        });
    };

    const decremento = (idtarifa) => {
        setCantidad((prevCantidades) => ({
            ...prevCantidades,
            [idtarifa]: Math.max(0, (prevCantidades[idtarifa] || 0) - 1),
        }));
    };
    

    const handleAñadirCarrito = async () => {
        const idevento = parseInt(param.id);

        const items = Object.entries(cantidad).map(([idtarifa, cantidad]) => ({
            idtarifa: parseInt(idtarifa),
            cantidad,
        })).filter(item => item.cantidad > 0); // Filtramos para solo incluir items con cantidad mayor a 0

        if (items.length === 0) {
            toast.info("Debes seleccionar al menos un item para añadir al carrito.", {
                position: "top-right",
                autoClose: 4000,
            });

            return;
        }

        await crearCarrito(idevento, items);
        setCantidad(items.reduce((acc, item) => ({ ...acc, [item.idtarifa]: 0 }), {}));
    };

    if (!data) {
        return <Loading />;
    }

    const next = () => {
        const nextIndex =
            activeIndex === data.attachs?.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        const nextIndex =
            activeIndex === 0 ? data.attachs?.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const slides = data.attachs?.map((attach, index) => (
        <CarouselItem key={index}>
            <img
                src={attach.url}
                alt={data.evento}
                className="object-fit-fill"
                style={{ width: '100%', height: "435px", objectPosition: 'center' }}
            />
        </CarouselItem>
    ));

    const descripLimpia = sanitizeHtml(data.descripcion);

    return (
        <>
            <div className='header w-100' style={{ height: '435px' }}>
                {data.attachs && data.attachs.length > 0 && (
                    <Carousel
                        activeIndex={activeIndex}
                        next={next}
                        previous={previous}
                        className="mb-0"
                        slide={false}
                    >
                        {slides}

                        {data.attachs && data.attachs.length > 1 && (
                            <>
                                <CarouselControl
                                    direction="prev"
                                    directionText="Anterior"
                                    onClickHandler={previous}
                                />
                                <CarouselControl
                                    direction="next"
                                    directionText="Siguiente"
                                    onClickHandler={next}
                                />
                            </>
                        )}
                    </Carousel>
                )}
            </div>

            <div className='container py-4 d-flex flex-wrap flex-lg-nowrap justify-content-center'>
                <div className="w-100">
                    <h2 className="d-flex justify-content-between">
                        {data.evento}
                        <ShareButton idEvento={param.id} />
                    </h2>
                    <p dangerouslySetInnerHTML={{ __html: descripLimpia }} />


                    <div className="d-flex justify-content-center" >
                        <iframe
                            title="location"
                            className="rounded shadow"
                            width="500"
                            height="250"
                            id="map"
                            loading="lazy"
                            allowFullScreen
                            referrerPolicy="no-referrer-when-downgrade"
                            src={mapUrl}>
                        </iframe>
                    </div>
                </div>

                <div className="bg-body-tertiary rounded w-100 p-4 mt-4 mt-lg-0" style={{ maxWidth: '400px'  }}>
                    <div className="d-flex flex-column">
                        <Badge color="secondary" tag="h5" className="fs-6 fw-medium text-start bg-opacity-25 text-dark">
                            {format(new Date(data.fecha), "dd 'de' MMMM yyyy", { locale: es })}
                        </Badge>

                        <Badge color="secondary" tag="h5" className="fs-6 fw-medium text-start bg-opacity-25 text-dark">
                            {moment.parseZone(data.hora).format("HH:mm")}hs
                        </Badge>

                        {data.direccion && (
                            <Badge color="secondary" tag="h5" className="fs-6 fw-medium text-start bg-opacity-25 text-dark"
                                style={{ wordBreak: 'break-word', whiteSpace: 'normal', overflowWrap: 'break-word' }}
                            >
                                {data.direccion + " - " + data?.ciudad}
                            </Badge>
                        )}
                    </div>

                    <div className="d-flex flex-column fw-medium gap-2 my-4">
                        {data.tarifas ? (data.tarifas.map((tarifa) => (
                            <div key={tarifa.id} className="d-flex py-2 align-items-center justify-content-between border-bottom border-secondary">
                                <div className="d-flex flex-column">
                                    <p className=" fw-bold mb-0 text-dark">
                                        {tarifa.concepto}
                                    </p>
                                    <small className="text-secondary">
                                        {tarifa.idtipo === 1
                                            ? <i>Tickets</i>
                                            : <i>Vouchers</i>
                                        } restantes: {tarifa.restantes}
                                    </small>
                                </div>
                                <ButtonGroup>
                                    <Button
                                        className="fw-bold"
                                        outline
                                        onClick={() => decremento(tarifa.id)}
                                        style={{ border: "1px solid #7D888D", width: '40px' }}
                                    >
                                        -
                                    </Button>
                                    <Button className="fw-bold" outline disabled style={{ color:"black", border:"1px solid #7D888D", cursor: 'default', width: '40px' }}>
                                        {cantidad[tarifa.id] || 0}
                                    </Button>
                                    <Button
                                        className="fw-bold"
                                        outline
                                        onClick={() => incremento(tarifa.id)}
                                        style={{ border: "1px solid #7D888D", width: '40px' }}
                                    >
                                        +
                                    </Button>
                                </ButtonGroup>
                            </div>))
                        ) : (
                            <p className="text-warning text-center">
                                <hr />
                                Aún no hay tarifas.
                                <hr />
                            </p>
                        )}
                    </div>
                    <div className="d-flex justify-content-end flex-wrap gap-2 align-items-center">
                        {data.eventos_tipos_id === 3
                            ?
                            <Button
                                color="primary"
                                onClick={() => history.push(`/eventos/${data.id}/inscripcion`)}
                            >
                                Inscribirse
                            </Button>
                            :
                            <Button color="dark" onClick={handleAñadirCarrito}>
                                <span className="me-1">Añadir</span>
                                {loadingCarrito
                                    ?
                                    <Spinner color="primary" size="sm" />
                                    :
                                    <FontAwesomeIcon icon={faCartShopping} size="sm" />
                                }
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}  
