import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { api } from "../../../services/api";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Button, FormControl, Alert, TextField } from "@mui/material";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

const EditPublicacion = () => {
  const { id, pubId } = useParams();
  const [formData, setFormData] = useState({
    eventoId: "",
    titulo: "",
    subtitulo: "",
    descripcion: "",
    desde: "",
    hasta: "",
  });
  const [error, setError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [newFiles, setNewFiles] = useState([]);
  const history = useHistory();
  const [t] = useTranslation("global");
  const [lengthDescrip, setLengthDescrip] = useState("");
  const [selectedFileIndex, setSelectedFileIndex] = useState(null);

  useEffect(() => {
    const fetchPublicacion = async () => {
      try {
        const response = await api.publicaciones.get(id);
        if (response.status === "success") {
          const data = response.data.publicacion;
          setFormData({
            eventoId: data.idevento,
            titulo: data.titulo,
            subtitulo: data.subtitulo,
            descripcion: data.descripcion,
            desde: moment(data.desde).format("YYYY-MM-DD"),
            hasta: moment(data.hasta).format("YYYY-MM-DD"),
          });
          setAttachments(data.attachs.map(attach => ({
            id: attach.id,
            url: attach.url,
            type: attach.type,
            format: attach.format,
            public_id: attach.public_id
          })));
        }
      } catch (error) {
        setError("Error al cargar los datos de la publicación.");
      }
    };
    fetchPublicacion();
  }, [id]);

  const handleCancel = () => {
    setFormData({
      eventoId: "",
      titulo: "",
      subtitulo: "",
      descripcion: "",
      desde: "",
      hasta: "",
    });
    setAttachments([]);
    setNewFiles([]);
    setError("");
    setSubmitted(false);
    history.goBack();
  };

  const validateField = (name, value) => {
    switch (name) {
      case 'titulo':
        return value.length < 5 || value.length > 50 ? t("publications.titleError") : "";
      case 'subtitulo':
        return value.length < 5 || value.length > 50 ? t("publications.subtitleError") : "";
      case 'descripcion':
        return value.length < 5 || value.length > 240 ? t("publications.descripError") : "";
      case 'desde':
      case 'hasta':
        return !value ? t("publications.dateError") : "";
      default:
        return "";
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));

    setLengthDescrip(value.length);

    const fieldError = validateField(name, value);
    setError(prevErrors => ({
      ...prevErrors,
      [name]: fieldError,
    }));
  };

  const handleAddFileClick = () => {
    document.getElementById('files').click();
  };

  const handleFileChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);

    setAttachments((prevFiles) => {
      const combinedFiles = [...prevFiles, ...selectedFiles];

      if (selectedFileIndex === null && selectedFiles.length > 0) {
        setSelectedFileIndex(prevFiles.length);
      }

      return combinedFiles;
    });

    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("files", file);
    });

    try {
      const response = await api.media.upload(formData);
      if (response.status === "success") {
        const newAttachments = response.data.media.map((media) => ({
          url: media.url,
          type: media.type,
          format: media.format,
          public_id: media.public_id,
        }));
        setAttachments(prevAttachments => [...prevAttachments, ...newAttachments]);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const deleteMedia = async (index) => {
    const attachmentToDelete = attachments[index];

    try {
      if (attachmentToDelete.id) {
        await api.media.delete({ public_id: attachmentToDelete.public_id });
      }
      setAttachments(prevAttachments => prevAttachments.filter((_, i) => i !== index));
    } catch (error) {
      console.error("Error deleting attachment:", error);
      setError("Error al eliminar el archivo adjunto.");
    }
  };


  const handleThumbnailClick = (index) => {
    setSelectedFileIndex(index);
  };

  const isFileTypeImage = (file) => {
    return file && file.type && file.type.startsWith('image/');
  };

  const isFileTypeVideo = (file) => {
    return file && file.type && file.type.startsWith('video/');
  };

  const renderMainPreview = () => {
    if (selectedFileIndex === null || attachments.length === 0) return null;

    const selectedFile = attachments[selectedFileIndex];

    if(!selectedFile) return null;

    const isImage = isFileTypeImage(selectedFile);
    const isVideo = isFileTypeVideo(selectedFile);

    const handleRemoveFile = () => {
      setAttachments((prevFiles) => prevFiles.filter((_, index) => index !== selectedFileIndex));
      setSelectedFileIndex(null); // Resetea la seleccion
    };

    return (
      <div className="main-preview">
        {isImage && <img src={selectedFile.url} alt="Imagen seleccionada" />}
        {isVideo && (
          <video src={selectedFile.url} controls />
        )}

        <button type="button" className="remove-button" onClick={() => {
          deleteMedia(selectedFileIndex);
          handleRemoveFile();
        }}>
          Eliminar
        </button>

      </div>
    );
  };

  const renderFilePreview = (attachment, index) => {
    const isImage = isFileTypeImage(attachment);
    const isVideo = isFileTypeImage(attachment);

    const thumbnailClass = selectedFileIndex === index ? "thumbnail selected" : "thumbnail";

    return (
      <div key={index} className={thumbnailClass} onClick={() => handleThumbnailClick(index)}>
        {isImage && <img src={attachment.url} alt={`Preview ${index}`} />}
        {isVideo && <video src={attachment.url} controls />}
      </div>
    );
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = Object.keys(formData).reduce((acc, key) => {
      const fieldError = validateField(key, formData[key]);
      if (fieldError) {
        acc[key] = fieldError;
      }
      return acc;
    }, {});

    if (Object.keys(formErrors).length > 0) {
      setError(formErrors);
      return;
    }

    const data = {
      id: parseInt(id),
      idevento: formData.eventoId,
      titulo: formData.titulo,
      subtitulo: formData.subtitulo,
      descripcion: formData.descripcion,
      desde: moment.parseZone(formData.desde).format("YYYY-MM-DDT00:00:00-03:00"),
      hasta: moment.parseZone(formData.hasta).format("YYYY-MM-DDT00:00:00-03:00"),
      attachs: attachments,
    };

    try {
      const response = await api.publicaciones.updatePublicacion(data);

      if (response.status === "success") {
        setFormData({
          eventoId: "",
          titulo: "",
          subtitulo: "",
          descripcion: "",
          desde: "",
          hasta: "",
        });
        setAttachments([]);
        setNewFiles([]);
        setError("");
        setSubmitted(true);
        setTimeout(() => {
          setSubmitted(false);
          history.goBack();
        }, 1500);
      } else {
        console.error("Error submitting form:", error);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "30px", padding: "0 10px" }}>
      <div style={{ maxWidth: "600px", width: "100%" }}>
        <h2>{t("publications.editPublication")}</h2>
        <form id="form-publicacion" onSubmit={handleSubmit} className="mt-4">
          <FormControl sx={{ width: "100%" }}>
            <p className="subtitulo-seccion-evento-expanded">Título <span className="text-danger">*</span></p>
            <p className="descrip-seccion-evento-expanded mb-0">Ingrese un titulo para su publicacion</p>
            <TextField
              sx={{
                marginTop: "5px",
                marginBottom: "15px",
                input: {
                  padding: "10px 15px"
                }
              }}
              type="text"
              id="titulo"
              name="titulo"
              value={formData.titulo}
              onChange={handleInputChange}
            />
            <>
              {error.files &&
                <Alert severity="error"
                  sx={{
                    marginBottom: "10px"
                  }}>{error.titulo}</Alert>
              }
            </>
          </FormControl>

          <FormControl sx={{ width: "100%" }}>
            <p className="subtitulo-seccion-evento-expanded">Subtitulo</p>
            <TextField
              sx={{
                marginTop: "5px",
                marginBottom: "15px",
                input: {
                  padding: "10px 15px"
                }
              }}
              type="text"
              id="subtitulo"
              name="subtitulo"
              value={formData.subtitulo}
              onChange={handleInputChange}
            />

          </FormControl>

          <FormControl sx={{ width: "100%" }}>
            <p className="subtitulo-seccion-evento-expanded">Descripción <span className="text-danger">*</span></p>
            <p className="descrip-seccion-evento-expanded mb-0">Ingrese una descripcion concisa y atrayente para su publicacion</p>
            <TextField
              sx={{
                marginTop: "5px",
                marginBottom: "15px",
                input: {
                  padding: "10px 15px"
                }
              }}
              multiline
              rows={2}
              id="descripcion"
              name="descripcion"
              value={formData.descripcion}
              onChange={handleInputChange}
            />
            {lengthDescrip === 80 ? (
              <p className="text-right" style={{ color: "#c44536", fontSize: "12px", position: "relative", top: "-10px" }}>
                {lengthDescrip}/80
              </p>
            ) : (
              <p className="text-right" style={{ fontSize: "12px", color: "#666", position: "relative", top: "-10px" }}>
                {lengthDescrip}/80
              </p>
            )}
            <>
              {error.descripcion &&
                <Alert severity="error"
                  sx={{
                    position: "relative",
                    top: "-25px",
                    marginBottom: "15px"
                  }}>{error.descripcion}</Alert>
              }
            </>
          </FormControl>

          <div className="mb-3" style={{ position: "relative", top: "-20px" }}>
            <p className="subtitulo-seccion-evento-expanded">Periodo de la publicación <span className="text-danger">*</span></p>
            <p className="descrip-seccion-evento-expanded mb-0 text-justify">Selecciona las fechas en las que deseas que tu publicación sea visible.
              La publicación comenzará a mostrarse en la fecha de inicio y dejará de estar disponible después de la fecha de finalización.</p>
            <div className="d-flex flex-row gap-4 flex-wrap mt-1">
              <FormControl sx={{ width: "35%" }}>
                <TextField
                  sx={{
                    marginTop: "5px",
                    marginBottom: "15px",
                    input: {
                      padding: "10px 15px"
                    }
                  }}
                  id="desde"
                  name="desde"
                  type="date"
                  value={formData.desde}
                  onChange={handleInputChange}
                />
                <small style={{ position: "relative", top: "-10px" }}>Fecha de inicio</small>

              </FormControl>
              <FormControl sx={{ width: "35%" }}>
                <TextField
                  sx={{
                    marginTop: "5px",
                    marginBottom: "15px",
                    input: {
                      padding: "10px 15px"
                    }
                  }}
                  id="hasta"
                  name="hasta"
                  type="date"
                  value={formData.hasta}
                  onChange={handleInputChange}
                />
                <small style={{ position: "relative", top: "-10px" }}>Fecha de finalizacion</small>
              </FormControl>
              <>
                {error.desde &&
                  <Alert severity="error"
                    sx={{
                      position: "relative",
                      top: "-20px",
                    }}>{error.desde}</Alert>
                }
                {error.hasta &&
                  <Alert severity="error"
                    sx={{
                      position: "relative",
                      top: "-20px",
                    }}>{error.hasta}</Alert>
                }
                {error.fechas &&
                  <Alert severity="error"
                    sx={{
                      position: "relative",
                      top: "-20px",
                    }}>{error.fechas}</Alert>
                }
              </>
            </div>
          </div>

          <FormControl sx={{ width: "100%" }}>
            <p className="subtitulo-seccion-evento-expanded mb-2">Imagenes para tu publicacion <span className="text-danger">*</span></p>
            <p className="descrip-seccion-evento-expanded">
              Sube hasta 5 imágenes y videos que muestren los detalles y momentos destacados para promocionar tu evento.
              Asegúrate de elegir archivos de alta calidad para un mejor impacto visual.
            </p>
            <div className="info-input-img">
              <ul className="ul-info-img">
                <li style={{ fontSize: "10px", fontWeight: "normal" }}>Tamaño recomendado de imagen: 19200x1280</li>
                <li style={{ fontSize: "10px", fontWeight: "normal" }}>Tamaño máximo de archivo: 15MB</li>
                <li style={{ fontSize: "10px", fontWeight: "normal" }}>Formatos admitidos de imagen: JPEG, PNG</li>
              </ul>
            </div>
            <div className={`input-file ${attachments.length === 0 ? "" : "border-0"}`}>
              <input
                id="files"
                name="files"
                type="file"
                accept="image/*,video/*"
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }}
              />

              {attachments.length === 0 ? (
                <label
                  className="placeholder-input-img"
                  htmlFor="files"
                >
                  {t("event.inputImage")}
                </label>

              ) : (
                <>
                  <div className="imagen-contenedor">
                    {renderMainPreview()}
                    <div className="thumbnails-contenedor">
                      <div className="boton-agregar-img" onClick={handleAddFileClick}>
                        <AddOutlinedIcon sx={{ color: "#3659E3" }} />
                      </div>
                      {attachments.map(renderFilePreview)}
                    </div>
                  </div>
                </>
              )}
            </div>
            <>
              {error.attachments &&
                <Alert severity="error"
                  sx={{
                    marginTop: "10px"
                  }}>{error.attachments}</Alert>
              }
            </>
          </FormControl>

          {Object.values(error).some(error => error) && (
            <Alert color="danger">
              Por favor, corrige los errores antes de enviar el formulario.
            </Alert>
          )}
          {submitted && (
            <Alert color="success">
              {t("publications.successEditedAlert")}
            </Alert>
          )}
          <div className="d-flex justify-content-end mb-5 mt-22">
            <Button style={{color:"red", border:"1px solid red", backgroundColor:"transparent", fontFamily:"Inter, sans-serif"}} className="mr-2" onClick={handleCancel}>
              {t("button.cancel")}
            </Button>
            <Button type="submit" className="boton-submit" onClick={handleSubmit}>
              {t("button.edit")} publicacion
            </Button>{" "}
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditPublicacion;
