import React, { Fragment, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { api } from "../services/api";
import Footer from "../components/Footer";
import Ads from "../components/publicaciones-home/Ads";
import { HomeHeader } from "../components/homeHeader/homeHeader";
import "../App.css";

const Home = () => {
  const { user, isAuthenticated } = useAuth0();
  const [searchPub, setSearchPub] = useState("");

  const handleSearchChange = (pub) => {
    setSearchPub(pub);
  };


  useEffect(() => {
    const initializeUserAndCheckInvitations = async () => {
      if (isAuthenticated && user) {
        const data = {
          usuario: user.email,
          auth0id: user.sub,
          nombre: user.given_name,
          apellido: user.family_name,
        };

        const email = user.email;
         try {
          // Verificar si el usuario existe y crear si no existe
          const userResponse = await api.usuarios.getUserExist(email);
          if (userResponse.status === "error") {
            await api.usuarios.createUsuario(data);
          }

        } catch (error) {
          console.error("Error: ", error);
        } 
      }
    };

    initializeUserAndCheckInvitations();
  }, [isAuthenticated, user]);

  return (
    <Fragment>

      <HomeHeader searchPub={searchPub} onSearchChange={handleSearchChange}/>
  
      <Ads searchPub={searchPub}/>
      
      <Footer />
      
    </Fragment>
  );
};

export default Home;
