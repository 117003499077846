import { useEffect, useState } from "react";
import { api } from "../services/api";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";

const useCarrito = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [carrito, setCarrito] = useState([]);
  const [itemsCarrito, setItemsCarrito] = useState([]);
  const [loadingItem, setLoadingItem] = useState({});
  const [loadingCarrito, setLoadingCarrito] = useState(false);

  const crearCarrito = async (idevento, items) => {
    setLoadingCarrito(true);

    try {
      const response = await api.carrito.crearCarrito({
        idevento,
        items,
      });

      if (response.status === "success") {
        setCarrito(response.data.carrito);

        toast.success(response.message, {
          position: "top-right",
          autoClose: 4000,
        });
      }
      else {
        throw new Error(response.message);
      }

    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 4000,
      });
    } finally {
      setLoadingCarrito(false);
    }
  }

  const actualizarCantidad = async (idtarifa, cantidad) => {
    // Activar el estado de carga para el item específico
    setLoadingItem(prevLoading => ({
      ...prevLoading,
      [idtarifa]: true
    }));

    try {
      const response = await api.carrito.sumarRestarItems({
        idtarifa,
        cantidad
      });

      if (response.status === "success") {
        // Actualizamos el carrito
        const carritoActualizado = carrito.items.map(item =>
          item.idtarifa === idtarifa ? { ...item, cantidad } : item
        );
        setItemsCarrito(carritoActualizado);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      // Desactivar el estado de carga para el item específico
      setLoadingItem(prevLoading => ({
        ...prevLoading,
        [idtarifa]: false
      }));
    }
  }

  const eliminarItem = async (idtarifa) => {
    setLoadingItem(prevLoading => ({
      ...prevLoading,
      [idtarifa]: true
    }));

    try {
      const response = await api.carrito.eliminarItem(idtarifa);

      if (response.status === "success") {
        toast.success("Item eliminado con éxito", {
          position: "top-right",
          autoClose: 2000,
        });
      }
      else {
        throw new Error(response.message);
      }

    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 4000,
      });
    } finally {
      setLoadingItem(prevLoading => ({
        ...prevLoading,
        [idtarifa]: false
      }));
    }
  }

  const getCarrito = async () => {
    setLoadingCarrito(true);

    try {
      if (isAuthenticated) {
        const response = await api.carrito.getCarrito();

        if (response.status === "success") {
          setCarrito(response.data.carrito);
          setItemsCarrito(response.data.carrito.items || []); // Aseguramos que sea un array
        } else {
          throw new Error(response.message);
        }
      }
    } catch (error) {
      toast.error("Error en la red.", {
        position: "top-center",
        autoClose: 5000,
      });
    } finally {
      setLoadingCarrito(false);
    }
  };

  useEffect(() => {
    getCarrito();
  }, [loadingItem, isAuthenticated, getAccessTokenSilently,]);

  return {
    carrito,
    itemsCarrito,
    loadingItem,
    loadingCarrito,
    getCarrito,
    crearCarrito,
    actualizarCantidad,
    eliminarItem
  };
}

export default useCarrito;