import React, { useState } from "react";
import { CardBody, Container, Spinner, } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import '../publicaciones-home/ads.css';
import moment from 'moment-timezone';
import ShareButton from "../shareButton/shareButton";
import { useMediaQuery } from "react-responsive";
import LikeButton from "../Like/LikeButton";
import { Card, CardMedia, CardContent, MobileStepper, IconButton } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import AccessAlarmsOutlinedIcon from '@mui/icons-material/AccessAlarmsOutlined';

moment.locale('es');
moment.locale('en-GB');

const translations = {
    Deportivos: {
        es: "Deportivos",
        en: "Sports",
    },
    Beneficios: {
        es: "Beneficios",
        en: "Profits",
    },
    Recepciones: {
        es: "Recepciones",
        en: "Social events",
    },
};

const translateCategory = (category, language) => {
    return translations[category] ? translations[category][language] : category;
};

const CardPublicacion = ({ publicacion, isLoading }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const history = useHistory();
    const { i18n, t } = useTranslation("global");
    const isMobile = useMediaQuery({ query: `(max-width: 770px)` });

    moment.locale(i18n.language === "es" ? "es" : "en-gb");

    const maxSteps = publicacion.attachs?.length || 0;

    const next = () => {
        const nextIndex = activeIndex === publicacion.attachs?.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        const nextIndex = activeIndex === 0 ? publicacion.attachs?.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    if (isLoading) {
        return (
            <Container
                fluid
                className={"my-4"}
                style={{ width: "350px", height: "auto" }}
            >
                <Card className="card-indiv border-0" style={{ height: "100%", boxShadow: "0px 5px 14px -6px black !important" }}>
                    <CardBody
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "100%" }}
                    >
                        <Spinner color="primary" />
                    </CardBody>
                </Card>
            </Container>
        );
    }

    return (
        <div className="mt-4">
            <Card className="card-publicacion">
                {publicacion.attachs && publicacion.attachs.length > 0 && (
                    <div style={{ position: 'relative' }}>
                        <CardMedia
                            className="media-publicacion"
                            component={publicacion.attachs[activeIndex].url.includes("video") ? "video" : "img"}
                            src={publicacion.attachs[activeIndex].url}
                            alt={publicacion.titulo}
                            controls={publicacion.attachs[activeIndex].url.includes("video")}
                        />
                        {maxSteps > 1 && (
                            <>
                                <IconButton
                                    style={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)' }}
                                    onClick={previous}
                                >
                                    <KeyboardArrowLeft />
                                </IconButton>
                                <IconButton
                                    style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}
                                    onClick={next}
                                >
                                    <KeyboardArrowRight />
                                </IconButton>
                                <MobileStepper
                                    steps={maxSteps}
                                    position="static"
                                    activeStep={activeIndex}
                                    style={{
                                        background: 'transparent',
                                        position: 'absolute',
                                        bottom: 0,
                                        width: '100%'
                                    }}
                                    nextButton={<div />}
                                    backButton={<div />}
                                />
                            </>
                        )}
                    </div>
                )}
                <CardContent className="contenido-publicacion">
                    <p className="title-publicacion">
                        {publicacion.titulo}
                    </p>
                    <div className="fechaHora-publicacion">
                        <div className="fecha-publicacion d-flex align-items-center">
                            <CalendarMonthOutlinedIcon sx={{ marginRight: "5px" }} />
                            <p className="text-publicacion">
                                {i18n.language === 'es'
                                    ? moment(publicacion.evento?.fecha).clone().local().format("D [de] MMMM")
                                    : moment(publicacion.evento?.fecha).clone().local().format("MMMM[,] D")}
                            </p>
                        </div>
                        <div className="separador-vertical"></div>
                        <div className="d-flex align-items-center">
                            <AccessAlarmsOutlinedIcon sx={{ marginRight: "5px" }} />
                            <p className="text-publicacion">{moment(publicacion.evento?.hora).clone().local().format('LT')}</p>
                        </div>
                    </div>
                    <p className="descripcion">
                        {publicacion.descripcion.length > 70
                            ? `${publicacion.descripcion.substring(
                                0,
                                70
                            )}...`
                            : publicacion.descripcion
                        }
                    </p>
                    <div className="separador-horizontal"></div>
                    <div className="contenedor-botones">
                        <div className="d-flex align-items-center">
                            <LikeButton publicacionId={publicacion.id} />
                            <ShareButton idEvento={publicacion.idevento} />
                        </div>

                        <button
                            className="boton-publicacion"
                            onClick={() =>
                                history.push(`/evento/${publicacion.idevento}/informacion`)
                            }
                        >
                            {t("adsDetails.more")}
                        </button>


                    </div>
                </CardContent>
            </Card>
        </div>
    );
};

export default CardPublicacion;
