import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../../services/api';
import { useAuth0 } from "@auth0/auth0-react";
import { useMediaQuery } from "react-responsive";
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { TextField, Select, MenuItem, Alert, Button, Table } from '@mui/material'
import { Input } from 'reactstrap';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';

const Perfil = ({ usuario, paises, provincias, ciudades, setPaisSeleccionado, setProvSeleccionada }) => {
    const { user } = useAuth0();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTool = () => setTooltipOpen(!tooltipOpen);
    const [editProfile, setEditProfile] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const { i18n } = useTranslation();
    const [t] = useTranslation("global");
    const [error, setError] = useState("");
    const [formData, setFormData] = useState({
        nombre: usuario?.nombre || "",
        apellido: usuario?.apellido || "",
        telefono: usuario?.telefono || "",
        direccion: usuario?.direccion || "",
        ciudad: usuario?.ciudad || "",
        provincia: usuario?.provincia || "",
        cp: usuario?.cp || "",
        pais: usuario?.pais || "",
    });
    const [submitted, setSubmitted] = useState(false);
    const [ciudadSeleccionada, setCiudadSeleccionada] = useState("");
    const isMobile = useMediaQuery({ query: '(max-width: 770px)' });
    const history = useHistory();
    const [cookies, setCookie] = useCookies(['language_preference']);
    const [language, setLanguage] = useState(cookies.languagePreference || 'es');

    useEffect(() => {
        setFormData({
            nombre: usuario?.nombre || "",
            apellido: usuario?.apellido || "",
            telefono: usuario?.telefono || "",
            direccion: usuario?.direccion || "",
            ciudad: usuario?.ciudad || "",
            provincia: usuario?.provincia || "",
            cp: usuario?.cp || "",
            pais: usuario?.pais || "",
        });
    }, [usuario]);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        languagePreference(lng);
        localStorage.setItem('i18nextLng', lng);
    };

    // cookie para la preferencia de idioma
    const languagePreference = (lng) => {
        setCookie('language_preference', lng, {
            path: '/',
            maxAge: 365 * 24 * 60 * 60,
            sameSite: 'Lax'
        });
    };

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
        changeLanguage(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (formData.nombre?.trim() === "") {
            setError(<>{t("errors.emptyField")}</>);
            return;
        }

        if (formData.nombre?.length > 50) {
            setError(<>{t("errors.eventName")}</>);
            return;
        }

        if (formData.apellido?.trim() === "") {
            setError(<>{t("errors.emptyField")}</>);
            return;
        }

        if (formData.apellido?.length > 50) {
            setError(<>{t("errors.charactersDescription")}</>);
            return;
        }

        try {
            const response = await api.usuarios.EditUsuario(formData);
            if (response.status === "success") {
                setSubmitted(true);
                setTimeout(() => window.location.reload(), 1500);
            } else {
                setError(response.message);
            }
        } catch (error) {
            setError("Error al editar usuario.");
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleCountryChange = (event) => {
        const selectedPaisIso2 = event.target.value;
        const selectedPais = paises.find(pais => pais.iso2 === selectedPaisIso2);
        setPaisSeleccionado(selectedPaisIso2);
        setFormData(prevState => ({
            ...prevState,
            pais: selectedPais ? selectedPais.name : "",
            provincia: "",
            ciudad: ""
        }));
    };

    const handleProvChange = (event) => {
        const selectedProvIso2 = event.target.value;
        const selectedProv = provincias.find(provincia => provincia.iso2 === selectedProvIso2);
        setProvSeleccionada(selectedProvIso2);
        setFormData(prevState => ({
            ...prevState,
            provincia: selectedProv ? selectedProv.name : "",
            ciudad: ""
        }));
    };

    const handleCityChange = (event) => {
        const selectedCityId = event.target.value;
        const nombreCiudad = buscarNombreCiudad(selectedCityId);
        setCiudadSeleccionada(selectedCityId);
        setFormData(prevState => ({
            ...prevState,
            ciudad: nombreCiudad
        }));
    };


    const buscarNombreCiudad = (ciudadId) => {
        const ciudadEncontrada = ciudades.find(ciudad => ciudad.id === ciudadId);
        return ciudadEncontrada ? ciudadEncontrada.name : ciudadId;
    };


    const desactivarCuenta = () => {
        history.push('/profile/eliminar-cuenta');
    };

    return (
        <div className="container">
            {isMobile ? (
                <>
                    <h3 className='d-flex align-items-center my-2'>{t("profile_info.general_info")}
                        <div>
                            {!editProfile && (
                                <button
                                    className="py-0 mx-2"
                                    color="grey"
                                    style={{ border: "none" }}
                                    onClick={() => setEditProfile(true)}
                                >
                                    <EditOutlinedIcon />
                                </button>
                            )}
                        </div>
                    </h3>

                    <div>
                        {editProfile ? (
                            <>
                                <div className="">
                                    <form id="form-profile" onSubmit={handleSubmit}>
                                        <div className='form-seccion my-3'>
                                            <p className='mb-0'>{t("profile_info.only_name")}</p>
                                            <TextField type="text"
                                                sx={{
                                                    marginTop: "5px",
                                                    marginBottom: "15px",
                                                    width: "100%",
                                                    input: {
                                                        padding: "10px 15px"
                                                    }
                                                }}
                                                id="nombre"
                                                name="nombre"
                                                value={formData.nombre || ""}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className='form-seccion my-3'>
                                            <p className='m-0'>{t("profile_info.only_surname")}</p>
                                            <TextField type="text"
                                                sx={{
                                                    marginTop: "5px",
                                                    marginBottom: "15px",
                                                    width: "100%",
                                                    input: {
                                                        padding: "10px 15px"
                                                    }
                                                }}
                                                id="apellido"
                                                name="apellido"
                                                value={formData.apellido || ""}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className='form-seccion my-3'>
                                            <p className='m-0'>{t("profile_info.phone")}</p>
                                            <TextField
                                                sx={{
                                                    marginTop: "5px",
                                                    marginBottom: "15px",
                                                    width: "100%",
                                                    input: {
                                                        padding: "10px 15px"
                                                    }
                                                }} type="text"
                                                id="telefono"
                                                name="telefono"
                                                value={formData.telefono || ""}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className='form-seccion my-3'>
                                            <p className='m-0'>{t("profile_info.country")}</p>
                                            <Select
                                                sx={{
                                                    marginTop: "5px",
                                                    marginBottom: "15px",
                                                    width: "100%",
                                                    input: {
                                                        padding: "10px 15px"
                                                    }
                                                }}
                                                id="pais"
                                                name="pais"
                                                value={paises.find(p => p.name === formData.pais)?.iso2 || ""}
                                                onChange={handleCountryChange}
                                                className="profile-input"
                                                /* style={{ width: "70%" }} */
                                                disabled={!paises.length}
                                            >
                                                <MenuItem value="" disabled>{t("profile_info.select_country")}</MenuItem>
                                                {paises.map((pais) => (
                                                    <MenuItem key={pais.iso2} value={pais.iso2}>
                                                        {pais.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='form-seccion my-3'>
                                            <p className='m-0'>{t("profile_info.province_state")}</p>
                                            <Select
                                                sx={{
                                                    marginTop: "5px",
                                                    marginBottom: "15px",
                                                    width: "100%",
                                                    input: {
                                                        padding: "10px 15px"
                                                    }
                                                }}
                                                id="provincia"
                                                name="provincia"
                                                value={provincias.find(p => p.name === formData.provincia)?.iso2 || ""}
                                                onChange={handleProvChange}
                                                className="profile-input"
                                                disabled={!formData.pais || !provincias.length}
                                            >
                                                <MenuItem value="" disabled>{t("profile_info.select_province")}</MenuItem>
                                                {provincias.map((provincia) => (
                                                    <MenuItem key={provincia.iso2} value={provincia.iso2}>
                                                        {provincia.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </div>
                                        <div className='form-seccion my-3'>
                                            <p className='m-0'>Ciudad</p>
                                            <Input
                                                id="ciudad"
                                                name="ciudad"
                                                type="select"
                                                value={formData.ciudad || ""}
                                                onChange={handleCityChange}
                                                className="profile-input"
                                                style={{ width: "70%" }}
                                                disabled={!formData.provincia || !ciudades.length}
                                            >
                                                <option value="" disabled>Selecciona una ciudad</option>
                                                {ciudades.map((ciudad) => (
                                                    <option key={ciudad.id} value={ciudad.name}>
                                                        {ciudad.name}
                                                    </option>
                                                ))}
                                            </Input>
                                        </div>
                                        <div className='form-seccion my-3'>
                                            <p className='m-0'>{t("profile_info.address")}</p>
                                            <TextField
                                                sx={{
                                                    marginTop: "5px",
                                                    marginBottom: "15px",
                                                    width: "100%",
                                                    input: {
                                                        padding: "10px 15px"
                                                    }
                                                }} type="text"
                                                id="apellido"
                                                name="apellido"
                                                value={formData.direccion || ""}
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                        <div className='form-seccion my-3'>
                                            <p className='m-0'>{t("profile_info.zip_code")}</p>
                                            <TextField
                                                sx={{
                                                    marginTop: "5px",
                                                    marginBottom: "15px",
                                                    width: "100%",
                                                    input: {
                                                        padding: "10px 15px"
                                                    }
                                                }}
                                                type="text"
                                                id="cp"
                                                name="cp"
                                                value={formData.cp || ""}
                                                onChange={handleInputChange}
                                                style={{ width: "30%" }}
                                            />
                                        </div>
                                        <div className='form-seccion mt-3 mb-4'>
                                            <div className='d-flex mb-2'>
                                                <p className='m-0' style={{ textAlign: "center", paddingTop: "5px" }}>{t("profile_info.email")}</p>
                                                {user?.email_verified ? (
                                                    <p className="ml-2" style={{
                                                        color: "white",
                                                        backgroundColor: "green",
                                                        padding: "5px 10px",
                                                        marginBottom: "0",
                                                        borderRadius: "4px"
                                                    }}>
                                                        {t("profile_info.verified")}
                                                    </p>
                                                ) : (
                                                    <p className="ml-2" style={{
                                                        color: "white",
                                                        backgroundColor: "red",
                                                        padding: "5px 10px",
                                                        marginBottom: "0",
                                                        borderRadius: "4px"
                                                    }}>
                                                        {t("profile_info.unverified")}
                                                    </p>
                                                )}
                                            </div>
                                            <p className='m-0 fw-bold'>{user?.email}</p>
                                        </div>

                                        {submitted && <Alert color="success">{t("profile_info.edit_user")}</Alert>}

                                        {editProfile && (
                                            <div className="d-flex flex-row ml-auto justify-content-end mt-2 mb-4">
                                                <Button
                                                    sx={{
                                                        color: "red",
                                                        border: "1px solid red",
                                                        padding: "5px 15px"
                                                    }}
                                                    type="button"
                                                    className="mt-3 mr-3 d-inline-flex "
                                                    onClick={() => setEditProfile(false)}
                                                >
                                                    {t("button.cancel")}
                                                </Button>{" "}
                                                <Button
                                                    sx={{ padding: "5px 15px" }}
                                                    type="submit"
                                                    className="mt-3 boton-submit"
                                                >
                                                    {t("button.save")}
                                                </Button>{" "}
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className='d-flex justify-content-between my-3 pb-2 border-bottom '>
                                    <p className='m-0' style={{ color: "#797D88" }}>{t("profile_info.name_lastname")}</p>
                                    <p className='m-0 text-capitalize fw-bold'>{formData.nombre} {formData.apellido}</p>
                                </div>
                                <div className='d-flex justify-content-between my-2 pb-2 border-bottom '>
                                    <p className='m-0' style={{ color: "#797D88" }} >{t("profile_info.phone")}</p>
                                    <p className='m-0 fw-bold'>{formData.telefono}</p>
                                </div>
                                <div className='d-flex justify-content-between my-3 pb-2 border-bottom '>
                                    <p className='m-0' style={{ color: "#797D88" }}>{t("profile_info.country")}</p>
                                    <p className='m-0 fw-bold'>{formData.pais}</p>
                                </div>
                                <div className='d-flex justify-content-between my-3 pb-2 border-bottom  '>
                                    <p className='m-0' style={{ color: "#797D88" }}>{t("profile_info.province_state")}</p>
                                    <p className='m-0 fw-bold'>{formData.provincia}</p>
                                </div>
                                <div className='d-flex justify-content-between my-3 pb-2 border-bottom '>
                                    <p className='m-0' style={{ color: "#797D88" }}>{t("profile_info.city")}</p>
                                    <p className='m-0 fw-bold'>{formData.ciudad}</p>
                                </div>
                                <div className='d-flex justify-content-between my-3 pb-2 border-bottom '>
                                    <p className='m-0' style={{ color: "#797D88" }}>{t("profile_info.zip_code")}</p>
                                    <p className='m-0 fw-bold'>{formData.cp}</p>
                                </div>
                                <div className='d-flex justify-content-between my-3 pb-2 border-bottom  '>
                                    <p className='m-0' style={{ color: "#797D88" }}>{t("profile_info.address")}</p>
                                    <p className='m-0 fw-bold'>{formData.direccion}</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-center pb-2 border-bottom my-3'>
                                    <p className='m-0' style={{ color: "#797D88" }}>{t("profile_info.email")}</p>
                                    <p className='m-0 fw-bold'>{user?.email}</p>
                                </div>
                                <div className='d-flex justify-content-between align-items-center my-3 pb-2 border-bottom '>
                                    <p className='m-0' style={{ color: "#797D88" }}>Facebook</p>
                                    <button type='button ' className='fw-bold'>{t("profile_info.link")}</button>
                                </div>
                                <div className='d-flex justify-content-between my-3 pb-2 border-bottom '>
                                    <p className='m-0' style={{ color: "#797D88" }}>Google</p>
                                    <button type="button" className='fw-bold'>{t("profile_info.link")}</button>
                                </div>
                                <div className="row mt-2 ml-1">
                                    <h3 className="d-flex align-items-center mt-4 config" >
                                        <strong>{t("profile_info.settings")}</strong>
                                    </h3>
                                </div>
                                <div className="container mb-3">
                                    <Table className="mb-0 table">
                                        <tbody>
                                            <tr>
                                                <th scope="row" className="align-middle">{t("profile_info.language")}</th>
                                                <td className="p-2 align-middle">
                                                    <select id="dropdown" value={i18n.language} onChange={handleChange}>
                                                        <option value="" disabled>
                                                            {t("profile_info.option")}
                                                        </option>
                                                        <option value="es">{t("profile_info.spanish")}</option>
                                                        <option value="en">{t("profile_info.english")}</option>
                                                    </select>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </>
                        )}
                    </div>
                </>
            ) : (
                <>
                    <h3 className='d-flex align-items-center ml-4 my-2'>{t("profile_info.general_info")}
                        <div>
                            {!editProfile && (
                                <button
                                    className="py-0 mx-2"
                                    color="grey"
                                    style={{ border: "none" }}
                                    onClick={() => setEditProfile(true)}
                                >
                                    <EditOutlinedIcon />
                                </button>
                            )}
                        </div>
                    </h3>

                    {error && <Alert color="danger">{error}</Alert>}

                    {editProfile ? (
                        <>
                            <form id="form-profile" onSubmit={handleSubmit}>
                                <div className="container mt-2 ml-4">
                                    <div className="row g-2">
                                        <div className="col-md-6 mb-2">
                                            <div className=" d-flex flex-column">
                                                <small className="text-muted">{t("profile_info.name")}</small>
                                                <TextField
                                                    sx={{
                                                        marginTop: "5px",
                                                        marginBottom: "15px",
                                                        width: "80%",
                                                        input: {
                                                            padding: "10px 15px"
                                                        }
                                                    }}
                                                    type="text"
                                                    id="nombre"
                                                    name="nombre"
                                                    value={formData.nombre || ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="d-flex flex-column">
                                                <small className="text-muted">{t("profile_info.country")}</small>
                                                <Select
                                                    sx={{
                                                        marginTop: "5px",
                                                        marginBottom: "15px",
                                                        width: "80%",
                                                        input: {
                                                            padding: "10px 15px"
                                                        }
                                                    }}
                                                    id="pais"
                                                    type="select"
                                                    name="pais"
                                                    value={paises.find(p => p.name === formData.pais)?.iso2 || ""}
                                                    onChange={handleCountryChange}
                                                    className="profile-input"
                                                    disabled={!paises.length}
                                                >
                                                    <MenuItem value="" disabled>{t("profile_info.option")}</MenuItem>
                                                    {paises.map((pais) => (
                                                        <MenuItem key={pais.iso2} value={pais.iso2}>
                                                            {pais.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className="mb-3">
                                                <small className="text-muted">Ciudad</small>
                                                <Input
                                                    id="ciudad"
                                                    name="ciudad"
                                                    type="select"
                                                    value={formData.ciudad || ""}
                                                    onChange={handleCityChange}
                                                    className="profile-input"
                                                    style={{ width: "70%" }}
                                                    disabled={!formData.provincia || !ciudades.length}
                                                >
                                                    <option value="" disabled>Selecciona una ciudad</option>
                                                    {ciudades.map((ciudad) => (
                                                        <option key={ciudad.id} value={ciudad.name}>
                                                            {ciudad.name}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <small className="text-muted">{t("profile_info.phone")}</small>
                                                <TextField
                                                    sx={{
                                                        marginTop: "5px",
                                                        marginBottom: "15px",
                                                        width: "80%",
                                                        input: {
                                                            padding: "10px 15px"
                                                        }
                                                    }}
                                                    id="telefono"
                                                    name="telefono"
                                                    value={formData.telefono || ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="d-flex flex-column">
                                                <small className="text-muted">{t("profile_info.lastname")}</small>
                                                <TextField
                                                    sx={{
                                                        marginTop: "5px",
                                                        marginBottom: "15px",
                                                        width: "80%",
                                                        input: {
                                                            padding: "10px 15px"
                                                        }
                                                    }} type="text"
                                                    id="apellido"
                                                    name="apellido"
                                                    value={formData.apellido || ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="d-flex flex-column">
                                                <small className="text-muted">{t("profile_info.province_state")}</small>
                                                <Select
                                                    sx={{
                                                        marginTop: "5px",
                                                        marginBottom: "15px",
                                                        width: "80%",
                                                        input: {
                                                            padding: "10px 15px"
                                                        }
                                                    }}
                                                    id="provincia"
                                                    name="provincia"
                                                    value={provincias.find(p => p.name === formData.provincia)?.iso2 || ""}
                                                    onChange={handleProvChange}
                                                    className="profile-input"
                                                    disabled={!formData.pais || !provincias.length}
                                                >
                                                    <MenuItem value="" disabled>{t("profile_info.select_province")}</MenuItem>
                                                    {provincias.map((provincia) => (
                                                        <MenuItem key={provincia.iso2} value={provincia.iso2}>
                                                            {provincia.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <small className="text-muted">{t("profile_info.zip_code")}</small>
                                                <TextField
                                                    sx={{
                                                        marginTop: "5px",
                                                        marginBottom: "15px",
                                                        width: "40%",
                                                        input: {
                                                            padding: "10px 15px"
                                                        }
                                                    }}
                                                    type="text"
                                                    id="cp"
                                                    name="cp"
                                                    value={formData.cp || ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="d-flex flex-column">
                                                <small className="text-muted">{t("profile_info.address")}</small>
                                                <TextField
                                                    sx={{
                                                        marginTop: "5px",
                                                        marginBottom: "15px",
                                                        width: "80%",
                                                        input: {
                                                            padding: "10px 15px"
                                                        }
                                                    }}
                                                    type="text"
                                                    id="direccion"
                                                    name="direccion"
                                                    value={formData.direccion || ""}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='mb-3'>
                                            <div className='d-flex mb-2'>
                                                <small style={{ textAlign: "center", paddingTop: "5px" }}>{t("profile_info.email")}</small>
                                                {user?.email_verified ? (
                                                    <p className="ml-2" style={{
                                                        color: "white",
                                                        backgroundColor: "green",
                                                        padding: "5px 10px",
                                                        marginBottom: "0",
                                                        borderRadius: "4px"
                                                    }}>
                                                        {t("profile_info.verified")}
                                                    </p>
                                                ) : (
                                                    <p className="ml-2" style={{
                                                        color: "white",
                                                        backgroundColor: "green",
                                                        padding: "5px 10px",
                                                        marginBottom: "0",
                                                        borderRadius: "4px"
                                                    }}>
                                                        {t("profile_info.unverified")}
                                                    </p>
                                                )}
                                            </div>
                                            <TextField
                                                sx={{
                                                    marginTop: "5px",
                                                    marginBottom: "15px",
                                                    width: "80%",
                                                    input: {
                                                        padding: "10px 15px"
                                                    }
                                                }}
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={user?.email}
                                                disabled
                                                style={{ width: "85%" }}
                                            />
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="mb-3">
                                                <small className="text-muted">Facebook</small>
                                                <button type="button" className='fw-bold'>{t("profile_info.link")}</button>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-2">
                                            <div className="mb-3">
                                                <small className="text-muted">Google</small>
                                                <button type="button" className='fw-bold'>{t("profile_info.link")}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {submitted && <Alert color="success">{t("profile_info.edit_user")}</Alert>}

                                {editProfile && (
                                    <div className="d-flex flex-row justify-content-end mt-2 ml-4">
                                        <Button
                                            sx={{
                                                color: "red",
                                                border: "1px solid red",
                                                padding: "5px 15px"
                                            }}
                                            type="button"
                                            className="mt-3 mr-3 d-inline-flex "
                                            onClick={() => setEditProfile(false)}
                                        >
                                            {t("button.cancel")}
                                        </Button>{" "}
                                        <Button
                                            sx={{ padding: "5px 30px !important" }}
                                            type="submit"
                                            className="mt-3 boton-submit"
                                        >
                                            {t("button.save")}
                                        </Button>{" "}
                                    </div>
                                )}
                            </form>
                        </>
                    ) : (
                        <>
                            <div className="container mt-2 ml-4">
                                <div className="row g-2">
                                    <div className="col-md-6 mb-2">
                                        <div className="mb-3">
                                            <small className="text-muted">{t("profile_info.name")}</small>
                                            <p className="fs-6 mb-0">{formData?.nombre}</p>
                                        </div>
                                        <div className="mb-3">
                                            <small className="text-muted">{t("profile_info.country")}</small>
                                            <p className="mb-0">{formData?.pais}</p>
                                        </div>
                                        <div className="mb-3">
                                            <small className="text-muted">{t("profile_info.city")}</small>
                                            <p className="mb-0">{formData?.ciudad}</p>
                                        </div>
                                        <div className="mb-3">
                                            <small className="text-muted">{t("profile_info.phone")}</small>
                                            <p className="mb-0">{formData?.telefono}</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="mb-3">
                                            <small className="text-muted">{t("profile_info.lastname")}</small>
                                            <p className="fs-6 mb-0">{formData?.apellido}</p>
                                        </div>
                                        <div className="mb-3">
                                            <small className="text-muted">{t("profile_info.province_state")}</small>
                                            <p className="mb-0"> {formData?.provincia}</p>
                                        </div>
                                        <div className="mb-3">
                                            <small className="text-muted">{t("profile_info.zip_code")}</small>
                                            <p className="mb-0">{formData?.cp}</p>
                                        </div>
                                        <div className="mb-3">
                                            <small className="text-muted">{t("profile_info.address")}</small>
                                            <p className="mb-0">{formData?.direccion}</p>
                                        </div>
                                    </div>
                                    <div className='mb-3'>
                                        <div className='d-flex mb-2'>
                                            <small style={{ textAlign: "center", paddingTop: "5px" }}>{t("profile_info.email")}</small>
                                            {user?.email_verified ? (
                                                <p className="ml-2" style={{
                                                    color: "white",
                                                    backgroundColor: "green",
                                                    padding: "5px 10px",
                                                    marginBottom: "0",
                                                    borderRadius: "4px"
                                                }}>
                                                    {t("profile_info.verified")}
                                                </p>
                                            ) : (
                                                <p className="ml-2" style={{
                                                    color: "white",
                                                    backgroundColor: "red",
                                                    padding: "5px 10px",
                                                    marginBottom: "0",
                                                    borderRadius: "4px"
                                                }}>
                                                    {t("profile_info.unverified")}
                                                </p>
                                            )}
                                        </div>
                                        <p className='fs-6 mb-0'>{user?.email}</p>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="mb-3">
                                            <small className="text-muted">Facebook</small>
                                            <button type="button" className='fw-bold'>{t("profile_info.link")}</button>
                                        </div>
                                    </div>
                                    <div className="col-md-6 mb-2">
                                        <div className="mb-3">
                                            <small className="text-muted">Google</small>
                                            <button type="button" className='fw-bold'>{t("profile_info.link")}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2 ml-1">
                                <h3 className='d-flex align-items-center mt-2'>{t("profile_info.settings")}</h3>
                            </div>
                            <div className="container ml-4 mb-2">
                                <div className='d-flex flex-column'>
                                    <small className="mb-2">{t("profile_info.language")}</small>
                                    <Select
                                        sx={{
                                            marginTop: "5px",
                                            marginBottom: "15px",
                                            width: "80%",
                                            input: {
                                                padding: "10px 15px"
                                            }
                                        }}
                                        id="idioma"
                                        name="idioma"
                                        type="select"
                                        value={i18n.language}
                                        onChange={handleChange}
                                        className="profile-input"
                                    
                                    >
                                        <MenuItem value="" disabled>
                                            {t("profile_info.option")}
                                        </MenuItem>
                                        <MenuItem value="es">{t("profile_info.spanish")}</MenuItem>
                                        <MenuItem value="en">{t("profile_info.english")}</MenuItem>
                                    </Select>
                                </div>
                            </div>
                            <button
                                type="button"
                                className="mt-2 mb-2 btn d-flex align-items-center text-start"
                                onClick={desactivarCuenta}
                                style={{ backgroundColor: "transparent", color: "red", borderColor: "transparent" }}
                            >
                                <ManageAccountsOutlinedIcon sx={{
                                    marginRight: "5px"
                                }}/>
                                {t("profile_info.message_delete_account")}
                            </button>
                        </>
                    )}
                </>
            )}
        </div >
    );
};

export default Perfil;